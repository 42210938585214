import { Component, OnInit, ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { MapsAPILoader } from '@agm/core';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-addcity',
  templateUrl: './addcity.component.html',
  styleUrls: ['./addcity.component.css']
})
export class AddcityComponent implements OnInit {

  //MAP
  @ViewChild('search',{static:false})
  public searchElementRef: ElementRef;

 //Mode Values
 isaddmode:boolean=false;
 iseditmode:boolean=false;
 isdeletemode:boolean=false;
 isviewmode:boolean=false;

 //Save Validation
 submitted:boolean;
 successfully:boolean;



 //Edit Get Values
 warehouseColl:any=[];
 cityColl:any=[]; 
 cityid:number=0


 cityform=new FormGroup({
  cityname:new FormControl('',Validators.required),         
  cityid:new FormControl(0),
  warehouseid:new FormControl(null,Validators.required),  
  inactive:new FormControl(false),     
 });


 latitude = 13.0827;
  longitude = 80.2707;
  mapType = 'roadmap';
  markers = [ ];
  selectedMarker;

 list = [];

 isloading:boolean=false;
isprivilage:boolean=false;  
showProgress:boolean=false;
formname:String="City";

_routerSub = Subscription.EMPTY;
_routerparamsSub = Subscription.EMPTY;
_routerLoginSub = Subscription.EMPTY

constructor(private appService: DhukanDataService,private route: ActivatedRoute,
    public router: Router,private toastr: ToastrService,private loginService:LoginDataService,
    private mapsAPILoader: MapsAPILoader,  private ngZone: NgZone) { 
      this._routerSub = this.route.queryParams.subscribe(params => {       
        if(params.id!==undefined){
          this.cityid = params.id
        }    
      });

      this.fnServiceChanges();
}


fnServiceChanges(){
  this._routerLoginSub =this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}

ngOnInit() {
    try{
      if(this.loginService.fnCheckUser()){ 
        if(this.fnCheckUserPririvilage()){       
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
              
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}
ngOnDestroy(): void {
  if (this._routerSub !== undefined) {
    this._routerSub.unsubscribe();
  }
  if (this._routerparamsSub !== undefined) {
    this._routerparamsSub.unsubscribe();
  }

  if (this._routerLoginSub !== undefined) {
    this._routerLoginSub.unsubscribe();
  }
}
fnOnInitForm(){

    try{
        this.isaddmode=true;
        this.cityform.reset();
        this.fnInitializeCityFormGroup(); 

        if(this.cityid>0){
          this.fngetCity();
        }

        this.fngetWarehouse();
        this.fnSetAutoCompleteCity();
        
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}



fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.cityid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}

fnInitializeCityFormGroup(){
    this.cityform.setValue({
      cityname:'',
      cityid:0,
      warehouseid:null,     
      inactive:false,      
    })
}

fnSetAutoCompleteCity(){
   //load Places Autocomplete
   this.mapsAPILoader.load().then(() => {
    let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["(cities)"]
    });
    autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
            //get the place result
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();

            //verify result
            if (place.geometry === undefined || place.geometry === null) {
                return;
            }


            console.log(parseFloat(place.geometry.location.lat().toString()).toFixed(4));
            console.log(parseFloat(place.geometry.location.lng().toString()).toFixed(4));

            this.cityform.setValue({
              cityname: place.address_components[0].short_name,
              cityid:this.cityform.get('cityid').value,
              warehouseid:this.cityform.get('warehouseid').value,  
              inactive:this.cityform.get('inactive').value,  
            });


            //set latitude, longitude and zoom
            //this.latitude = place.geometry.location.lat();
           // this.longitude = place.geometry.location.lng();
            //this.position = place.address_components[0].short_name;
           // this.zoom = 12;
        });
    });
});
}


//Get 
fngetWarehouse() {        
  var data = {
    'warehouseid':0,            
  }
  this.appService.getWarehouse(data)
  .subscribe(
    (res)=>{                  
      this.warehouseColl=res.json().result;     
    },
  (err)=>{          
    this.toastr.error('Error', err);
  })
}

//Get 
fngetCity() {        
      var data = {
        'cityid':this.cityid,            
      }
      this.appService.getCity(data)
      .subscribe(
        (res)=>{                  
          this.cityColl=res.json().result;
          console.log(this.cityColl);
          var editdata = {
            'cityid':this.cityColl[0].cityid,
            'cityname':this.cityColl[0].cityname, 
            'warehouseid':this.cityColl[0].warehouseid,
            'inactive':this.cityColl[0].inactive,          
          }
          this.fnEditMode(editdata);
      },
      (err)=>{          
        this.toastr.error('Error', err);
      })
}

//Save Btn Click
fnSaveBtnClick(){
    try{
      this.submitted=true;
      this.successfully=false;
      //Validate
      if(this.cityform.valid){
          //Check
          var data = {
                'cityid': this.cityid,       
                'cityname':this.cityform.get('cityname').value, 
                'warehouseid':this.cityform.get('warehouseid').value,           
                'inactive': 0,  
                'createdby': this.loginService.fnGetModUserId(),    
                'modifiedby':this.loginService.fnGetModUserId(), 
          }
          this.appService.fnCheckCity(data).subscribe(
            (res)=>{
                var _checkresult=res.json().result[0].checkfield;
              
                if(_checkresult==="done"){
                      if (this.cityform.get('cityid').value>0){
                        //Update Department
                        this.fnSetCity(data);
                      }
                      else{
                        //Insert Department
                        this.fnInsertCity(data);
                      }
                }
                else{        
                      if(_checkresult==="name"){
                        this.cityform.controls['cityname'].setErrors({'notfound': true});
                      }
                }
          },
          (err)=>{
              this.toastr.error('Error', err);           
          });
       }
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}

//Insert
fnInsertCity(data){    
  
    this.appService.fnInsertCity(data).subscribe(
      (res)=>{
      if(res.json().result!=""){
        this.toastr.success('', "The new city has been added successfully.Insert Successfully");

        this.fnInsertActivityLog('INSERT',0,data.cityname);

        this.fnCloseCity();
      }},
      (err)=>{
        this.toastr.error('Error', err);
      });

}

//Set
fnSetCity(data){    

this.appService.fnSetCity(data).subscribe(
  (res)=>{
  if(res.json().result!=""){
      this.toastr.success('', "The city has been updated successfully.");

      this.fnInsertActivityLog('EDIT',this.cityid,data.cityname);

      this.fnCloseCity();        
  }},
  (err)=>{
    this.toastr.error('Error', err);
  });

}

//Close
fnCloseCity(){
try{
   this.router.navigate(['/City']);
}catch(ex){
    this.fnShowErrorMsg(ex);
}
}

addMarker(lat: number, lng: number) {
  this.markers.push({ lat, lng, alpha: 0.4 });
}

max(coordType: 'lat' | 'lng'): number {
  return Math.max(...this.markers.map(marker => marker[coordType]));
}

min(coordType: 'lat' | 'lng'): number {
  return Math.min(...this.markers.map(marker => marker[coordType]));
}

selectMarker(event) {
  this.selectedMarker = {
    lat: event.latitude,
    lng: event.longitude
  };
}


private fnEditMode(deptBO){
  this.cityform.setValue({
    cityname:deptBO.cityname,
    cityid:deptBO.cityid,
    warehouseid:deptBO.warehouseid,    
    inactive:deptBO.inactive,   
  });

this.isaddmode=false;
this.iseditmode=true;
this.isdeletemode=false;
this.isviewmode=false;
}

  
fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}


private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


private  fnShowErrorMsg(ex){
this.toastr.warning('Internal Error', ex);       
}




}
