import { Component, OnInit } from '@angular/core';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-viewcustomer',
  templateUrl: './viewcustomer.component.html',
  styleUrls: ['./viewcustomer.component.css']
})
export class ViewcustomerComponent implements OnInit {

  //Mode Values
isaddmode:boolean=false;
iseditmode:boolean=false;
isdeletemode:boolean=false;
isviewmode:boolean=false;

//Save Validation
submitted:boolean;
successfully:boolean;


//Edit Get Values
customerColl:any=[];
customerBO:any={};
addressColl:any=[];
loyaltyColl:any=[];
walletColl:any=[];
orderColl:any=[];
deviceColl:any=[];
viewproductColl:any=[];
customersearchColl: any = [];
cartColl: any = [];
  
customerid:number=0

isloading:boolean=false;
isprivilage:boolean=false;  
showProgress:boolean=false;
formname:String="Customer";
  
_routerSub = Subscription.EMPTY;
_routerparamsSub = Subscription.EMPTY;
_routerLoginSub = Subscription.EMPTY

constructor(private appService: DhukanDataService,private route: ActivatedRoute,private loginService:LoginDataService,
  public router: Router,private toastr: ToastrService,private datePipe: DatePipe,private confirmationDialogService: ConfirmationDialogService) { 
    this._routerSub = this.route.queryParams.subscribe(params => {
     if(params.id!==undefined){
       this.customerid = params.id
     }  
    });
    this.fnServiceChanges();
}


 
fnServiceChanges(){
  this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}

ngOnInit() {
    try{
      if(this.loginService.fnCheckUser()){ 
        if(this.fnCheckUserPririvilage()){       
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
              
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}
ngOnDestroy(): void {
  if (this._routerSub !== undefined) {
    this._routerSub.unsubscribe();
  }
  if (this._routerparamsSub !== undefined) {
    this._routerparamsSub.unsubscribe();
  }

  if (this._routerLoginSub !== undefined) {
    this._routerLoginSub.unsubscribe();
  }
}
fnOnInitForm(){
  try{
      this.isaddmode=true;
      
      if(this.customerid>0){
        this.fngetCustomer();
      }

  }catch(ex){
    this.fnShowErrorMsg(ex);
  }
}



fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.customerid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}

//Get 
fngetCustomer() {        
    this.fnShowProgress();
    var data = {
      'customerid':this.customerid,            
    }
    this.appService.getCustomerAdminView(data)
    .subscribe(      
      (res)=>{ 
        this.fnHideProgress();

        this.customerColl=res.json().result[0];
        if(this.customerColl.length){
          this.customerBO=this.customerColl[0];
        }
        this.addressColl=res.json().result[1];
        this.loyaltyColl=res.json().result[2];
        this.walletColl=res.json().result[3];
        this.orderColl=res.json().result[4];
        this.deviceColl=res.json().result[6];
        this.viewproductColl = res.json().result[7];        
        this.customersearchColl = res.json().result[8];
        this.cartColl = res.json().result[9];
        
    },
    (err)=>{   
      this.fnHideProgress();       
      this.toastr.error('Error', err);
    })
}

//Cutomer
fnEditCustomer(){
  try{
    let navigationExtras: NavigationExtras = {
    queryParams: {
      'id': this.customerid,
    }
    }

      this.router.navigate(['/AddCustomer'], navigationExtras);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}

//Order
fnEditOrder(orderid){
  try{
    let navigationExtras: NavigationExtras = {
    queryParams: {
      'id': orderid,
      'cid':this.customerid
    }
    }
  
      this.router.navigate(['/AddOrders'], navigationExtras);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}

  //Cart
fnEditCart(orderid){
  try{
    let navigationExtras: NavigationExtras = {
    queryParams: {
        'id': orderid,
        'customerid': this.customerid,
        'ftype': 'CUSTOMER',
    }
    }
  
      this.router.navigate(['/AddCart'], navigationExtras);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}

  
//Address

fnAddAddress(){
  try{
    let navigationExtras: NavigationExtras = {
    queryParams: {
      'cid': this.customerid,
    }
    }

  this.router.navigate(['/AddAddress'], navigationExtras);
}catch(ex){
  this.fnShowErrorMsg(ex);
}
}

fnEditAddress(addressid){
  try{
    let navigationExtras: NavigationExtras = {
    queryParams: {
      'id': addressid,
      'cid': this.customerid,
    }
    }

  this.router.navigate(['/AddAddress'], navigationExtras);
}catch(ex){
  this.fnShowErrorMsg(ex);
}
}

public fnDeleteAddessConfirm(deptid) {
  try{
    this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Address?')
    .then((confirmed) => 
          {
            if(confirmed){
              this.fnDeleteAddress(deptid)
            }          
        }        
    );     
    //.catch(() => );
  }catch(ex){
    this.fnShowErrorMsg(ex);
  }
}

private fnDeleteAddress(deptid){
     var data = {
       'addressid': deptid,            
     }
    this.appService.fnDeleteAddressAdmin(data)
      .subscribe(
        (res)=>{ 
          if(res.json().status===200){
           this.toastr.success('', " The Address has been deleted successfully.");                 
           window.location.reload();
          }else{
            this.toastr.error('',res.json().err_field);   
          }
        },
        (err)=>{          
          this.toastr.error('Error', err);
        })
}

//Loyalty

fnAddLoyalty(){
  try{
    let navigationExtras: NavigationExtras = {
    queryParams: {
      'cid': this.customerid,
    }
    }

  this.router.navigate(['/AddLoyalty'], navigationExtras);
}catch(ex){
  this.fnShowErrorMsg(ex);
}
}

fnEditLoyalty(addressid){
  try{
    let navigationExtras: NavigationExtras = {
    queryParams: {
      'id': addressid,
      'cid': this.customerid,
    }
    }

  this.router.navigate(['/AddLoyalty'], navigationExtras);
}catch(ex){
  this.fnShowErrorMsg(ex);
}
}

public fnDeleteLoyaltyConfirm(deptid) {
  try{
    this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Loyalty?')
    .then((confirmed) => 
          {
            if(confirmed){
              this.fnDeleteLoyalty(deptid)
            }          
        }        
    );     
    //.catch(() => );
  }catch(ex){
    this.fnShowErrorMsg(ex);
  }
}

private fnDeleteLoyalty(deptid){
     var data = {
       'loyaltyid': deptid,            
     }
    this.appService.fnDeleteLoyaltyAdmin(data)
      .subscribe(
        (res)=>{ 
          if(res.json().status===200){
           this.toastr.success('', " The Loyalty has been deleted successfully.");                 
           window.location.reload();
          }else{
            this.toastr.error('',res.json().err_field);   
          }
        },
        (err)=>{          
          this.toastr.error('Error', err);
        })
}

//Wallet

fnAddWallet(){
  try{
    let navigationExtras: NavigationExtras = {
    queryParams: {
      'cid': this.customerid,
    }
    }

  this.router.navigate(['/AddWallet'], navigationExtras);
}catch(ex){
  this.fnShowErrorMsg(ex);
}
}

fnEditWallet(addressid){
  try{
    let navigationExtras: NavigationExtras = {
    queryParams: {
      'id': addressid,
      'cid': this.customerid,
    }
    }

  this.router.navigate(['/AddWallet'], navigationExtras);
}catch(ex){
  this.fnShowErrorMsg(ex);
}
}

public fnDeleteWalletConfirm(deptid) {
  try{
    this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Wallet?')
    .then((confirmed) => 
          {
            if(confirmed){
              this.fnDeleteWallet(deptid)
            }          
        }        
    );     
    //.catch(() => );
  }catch(ex){
    this.fnShowErrorMsg(ex);
  }
}

private fnDeleteWallet(deptid){
     var data = {
       'walletid': deptid,            
     }
    this.appService.fnDeleteLoyaltyAdmin(data)
      .subscribe(
        (res)=>{ 
          if(res.json().status===200){
           this.toastr.success('', " The Wallet has been deleted successfully.");                 
           window.location.reload();
          }else{
            this.toastr.error('',res.json().err_field);   
          }
        },
        (err)=>{          
          this.toastr.error('Error', err);
        })
}




//Close
fnCloseCustomer(){
try{
 this.router.navigate(['/Customer']);
}catch(ex){
  this.fnShowErrorMsg(ex);
}
}


fnFormatDatetime(dt){
 if(dt){
   if(dt===""){
     return null
   }
   else{
     return this.datePipe.transform(dt,"yyyy-MM-dd")  
   }
   
   }else {
   return null
 }
 
}

fnConvertDatetime(dt){
 if(dt){
   if(dt===""){
     return null
   }
   else{
     

     var dateParts = dt.split("-"); 

      var sdate =new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]+1); 

      sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());

      
      
      return sdate;
   }
   
   }else {
   return null
 }

}



fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}



private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


private  fnShowErrorMsg(ex){
this.toastr.warning('Internal Error', ex);       
}

}
