import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-adduserrole',
  templateUrl: './adduserrole.component.html',
  styleUrls: ['./adduserrole.component.css']
})
export class AdduserroleComponent implements OnInit {




  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;

  urolerightsColl: any = [];

  //Edit Get Values
  uroleColl: any = [];
  uroleid: number = 0

  uroleform = new FormGroup({
    urolename: new FormControl('', Validators.required),
    uroleid: new FormControl(0),
    inactive: new FormControl(false),
  });

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "UserRole";



  private gridApi;
  private gridColumnApi;

  columnDefs = [
    { headerName: 'UserForm', field: 'userappformname', sortable: true, filter: true },
    { headerName: 'ModuleName', field: 'modulename', sortable: true, filter: true },
    {
      headerName: 'Create', checkboxSelection: false, headerCheckboxSelection: false, filter: false, sortable: false, field: 'uappcreate',
      cellRenderer: function (params) {
        let operatorValue = params.value;
        const input = document.createElement('input'); input.type = 'checkbox';
        if (operatorValue) {
          input.checked = true; params.data.uappcreate = true;
        } else {
          input.checked = false; params.data.uappcreate = false;
        }
        input.addEventListener('click', function (event) {
          input.checked != input.checked;
          params.data.uappcreate = input.checked;
        });
        return input;
      }
    },
    {
      headerName: 'Alter', checkboxSelection: false, headerCheckboxSelection: false, filter: false, sortable: false, field: 'uappalter',
      cellRenderer: function (params) {
        let operatorValue = params.value;
        const input = document.createElement('input'); input.type = 'checkbox';
        if (operatorValue) {
          input.checked = true; params.data.uappalter = true;
        } else {
          input.checked = false; params.data.uappalter = false;
        }
        input.addEventListener('click', function (event) {
          input.checked != input.checked;
          params.data.uappalter = input.checked;
        });
        return input;
      }
    },
    {
      headerName: 'Delete', checkboxSelection: false, headerCheckboxSelection: false, filter: false, sortable: false, field: 'uappdelete',
      cellRenderer: function (params) {
        let operatorValue = params.value;
        const input = document.createElement('input'); input.type = 'checkbox';
        if (operatorValue) {
          input.checked = true; params.data.uappdelete = true;
        } else {
          input.checked = false; params.data.uappdelete = false;
        }
        input.addEventListener('click', function (event) {
          input.checked != input.checked;
          params.data.uappdelete = input.checked;
        });
        return input;
      }
    },
    {
      headerName: 'View', checkboxSelection: false, headerCheckboxSelection: false, filter: false, sortable: false, field: 'uappview',
      cellRenderer: function (params) {
        let operatorValue = params.value;
        const input = document.createElement('input'); input.type = 'checkbox';
        if (operatorValue) {
          input.checked = true; params.data.uappview = true;
        } else {
          input.checked = false; params.data.uappview = false;
        }
        input.addEventListener('click', function (event) {
          input.checked != input.checked;
          params.data.uappview = input.checked;
        });
        return input;
      }
    },
    {
      headerName: 'Print', checkboxSelection: false, headerCheckboxSelection: false, filter: false, sortable: false, field: 'uappprint',
      cellRenderer: function (params) {
        let operatorValue = params.value;
        const input = document.createElement('input'); input.type = 'checkbox';
        if (operatorValue) {
          input.checked = true; params.data.uappprint = true;
        } else {
          input.checked = false; params.data.uappprint = false;
        }
        input.addEventListener('click', function (event) {
          input.checked != input.checked;
          params.data.uappprint = input.checked;
        });
        return input;
      }
    },


    {
      headerName: 'Export', checkboxSelection: false, headerCheckboxSelection: false, filter: false, sortable: false, field: 'uappexport',
      cellRenderer: function (params) {
        let operatorValue = params.value;
        const input = document.createElement('input'); input.type = 'checkbox';
        if (operatorValue) {
          input.checked = true; params.data.uappexport = true;
        } else {
          input.checked = false; params.data.uappexport = false;
        }
        input.addEventListener('click', function (event) {
          input.checked != input.checked;
          params.data.uappexport = input.checked;
        });
        return input;
      }
    },
  ]

  gridOptions = {
    rowSelection: 'single',
    rowHeight: 40,
    columnDefs: this.columnDefs,
    defaultColDef: {
      resizable: true,
      flex: 1,
      width: 100,
      minWidth: 100,
      enableValue: false,
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: true,
    },
  };
  
  _routerSub = Subscription.EMPTY;
  _routerparamsSub = Subscription.EMPTY;
  _routerLoginSub = Subscription.EMPTY

  constructor(private appService: DhukanDataService, private route: ActivatedRoute,
    public router: Router, private toastr: ToastrService, private loginService: LoginDataService) {
      this._routerSub = this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.uroleid = params.id
      }
    });
    this.fnServiceChanges();
  }



  fnServiceChanges() {
    this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {

      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.uroleform.reset();
      this.fnInitializeuroleFormGroup();

      if (this.uroleid > 0) {
        this.fngetUserRole();
      }

      this.fngetUserRoleRights();

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    if (this._routerSub !== undefined) {
      this._routerSub.unsubscribe();
    }
    if (this._routerparamsSub !== undefined) {
      this._routerparamsSub.unsubscribe();
    }
  
    if (this._routerLoginSub !== undefined) {
      this._routerLoginSub.unsubscribe();
    }
  }


  //Grid
  fnGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }


  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.uroleid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeuroleFormGroup() {
    this.uroleform.setValue({
      urolename: '',
      uroleid: 0,
      inactive: false,
    })
  }


  fngetUserRoleRights() {
    var data = {
      'uroleid': this.uroleid,
    }
    this.appService.getUserRoleRights(data)
      .subscribe(
        (res) => {
          this.urolerightsColl = res.json().result;
          this.fnSetDataTable();
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }


  //Get 
  fngetUserRole() {
    var data = {
      'uroleid': this.uroleid,
    }
    this.appService.getUserRole(data)
      .subscribe(
        (res) => {
          this.uroleColl = res.json().result;
          var editdata = {
            'uroleid': this.uroleColl[0].uroleid,
            'urolename': this.uroleColl[0].urolename,
            'inactive': this.uroleColl[0].inactive,
          }
          this.fnEditMode(editdata);
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.uroleform.valid) {
        //Check
        var data = {
          'uroleid': this.uroleid,
          'urolename': this.uroleform.get('urolename').value,
          'inactive': 0,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
          'urolerightsColl': this.urolerightsColl
        }
        this.appService.fnCheckUserRole(data).subscribe(
          (res) => {
            console.log(res.json())
            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {
              if (this.uroleform.get('uroleid').value > 0) {
                //Update Department
                this.fnSetUserRole(data);
              }
              else {
                //Insert Department
                this.fnInsertUserRole(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.uroleform.controls['urolename'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Insert
  fnInsertUserRole(data) {
    this.fnShowProgress();
    this.appService.fnInsertUserRole(data).subscribe(
      (res) => {
        this.fnHideProgress();
        if (res.json().result != "") {
          this.toastr.success('', "The new UserRole has been added successfully.Insert Successfully");

          this.fnInsertActivityLog('INSERT', 0, data.urolename);

          this.fnCloseUserRole();
        }
      },
      (err) => {
        this.fnHideProgress();
        this.toastr.error('Error', err);
      });

  }

  //Set
  fnSetUserRole(data) {
    this.fnShowProgress();
    this.appService.fnSetUserRole(data).subscribe(
      (res) => {
        this.fnHideProgress();
        if (res.json().result != "") {
          this.toastr.success('', "The UserRole has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.uroleid, data.urolename);

          this.fnCloseUserRole();
        }
      },
      (err) => {
        this.fnHideProgress();
        this.toastr.error('Error', err);
      });

  }


  fnCheckAllValue(values: any, index) {
    this.urolerightsColl[index].uappcreate = values.currentTarget.checked;
    this.urolerightsColl[index].uappalter = values.currentTarget.checked;
    this.urolerightsColl[index].uappdelete = values.currentTarget.checked;
    this.urolerightsColl[index].uappview = values.currentTarget.checked;
    this.urolerightsColl[index].uappprint = values.currentTarget.checked;
    this.urolerightsColl[index].uappexport = values.currentTarget.checked;
  }

  fnSaveAsBtnClick() {
    try {
      this.uroleid = 0;
      this.fnInitializeuroleFormGroup();
      this.router.navigate([]);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  //Close
  fnCloseUserRole() {
    try {
      this.router.navigate(['/UserRole']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnEditMode(deptBO) {

    this.uroleform.setValue({
      urolename: deptBO.urolename,
      uroleid: deptBO.uroleid,
      inactive: deptBO.inactive,
    });


    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }



  private fnSetDataTable() {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }



  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });

    } catch {

    }
  }


  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }



  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }






}
