import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-salesbycustomer',
  templateUrl: './salesbycustomer.component.html',
  styleUrls: ['./salesbycustomer.component.css']
})
export class SalesbycustomerComponent implements OnInit {

  mainColl: any = [];
  customerColl:any=[];

  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "SalesByCustomer";

  fromdate: any = null;
  todate: any = null;
  isshowfromdate: boolean = false;
  customerid:any = null;

  private gridApi;
  private gridColumnApi;
  pinnedBottomRowData;

  columnDefs = [
    { headerName: 'InvoiceNo', field: 'invoiceno', sortable: true, filter: true },
    {
      headerName: 'InvoiceDate', field: 'invoicedate', resizable: true ,sortable: true, filter: true,
      cellRenderer: (data) => {
        return this.fnMomentDateTimeFormatter(data)
      },
    },
    { headerName: 'OrderNo', field: 'orderno', sortable: true, filter: true },
    { headerName: 'ERPInvoiceNo', field: 'erpinvoiceno', sortable: true, filter: true },
    { headerName: 'Customer', field: 'customername', sortable: true, filter: true },
    { headerName: 'Branch', field: 'branch', sortable: true, filter: true },
    { headerName: 'Paymode', field: 'paymode', sortable: true, filter: true },
    { headerName: 'SubTotal', field: 'subtotal', sortable: true, filter: true, valueFormatter: this.fnFormatDecimal },
    { headerName: 'Discount', field: 'discamt', sortable: true, filter: true,valueFormatter:  this.fnFormatDecimal },      
    { headerName: 'SubTotal After Discount', field: 'itemtotal', sortable: true, filter: true, valueFormatter:  this.fnFormatDecimal },
    { headerName: 'Delivery Charges', field: 'deliverycharges', sortable: true, filter: true, valueFormatter: this.fnFormatDecimal },
    { headerName: 'RoundOff', field: 'roundoff', sortable: true, filter: true, valueFormatter:  this.fnFormatDecimal  },
    { headerName: 'Grand Total', field: 'grandtotal', sortable: true, filter: true, valueFormatter: this.fnFormatDecimal },
    { headerName: 'Taxable Amt', field: 'taxableamt', sortable: true, filter: true, valueFormatter: this.fnFormatDecimal },
    { headerName: 'Tax Amt', field: 'taxamt', sortable: true, filter: true, valueFormatter:  this.fnFormatDecimal },
    { headerName: 'Coupon', field: 'couponcode', sortable: true, filter: true},
    { headerName: 'Coupon Amount', field: 'couponamt', sortable: true, filter: true, valueFormatter:  this.fnFormatDecimal  },
    { headerName: 'Loyalty', field: 'loyaltyamt', sortable: true, filter: true, valueFormatter:  this.fnFormatDecimal },
    { headerName: 'ReferWalletAmount', field: 'referwalletamt', sortable: true, filter: true, valueFormatter: this.fnFormatDecimal  },
    { headerName: 'RedeemWalletAmount', field: 'redeemwalletamt', sortable: true, filter: true, valueFormatter:  this.fnFormatDecimal },
    { headerName: 'RefundWalletAmount', field: 'refundwalletamt', sortable: true, filter: true,valueFormatter: this.fnFormatDecimal  },
  ]

  gridOptions = {
    rowSelection: 'single',
    rowHeight: 40,
    columnDefs: this.columnDefs,
    defaultColDef: {
      resizable: true,
      flex: 1,
      width: 100,
      minWidth: 100,
      enableValue: false,
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: true,
    },
  };

  _routerSub = Subscription.EMPTY;
  _routerLoginSub = Subscription.EMPTY

  constructor(private appService: DhukanDataService, public router: Router,
    private confirmationDialogService: ConfirmationDialogService, private datePipe: DatePipe,
    private toastr: ToastrService, private loginService: LoginDataService) { this.fnServiceChanges(); }

  //User Check
  fnServiceChanges() {
    this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }
    });
  }


  ngOnInit() {
    try {

      this.fromdate = new Date();
      this.todate = new Date();


      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    this.fngetReportListView();
    this.fngetCustomer();
  }

  fnCheckPrivilage() {
    this.isadd = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE');
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE');
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
   
    if (this._routerSub !== undefined) {
      this._routerSub.unsubscribe();
    }
  
    if (this._routerLoginSub !== undefined) {
      this._routerLoginSub.unsubscribe();
    }
  }
  

  
  fngetCustomer() {
 
    var data = {
      'customerid': 0,
    }
    this.appService.getCustomerAdmin(data)
      .subscribe(
        (res) => {

          this.fnHideProgress();
          if (res.json().status == 200) {
            this.customerColl = res.json().result;            
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fngetReportListView() {
    if (this.showProgress) {
      return;
    }

    this.fnShowProgress();
    var fromdate = null;
    if (!this.isshowfromdate) {
      fromdate = this.fnFormatDatetime(this.fromdate);
    }
    var data = {
      'fromdate': fromdate,
      'todate': this.fnFormatDatetime(this.todate),
      'formname': 'SALESBYCUSTOMER',
      'customerid':this.customerid,
      'modifiedby': this.loginService.fnGetModUserId(),
    }
    this.appService.getReportSalesView(data)
      .subscribe(
        (res) => {
          this.fnHideProgress();
          if (res.json().status == 200) {
            this.mainColl = res.json().result[0];

            this.pinnedBottomRowData = res.json().result[1];



            this.fnSetTable();

          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }


  //Grid
  fnGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }


  private fnSetTable() {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();

      
    }

  }

  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }

  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {


        var dateParts = dt.split("-");

        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);

        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());



        return sdate;
      }

    } else {
      return null
    }

  }


  fnPrintData() {
    try {


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnExportData() {
    try {
      const params = {
        columnGroups: true,
        allColumns: true,
        fileName: 'CustomerSalesSummary',
        //columnSeparator: document.querySelector("#columnSeparator").value
      };
      this.gridApi.exportDataAsCsv(params);

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  fnFormatDecimal(params) {
    if (params.value) {
     return params.value.toFixed(2)
    }    
  }


    
fnMomentDateFormatter(params) {
  if (params.value) {
    return moment(params.value).utcOffset('+02:30',false).format('DD-MM-YYYY');//hh:mm a  
  }
  else {
    return '';
  }
}

fnMomentDateTimeFormatter(params) {
  if (params.value) {
    return moment(params.value).utcOffset('+02:30',false).format('DD-MM-YYYY hh:mm a');//  
  }
  else {
    return '';
  }
  
}

  
  
  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }






}
