import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit, AfterViewInit, OnDestroy {

   customerColl: any = [];


  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Customer";


  private gridApi;
  private gridColumnApi;

  columnDefs = [
    {
      headerName: 'Name', field: 'firstname', sortable: true, filter: true,
      cellRenderer: (data) => {
        return data.data.firstname + ' ' + data.data.lastname;
      }
    },
    { headerName: 'Mobile', field: 'mobile', sortable: true, filter: true },
    { headerName: 'Email', field: 'email', sortable: true, filter: true },
    { headerName: 'AltMobile', field: 'altmobile', sortable: true, filter: true },
    { headerName: 'Joindt', field: 'createdon', sortable: true,  filter:true, valueFormatter: this.fnMomentDateTimeFormatter },        
  ]

  gridOptions = {
    rowSelection: 'single',
    rowHeight: 40,
    columnDefs: this.columnDefs,
    defaultColDef: {
      resizable: true,
      flex: 1,
      width: 100,
      minWidth: 100,
      enableValue: false,
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: true,
    },
  };

  _routerSub = Subscription.EMPTY;
  _routerLoginSub = Subscription.EMPTY
  
  
  constructor(private appService: DhukanDataService, public router: Router,
    private confirmationDialogService: ConfirmationDialogService, private toastr: ToastrService, private datePipe: DatePipe, private loginService: LoginDataService) { this.fnServiceChanges(); }

  //User Check
  fnServiceChanges() {
    this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }
    });
  }


  ngOnInit() {
    try {
      
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    this.fngetCustomer();
  }

  fnCheckPrivilage() {
    this.isadd = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE');
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE');
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');

    this.isdelete = false;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }

  ngAfterViewInit(): void {
  
  }

  ngOnDestroy(): void {
   
    if (this._routerSub !== undefined) {
      this._routerSub.unsubscribe();
    }
  
    if (this._routerLoginSub !== undefined) {
      this._routerLoginSub.unsubscribe();
    }
  }

  
  //Grid
  fnGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;   
  }

  
  //Event  
  fnActionEditClick() {
    if (!this.gridApi) {
      return;
    }
    
    if (this.gridApi.getSelectedRows().length > 0 ) {
      var data = this.gridApi.getSelectedRows()[0];
      this.fnEditCustomer(data);
   }   
  }

  fnActionExportClick()  {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'customerlist',      
    };
    this.gridApi.exportDataAsCsv(params);
  }

  
  fngetCustomer() {
    if (this.showProgress) {
      return;
    }

    this.fnShowProgress();
    var data = {
      'paymodeid': 0,
    }
    this.appService.getCustomerAdmin(data)
      .subscribe(
        (res) => {

          this.fnHideProgress();
          if (res.json().status == 200) {
            this.customerColl = res.json().result;
            this.fnSetDataTable();
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fnAddCustomer() {
    try {
      this.router.navigate(['/AddCustomer']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnEditCustomer(deptBO) {
    try {
      if (this.isedit) {
        let navigationExtras: NavigationExtras = {
          queryParams: {
            'id': deptBO.customerid,
          }
        }

        this.router.navigate(['/CustomerView'], navigationExtras);
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  
  private fnSetDataTable() {
    
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }


  fnPrintData() {
    try {

    
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnExportData() {
    try {

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }


  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {


        var dateParts = dt.split("-");

        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);

        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());



        return sdate;
      }

    } else {
      return null
    }

  }

  fnMomentDateFormatter(params) {
    return moment(params.value).utcOffset('+02:30',false).format('DD-MM-YYYY');//hh:mm a  
  }

  fnMomentDateTimeFormatter(params) {
    return moment(params.value).utcOffset('+02:30',false).format('DD-MM-YYYY hh:mm a');//
  }

  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }





}
