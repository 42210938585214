import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxImageCompressService } from 'ngx-image-compress';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { ProductfindComponent } from '../../productfind/productfind.component';
import { ProductfaqComponent } from '../../productfaq/productfaq.component';
import { ProductspecificationComponent } from '../../productspecification/productspecification.component';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';


@Component({
  selector: 'app-addtest',
  templateUrl: './addtest.component.html',
  styleUrls: ['./addtest.component.css']
})
export class AddtestComponent implements OnInit {

  @ViewChild('skuForm',{static:false}) skuForm: NgForm; 
  @ViewChild('skuEditForm',{static:false}) skuEditForm: NgForm; 

  //@ViewChild('tabset',{static:false}) tabset;

  //Mode Values
  isaddmode:boolean=false;
  iseditmode:boolean=false;
  isdeletemode:boolean=false;
  isviewmode:boolean=false;
  
  //Save Validation
  submitted:boolean;
  successfully:boolean;
  faqsubmitted:boolean;
  faqsuccessfully:boolean;
  
  
  
   //set image
   image;
   strImage: any;
  
  //Edit Get Values
  productColl:any=[];
  productskuColl:any=[];
  productid:number=0
  
  //Master
  deptColl:any=[];
  categoryColl:any=[];
  subcategoryColl:any=[];
  brandColl:any=[];
  warehouseColl:any=[];
  taxgroupColl:any=[];
  vendorColl:any=[];
  uomColl:any=[];
  certificateColl:any=[];
  foodsymbolColl:any=[];
  itemtypeColl:any=[];
  
  loopindex:number=0;
  
  deptid:number=0;
  categoryid:number=null;  
  subcategoryid:number=null;  
  brandid:number=null;
  
  productform=new FormGroup({
      productid:new FormControl(0),      
      productcode:new FormControl('',Validators.required),
      title:new FormControl('',Validators.required),         
      deptid:new FormControl(0,Validators.required),      
      categoryid:new FormControl(0,Validators.required),      
      subcategoryid:new FormControl(0,Validators.required),      
      brandid:new FormControl(0,Validators.required),
      vendorid:new FormControl(0),      
      taxgrpid:new FormControl(0,Validators.required) ,      
      foodsymbolid:new FormControl(0),      
      certificateid:new FormControl(0),      
      tags:new FormControl(0),        
     inactive:new FormControl(false),      
  });
  
  question:string="";
  answer:string="";
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
  showProgress:boolean=false;
  formname:String="Product";
  

  skuBO:any={};  
  i:number=-1;

  isskueditmode:boolean=false;

  _routerSub = Subscription.EMPTY;
_routerparamsSub = Subscription.EMPTY;
_routerLoginSub = Subscription.EMPTY

  constructor(private appService: DhukanDataService,private route: ActivatedRoute,
     public router: Router,private toastr: ToastrService,private confirmationDialogService: ConfirmationDialogService,
     private productFindService: NgbModal,private loginService:LoginDataService,
     private imageCompress: NgxImageCompressService,private ng2ImgMaxService: Ng2ImgMaxService) { 
      this._routerSub = this.route.queryParams.subscribe(params => {
        if(params.id!==undefined){
          this.productid = params.id
        }  
  
        if(params.deptid!==undefined){
          this.deptid = params.deptid
        }  
  
        if(params.categoryid!==undefined){
          this.categoryid = params.categoryid
        }  
  
        if(params.subcategoryid!==undefined){
          this.subcategoryid = params.subcategoryid
        }  
  
        if(params.brandid!==undefined){
          this.brandid = params.brandid
        }  
  
       });
  
       this.fnServiceChanges();
  
  }
  
  
  
  
  fnServiceChanges(){
    this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
          if(this.loginService.fnCheckUser()){
           
            if(this.fnCheckUserPririvilage()){
              this.isloading=true;
              this.fnOnInitForm();
            }
            else{
              this.fnUserFailedForm();
            }
              
          }
          else{
            this.fnUserFailedForm();
          }
    });
  }
  
  ngOnInit() {
      try{
             
        if(this.loginService.fnCheckUser()){ 
          if(this.fnCheckUserPririvilage()){       
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
        }
        else{
          this.loginService.fnGetUserRoleList();
        }
                
      }catch(ex){
        this.fnShowErrorMsg(ex);
      }
  }
  ngOnDestroy(): void {
    if (this._routerSub !== undefined) {
      this._routerSub.unsubscribe();
    }
    if (this._routerparamsSub !== undefined) {
      this._routerparamsSub.unsubscribe();
    }
  
    if (this._routerLoginSub !== undefined) {
      this._routerLoginSub.unsubscribe();
    }
  }
  fnOnInitForm(){
     try{
         this.isaddmode=true;
         this.productform.reset();    
         this.fnInitializeProductFormGroup(); 
       
  
         if(this.productid>0){
           this.fngetProduct();                    
         }else{
          this.fnGetAutoCodeProduct();        
         }
        
         this.fngetWarehouse();
         this.fngetDepartment();
         this.fngetCategory();
         this.fngetSubCategory();     
         this.fngetBrand();
         this.fngetTaxGroup();
         this.fngetVendor();
         this.fngetUom();
         this.fngetFoodSymbol();
         this.fngetCertificate();
         this.fnGetItemType();
      
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
  }
  

  
  fnCheckUserPririvilage(){
    var _check:boolean=false;
    if (this.productid>0){
      _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
    }else{
      _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
    }
  
    return _check;
  }
  
  fnUserFailedForm(){
    this.isprivilage=true;
  }
  fnInitializeProductFormGroup(){
     this.productform.patchValue({   
        productid:0,   
        productcode:'',
        title:'',         
        deptid:null,   
        categoryid:null,   
        subcategoryid:null,   
        brandid:null, 
        vendorid:null,   
        taxgrpid:null,   
        foodsymbolid:null,   
        certificateid:null,   
        tags:'',   
        inactive:false,         
     })
     
    this.productColl=[];
     this.productskuColl=[];
     this.deptColl=[];
     this.categoryColl=[];
     this.subcategoryColl=[];
     this.brandColl=[];
     this.warehouseColl=[];
     this.taxgroupColl=[];
     this.vendorColl=[];
     this.uomColl=[];
     this.certificateColl=[];
     this.foodsymbolColl=[];
     this.itemtypeColl=[];

  }
  
  
  
  //Get Master
  //Get Warehouse
  fngetWarehouse() {        
    var data = {
      'warehouseid':0,            
    }
    this.appService.getWarehouse(data)
    .subscribe(
      (res)=>{                  
        this.warehouseColl=res.json().result;     
  
        if(this.productid===0){   
          this.productskuColl=[];         
          this.fnAddSku();
        }
  
  
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
  }
  
  fngetDepartment() {        
    var data = {
      'deptid':0,            
    }
    this.appService.getDepartment(data)
    .subscribe(
      (res)=>{                  
        this.deptColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
  }
  
  fngetCategory() {        
    let _deptid=this.productform.get('deptid').value;
    var data = {
      'categoryid':0,        
      'deptid':_deptid,     
    }
  
    if(_deptid>0){
      this.appService.getCategory(data)
      .subscribe(
        (res)=>{                  
          this.categoryColl=res.json().result;     
        },
      (err)=>{          
        this.toastr.error('Error', err);
      })
    }
    else{
      this.categoryColl=[];
    }
  }
  
  fngetSubCategory() {        
    let _categoryid=this.productform.get('categoryid').value;
    var data = {
      'subcategoryid':0, 
      'categoryid':_categoryid,            
    }
    if(_categoryid>0){
        this.appService.getSubCategory(data)
        .subscribe(
          (res)=>{                        
            this.subcategoryColl=res.json().result;     
          },
        (err)=>{          
          this.toastr.error('Error', err);
        })
    }
    else{
      this.subcategoryColl=[];
    }
  
  }
  
  fngetBrand() {        
    var data = {
      'brandid':0,            
    }
    this.appService.getBrand(data)
    .subscribe(
      (res)=>{                  
        this.brandColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
  }
  
  fngetTaxGroup() {        
    var data = {
      'taxgroupid':0,            
    }
    this.appService.getTaxGroup(data)
    .subscribe(
      (res)=>{                  
        this.taxgroupColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
  }
  
  fngetVendor() {        
    var data = {
      'vendorid':0,            
    }
    this.appService.getVendor(data)
    .subscribe(
      (res)=>{                  
        this.vendorColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
  }
  
  fngetUom() {        
    var data = {
      'uomid':0,            
    }
    this.appService.getUom(data)
    .subscribe(
      (res)=>{                  
        this.uomColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
  }
  
  fngetCertificate() {        
    var data = {
      'certificateid':0,            
    }
    this.appService.getCertificate(data)
    .subscribe(
      (res)=>{                  
        this.certificateColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
  }
  
  fngetFoodSymbol() {        
    var data = {
      'foodsymbolid':0,            
    }
    this.appService.getFoodSymbol(data)
    .subscribe(
      (res)=>{                  
        this.foodsymbolColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
  }
  
  fnGetItemType(){
    this.itemtypeColl.push({name:'S',displayname:'Simple'})
    this.itemtypeColl.push({name:'C',displayname:'Combo'})
  
  }
  
  //Change Event
  fnDepartmentChange(){
    let deptid=this.productform.get('deptid').value;
    if(deptid===null){
      this.productform.patchValue({categoryid:null});
      this.productform.patchValue({subcategoryid:null});
    }
    
  }
  
  fnCategoryChange(){
    let categoryid=this.productform.get('categoryid').value;
    if(categoryid===null){
      this.productform.patchValue({subcategoryid:null});
    }
    
  }
  
  //Get Product
  fngetProduct() {        
    this.fnShowProgress();
  
       var data = {
         'productid':this.productid,            
       }
       this.appService.getProduct(data)
       .subscribe(
         (res)=>{          
         
          this.fnHideProgress();

          //console.log(res);
          if(res.json().status==200){
           
           
           
  
            //Product Main
            this.productColl=res.json().result[0];
            
  
             //Product Sku
             let _prodsku=res.json().result[1];
            let _prodskuprice=res.json().result[2];
            let _prodskupictures=res.json().result[3];      
            let _prodskuproductrelated=res.json().result[4];
            let _prodskuproductcrosssell=res.json().result[5];
            let _prodskuproductfaq=res.json().result[6];
            let _prodskuproductspecification=res.json().result[7];
            let _prodskuproductcombo=res.json().result[8];
  
            this.productskuColl=[];

            this.skuBO={};
            this.i=-1;
            
            for(let pbo of _prodsku){
              //Product Sku Price
              var productskupriceColl = []
              for(let mbo of _prodskuprice){
                  if(mbo.skuid===pbo.skuid){
                    productskupriceColl.push(mbo)
                  }
              }            
              pbo.skuprice=productskupriceColl;
  
              //Product Sku Pictures
              var productskupictureColl = []
              for(let cbo of _prodskupictures){
                  if(cbo.skuid===pbo.skuid){
                    productskupictureColl.push(cbo)
                    
                  }
              }            
              pbo.skuimages=productskupictureColl;
  
              pbo.productrelated=_prodskuproductrelated;
            
              pbo.productcrosssell=_prodskuproductcrosssell;
              
              pbo.productfaq=_prodskuproductfaq;
  
              pbo.productspecification=_prodskuproductspecification;
  
              pbo.productcombo=_prodskuproductcombo;
  
              this.productskuColl.push(pbo);
            }
            
            if(this.productskuColl.length){
              this.skuBO=this.productskuColl[0];
              this.i=0;
              this.isskueditmode=true;
            }
            

  
            var editdata = {
              'productid':this.productColl[0].productid,
              'productcode':this.productColl[0].productcode, 
              'title':this.productColl[0].title, 
              'deptid':this.productColl[0].deptid,    
              'categoryid':this.productColl[0].categoryid,    
              'subcategoryid':this.productColl[0].subcategoryid,    
              'brandid':this.productColl[0].brandid,    
              'vendorid':this.productColl[0].vendorid,    
              'taxgrpid':this.productColl[0].taxgrpid,    
              'foodsymbolid':this.productColl[0].foodsymbolid,    
              'certificateid':this.productColl[0].certificateid,    
              'tags':this.productColl[0].tags,    
              'inactive':this.productColl[0].inactive,          
            }
  
            this.fnEditMode(editdata);
  
          }
       },
       (err)=>{          
        this.fnHideProgress();
         this.toastr.error('Error', err);
       })
  }
  
  fnGetAutoCodeProduct(){
    var data={}
  
    this.appService.getAutoCodeProduct(data).subscribe(
      (res)=>{
      
        if(res.json().status===200){
          let code=res.json().result[0][0].productcode;
          this.productform.patchValue({productcode:code});
        }
      
      },
      (err)=>{
          this.toastr.error('Error', err);           
      });
  }
  
  fnGetAutoSkuProduct(index){
    var data={}
  
    this.appService.getAutoCodeProduct(data).subscribe(
      (res)=>{
        if(res.json().status===200){        
          let sku=res.json().result[1][0].sku;
          this.productskuColl[index].sku=sku;
          this.productskuColl[index].autosku=sku;        
        }  
      },
      (err)=>{
          this.toastr.error('Error', err);           
    });
  }
  
  //Validate
  fnValidateProduct(myForm):boolean{
    let _check:boolean=false
    

    if(!this.productform.valid){   
      _check=true;
    }   
    else if(myForm){
      
      //  console.log(myForm);
        if(!myForm.valid && this.productid===0){
          
          var _p=0;
          for(let wbo of this.productskuColl){
              if(wbo.sku==="" || wbo.productname==="" || wbo.uomid===null ||  wbo.uomqty===""){
                //this.tabset.select("ngb-tab-"+_p);
                break;
              }
              _p=_p+1;
          }



          _check=true;
        }
     
    } 
  
    
    //else{    
       // for(let wbo of this.productskuColl){
       //   const index = this.productskuColl.findIndex(sku => sku.skuid !== wbo.skuid  && sku.sku === wbo.sku );
      //    if(index>-1){
       //     _check=true;
        //    return _check;
        //  }
      //  }
   // }
  
  
    return _check;
  }
  
  
  //Save Btn Click
  fnSaveBtnClick(myForm,mode){
  
     try{
       this.submitted=true;
       this.successfully=false;
  
  
       //Validate
       if(!this.fnValidateProduct(myForm)){      
      
         
          this.fnShowProgress();
          
          this.fnSetModifiedBySku();
           //Check
           var data = {
                 'productid': this.productid,       
                 'productcode':this.productform.get('productcode').value,              
                 'title':this.productform.get('title').value,                
                 'deptid':this.productform.get('deptid').value,    
                 'categoryid':this.productform.get('categoryid').value,    
                 'subcategoryid':this.productform.get('subcategoryid').value,    
                 'brandid':this.productform.get('brandid').value,    
                 'vendorid':this.productform.get('vendorid').value,    
                 'taxgrpid':this.productform.get('taxgrpid').value,    
                 'foodsymbolid':this.productform.get('foodsymbolid').value,    
                 'certificateid':this.productform.get('certificateid').value,    
                 'tags':this.productform.get('tags').value,    
                 'inactive': 0,  
                 'createdby': this.loginService.fnGetModUserId(),    
                 'modifiedby':this.loginService.fnGetModUserId(),  
                 "productsku":this.productskuColl
           }
           //CheckMain
           this.appService.fnCheckProduct(data).subscribe(
             (res)=>{
                 var _checkresult=res.json().result[0].checkfield;
               
                 if(_checkresult==="done"){
                        if(this.productid===0){
                            //Check SKu                                           
                            this.appService.fnCheckProductSku(this.skuBO).subscribe(                        
                              (res1)=>{                       
                                var _checkresultsku=res1.json().result[0].checkfield;
                            
                                if(_checkresultsku==="done"){                                
                                    if (this.productform.get('productid').value>0){
                                          //Update Product
                                      this.fnSetProduct(data,mode);
                                    }
                                    else{
                                      //Insert Product
                                      this.fnInsertProduct(data,mode);
                                    }                                                         
                                }else{
                                  if(_checkresultsku==="name"){       
                                    this.fnHideProgress();                                                           
                                    myForm.controls['sku-'+ this.i].setErrors({'notfound': true});
                                    return
                                  }                            
                                }                           
                              },
                              (err)=>{
                                this.toastr.error('Error', err);           
                            });
                        }
                        else{
                           //Update Product
                           this.fnSetProduct(data,mode);
                        } 
                 }
                 else{  
                       this.fnHideProgress();      
                       if(_checkresult==="name"){
                         this.productform.controls['productcode'].setErrors({'notfound': true});
                       }
                 }
           },
           (err)=>{
               this.fnHideProgress();      
               this.toastr.error('Error', err);           
           });
        }
     }catch(ex){


       this.fnHideProgress();      
       this.fnShowErrorMsg(ex);
     }
  }
  
  //Insert
  fnInsertProduct(data,mode){    

     this.appService.fnInsertProduct(data).subscribe(
       (res)=>{
        this.fnHideProgress(); 
       if(res.json().result!=""){
         this.toastr.success('', "The new product has been added successfully.Insert Successfully");

        

         if(mode==='EDITSAVE'){
          this.fnReloadProduct(res.json().result);
         }
         else if(mode==='SAVE'){
          this.fnCloseProduct();
         }
        
       }},
       (err)=>{
         this.toastr.error('Error', err);
       });
  
  }
  
  //Set
  fnSetProduct(data,mode){    
  
  this.appService.fnSetProduct(data).subscribe(
   (res)=>{
   
    this.fnHideProgress();      

   if(res.json().result!=""){
       this.toastr.success('', "The product has been updated successfully.");
       if(mode==='SAVEEDIT'){
        this.fnReloadProduct(this.productid);
       }
       else if(mode==='SAVE'){
        this.fnCloseProduct();
       }
   }},
   (err)=>{
     console.log(err)
     this.toastr.error('Error', err);
   });
  
  }
  
  onSubmit(myForm) {
    console.log(myForm);
  }
  
  fnReloadProduct(id){
    try{

      
      
      let navigationExtras: NavigationExtras = {
        queryParams: {   
          'deptid': this.deptid,
          'categoryid': this.categoryid,
          'subcategoryid': this.subcategoryid,
          'brandid': this.brandid,
          'id': id,
        }
      }
    
      this.router.navigate(['/AddTest'], navigationExtras).then(()=>{
        window.location.reload();
      });;  
     
    }catch(ex){
       this.fnShowErrorMsg(ex);
    }
  }

  
  //Close
  fnCloseProduct(){
  try{
    let navigationExtras: NavigationExtras = {
      queryParams: {   
        'deptid': this.deptid,
        'categoryid': this.categoryid,
        'subcategoryid': this.subcategoryid,
        'brandid': this.brandid,
      }
    }
  
    this.router.navigate(['/Test'], navigationExtras);  
  }catch(ex){
     this.fnShowErrorMsg(ex);
  }
  }
  
  
  private fnEditMode(deptBO){
  
   
   this.productform.patchValue({
      productid:deptBO.productid,
      productcode:deptBO.productcode,
      title:deptBO.title,    
      deptid:deptBO.deptid,   
      categoryid:deptBO.categoryid,   
      subcategoryid:deptBO.subcategoryid,   
      brandid:deptBO.brandid,   
       taxgrpid:deptBO.taxgrpid,   
       vendorid:deptBO.vendorid,   
       foodsymbolid:deptBO.foodsymbolid,   
       certificateid:deptBO.certificateid,   
       tags:deptBO.tags,   
      inactive:deptBO.inactive,       
    });
  
  this.fngetCategory();
  this.fngetSubCategory();
  
  
  this.isaddmode=false;
  this.iseditmode=true;
  this.isdeletemode=false;
  this.isviewmode=false;
  }
  
  //Add SKU
  private fnAddSku(){
    this.productskuColl.push(this.fnAddSkuBO());
    this.fnGetAutoSkuProduct(this.productskuColl.length-1);

    this.skuBO=this.productskuColl[this.productskuColl.length-1];
    this.i=this.productskuColl.length-1;
   
    this.isskueditmode=true;

   
  }
  
  private fnAddSkuBO(){
  
    var productsku = {  
      sno:this.productskuColl.length+1,
      skuid:0,
      productid:this.productid,
      sku:"",
      autosku:"",
      productname:"",
      productnamear:"",
      shortdescr:"",
      fulldescr:"",
      uomqty:1,
      uomid:null,
      mrp:0,
      ispublished:0,
      allowreview:0,
      availfrom:"2019-10-14",
      availto:"2019-10-14",
      isnew:0,
      newfrom:"2019-10-14",
      newto:"2019-10-14",
      admincomment:"",
      ratings:0,
      deliverydays:null,    
      itemtype:'',    
      isreturnable:0,    
      isfreecharge:0,
      warrenty:0,    
      inactive:0,    
      createdby:0,   
      modifiedby:0,
      image:null,
      strImage:null,
      picurl:"",
      picid:0,
      picname:"",
      skuprice:this.fnAddSkuPriceBO(),
      skuimages:[],
      productrelated:[],
      productcrosssell:[],
      productfaq:[],
      productspecification:[],
      productcombo:[]
    }
    return productsku;
  
  }
  
  private fnAddSkuPriceBO(){
    var productskupriceColl = []
  
    for(let wbo of this.warehouseColl){
      var priceBO = {
        warehouseid:wbo.warehouseid,
        warehousename:wbo.warehousename,
        productid:this.productid,
        skuid:0,
        sellingprice:0,
        stock:0,
        qtyonorder:0,
        isoutoforder:0,
      }
      productskupriceColl.push(priceBO)
  
    }
  
    return productskupriceColl;
  
  }

  //Edit Sku
  private fnEditSkuBtnClick(index){
  
    this.skuBO=this.productskuColl[index];
    this.i=index;
    this.isskueditmode=true;
  }

  
  //Delete SKu
  private fnDeleteSku(skuid,sno){
  
    if(this.productskuColl.length===1){
      return;
      // this.confirmationDialogService.confirm('Info..', 'Minimum One Sku in this Product','ALERT')
      // .then((confirmed) => 
      //       {
      //         if(confirmed){
      //           return;
      //         }          
      //     }        
      // );  
  
    }
    else{
  
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Product Sku?')
      .then((confirmed) => 
            {
              if(confirmed){
  
                if(skuid>0){
                  this.fnDeleteSkuExist(skuid);              
                  this.fngetProduct();
                }else{
                  const index = this.productskuColl.findIndex(sku => sku.sno === sno); 
                  this.productskuColl.splice(index, 1); 
                }
              
              }          
          }        
      ); 
    }    
   
  }
  
  fnDeleteSkuExist(skuid) {        
    var data = {
      'skuid':skuid,            
    }
    this.appService.fnDeleteProductSku(data)
    .subscribe(
      (res)=>{                 
        if(res.json().status==200){
          const index = this.productskuColl.findIndex(sku => sku.skuid === skuid); 
          this.productskuColl.splice(index, 1); 
          this.fngetProduct();
        }else{
          this.toastr.error('',res.json().err_field);   
       }  
  
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
  }


   //Validate Sku
   fnValidateProductSku(myForm):boolean{
    let _check:boolean=false
  
     if(!myForm.valid){
        
        _check=true;
     } 
  
    
     return _check;
  }

    //Save SKu
  private fnSaveSkuBtnClick(myForm){

 try{
       this.submitted=true;
       this.successfully=false;

       if(!this.fnValidateProductSku(myForm)){    

        this.fnShowProgress();
          
        this.fnSetModifiedBySku();

          this.appService.fnCheckProductSku(this.skuBO).subscribe(                        
            (res1)=>{                       
              var _checkresultsku=res1.json().result[0].checkfield;
          
              if(_checkresultsku==="done"){
                  this.fnSaveSkuOnly();                    
              }
              else{
                if(_checkresultsku==="name"){       
                  this.fnHideProgress();                                         
                  myForm.controls['skunew-'+ this.i].setErrors({'notfound': true});
                  return
                }                            
              }
              this.loopindex= this.loopindex+1;
            },
            (err)=>{
              this.toastr.error('Error', err);           
          });
        }

    }catch(ex){
      this.fnHideProgress();      
      this.fnShowErrorMsg(ex);
    }

  

  }
  

  private fnSaveSkuOnly(){
    this.fnShowProgress();

    this.appService.fnSetProductSku(this.skuBO).subscribe(
      (res)=>{
      
      if(res.json().result!=""){
        this.toastr.success('', "The product has been updated successfully.");
        this.fnHideProgress();
        this.fngetProduct();
      }},
      (err)=>{
        this.fnHideProgress();
        console.log(err)
        this.toastr.error('Error', err);
      });
  }

  private fnCancelSkuBtnClick(){
    
   


    if(this.skuBO.skuid===0){
      this.productskuColl.splice(this.i, 1); 
    }
    
    this.skuBO={};
    this.i=-1;
    this.isskueditmode=false;
  }
  
  
  
  //Edit Sku
  private fnEditSku(index,feild,value){
  
    if(feild==="sku"){
      this.productskuColl[index].sku=value;
    }
    else  if(feild==="shortdescr"){
      this.productskuColl[index].shortdescr=value;
    }
    else  if(feild==="uomqty"){
      this.productskuColl[index].uomqty=value;
    }
    else  if(feild==="mrp"){
      this.productskuColl[index].mrp=value;
    }
    else  if(feild==="productname"){
      this.productskuColl[index].productname=value;
    }
    else  if(feild==="fulldescr"){
      this.productskuColl[index].fulldescr=value;
    }
    else  if(feild===""){
      this.productskuColl[index].sku=value;
    }
    else  if(feild===""){
      this.productskuColl[index].sku=value;
    }
    else  if(feild===""){
      this.productskuColl[index].sku=value;
    }
    else  if(feild===""){
      this.productskuColl[index].sku=value;
    }
    
  }
  private fnEditSkuPrice(index,priceindex,feild,value){
  
    if(feild==="sellingprice"){
      this.productskuColl[index].skuprice[priceindex].sellingprice=value;
    }
    else  if(feild==="stock"){
      this.productskuColl[index].skuprice[priceindex].stock=value;
    }
    else  if(feild==="isoutoforder"){
      this.productskuColl[index].skuprice[priceindex].isoutoforder=value;
    }
  
   
  }
  
  private fnEditSkuImages(index,event){
    try{
  
      if (event.target.files && event.target.files[0]) {
        var filesAmount = event.target.files.length;
  
        for (let i = 0; i < filesAmount; i++) {
            const fileReader: FileReader = new FileReader();
  
            fileReader.onload = (event) => {
              
              this.image = fileReader.result;
              this.strImage = this.image.split(',')[1];    
  
              // this.ng2ImgMaxService.resize([this.image], 2000, 1000).subscribe((result)=>{
  
             
  
              //   this.strImage = result.split(',')[1];    
  
  
              //   var _skuimages=
              //   {
              //         productid:this.productid,
              //         skuid:0,
              //         picid:0,
              //         image:result,
              //         strImage:this.strImage,
              //         isclear:0,
              //         picurl:""
              //   }                      
              //   this.productskuColl[index].skuimages.push(_skuimages);
              // });
  
  
  
  
              this.imageCompress.compressFile(this.image,  75, 50).then(
                result => {
                
                  this.strImage = result.split(',')[1];    
  
  
                  var _skuimages=
                  {
                        productid:this.productid,
                        skuid:0,
                        picid:0,
                        image:result,
                        strImage:this.strImage,
                        isclear:0,
                        picurl:""
                  }                      
                  this.productskuColl[index].skuimages.push(_skuimages);
  
                }
              );
     
            }
            fileReader.readAsDataURL(event.target.files[i]);
        }
  
  
  
  
    }
  
      
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  
  }
  
  
  private fnSetModifiedBySku(){
    for(let mbo of this.productskuColl){
      mbo.createdby=this.loginService.fnGetModUserId();
      mbo.modifiedby=this.loginService.fnGetModUserId();
  
      for(let pbo of mbo.skuprice){
        pbo.createdby=this.loginService.fnGetModUserId();
        pbo.modifiedby=this.loginService.fnGetModUserId();
      }
  
    }
  }
  
  
  removeimage(skuindex,pictureindex){
  
      if(skuindex>-1){
        if(pictureindex>-1){
  
          if(this.productskuColl[skuindex].skuimages[pictureindex].picid>0){
            this.productskuColl[skuindex].skuimages[pictureindex].isclear=1;          
          }
          else{
            this.productskuColl[skuindex].skuimages.splice(pictureindex, 1); 
          }
  
        }
        
      }
  
  }
    
  fnOpenFileBrowser(event:any,index){
  
    event.preventDefault();
  
    let element:HTMLElement=document.getElementById('image'+index) as HTMLElement;
    element.click();
  
  }
  
  //Related Product
  fnAddRelatedProduct(skuid,sno){
    
    
    const modalRef = this.productFindService.open(ProductfindComponent ,{ size: <any>'xl'  });
    modalRef.componentInstance.doctype ="RELATED PRODUCT" ;
  
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
  
      const index = this.productskuColl.findIndex(sku => sku.skuid === skuid  && sku.sno === sno ); 
  
      for(let wbo of receivedEntry){
  
        if(this.productskuColl[index].productrelated.length===0){
          wbo.relatedproductid=wbo.productid;
          wbo.relatedskuid=wbo.skuid;
          this.productskuColl[index].productrelated.push(wbo);
        }
        else{
  
         const exitindex = this.productskuColl[index].productrelated.findIndex(sku => sku.productid === wbo.productid  && sku.skuid===wbo.skuid ); 
  
          if(exitindex<0){
            wbo.relatedproductid=wbo.productid;
            wbo.relatedskuid=wbo.skuid;
            this.productskuColl[index].productrelated.push(wbo);
          }
  
        }
       
  
      }
    });
  
  
  }
  
  fnDeleteRelatedProduct(skuid,sno,prodindex){
    this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Related Product')
    .then((confirmed) => 
          {
            if(confirmed){
                  const index = this.productskuColl.findIndex(sku => sku.skuid === skuid  && sku.sno === sno );  
                  if(index>-1){
                    this.productskuColl[index].productrelated.splice(prodindex, 1); 
                  }
        }
      });
  }
  
  
  //Crosssell Product
  fnAddCrosssellProduct(skuid,sno){
    
    
    const modalRef = this.productFindService.open(ProductfindComponent ,{ size: 'lg' });
    modalRef.componentInstance.doctype ="CROSSSELL PRODUCT" ;
  
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
  
      const index = this.productskuColl.findIndex(sku => sku.skuid === skuid  && sku.sno === sno ); 
  
      for(let wbo of receivedEntry){
  
        if(this.productskuColl[index].productcrosssell.length===0){
          wbo.csproductid=wbo.productid;
          wbo.csskuid=wbo.skuid;
          this.productskuColl[index].productcrosssell.push(wbo);
        }
        else{
  
         const exitindex = this.productskuColl[index].productcrosssell.findIndex(sku => sku.productid === wbo.productid  && sku.skuid===wbo.skuid ); 
  
          if(exitindex<0){
            wbo.csproductid=wbo.productid;
            wbo.csskuid=wbo.skuid;
            this.productskuColl[index].productcrosssell.push(wbo);
          }
  
        }
       
  
      }
    });
  
  
  }
  
  fnDeleteCrosssellProduct(skuid,sno,prodindex){
    this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Crosselll Product?')
    .then((confirmed) => 
          {
            if(confirmed){
                    const index = this.productskuColl.findIndex(sku => sku.skuid === skuid  && sku.sno === sno );  
                    if(index>-1){
                    this.productskuColl[index].productcrosssell.splice(prodindex, 1); 
                   }        
          }
        });
  }
  
  
  //Faq Product
  fnAddFaqProduct(skuid,sno){
    const modalRef = this.productFindService.open(ProductfaqComponent ,{ size: <any>'xl' });
    modalRef.componentInstance.doctype ="PRODUCT FAQ" ;
  
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
       
      const index = this.productskuColl.findIndex(sku => sku.skuid === skuid  && sku.sno === sno );  
  
      var _faq={
        question:receivedEntry.question,
        answer:receivedEntry.answer,
        productid:0,
        skuid:0
      }
      this.productskuColl[index].productfaq.push(_faq);
        
    });
        
  }
  
  fnDeleteFaqProduct(skuid,sno,prodindex){
    this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Faq?')
    .then((confirmed) => 
          {
            if(confirmed){
                const index = this.productskuColl.findIndex(sku => sku.skuid === skuid  && sku.sno === sno );  
                if(index>-1){
                  this.productskuColl[index].productfaq.splice(prodindex, 1); 
                }
            }
          });
  }
  
  fnEditFaqProduct(skuid,sno,prodindex){
    const modalRef = this.productFindService.open(ProductfaqComponent ,{ size: 'lg' });
    modalRef.componentInstance.doctype ="PRODUCT FAQ" ;
    const index = this.productskuColl.findIndex(sku => sku.skuid === skuid  && sku.sno === sno );
  
    if(index>-1){      
     // console.log(this.productskuColl[index].productfaq);
    //  console.log(prodindex);
  
      modalRef.componentInstance.question=this.productskuColl[index].productfaq[prodindex].question;
      modalRef.componentInstance.answer=this.productskuColl[index].productfaq[prodindex].answer;    
    }
  
  
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
       
      const index = this.productskuColl.findIndex(sku => sku.skuid === skuid  && sku.sno === sno );     
      this.productskuColl[index].productfaq[prodindex].question=receivedEntry.question;
      this.productskuColl[index].productfaq[prodindex].answer=receivedEntry.answer
    });
    
  }
  
  //Specification Product
  fnAddSpecificationProduct(skuid,sno){
    const modalRef = this.productFindService.open(ProductspecificationComponent ,{ size: 'lg' });
    modalRef.componentInstance.doctype ="PRODUCT SPECIFICATION" ;
    modalRef.componentInstance.mode ="ADD" ;
  
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
  
       
      const index = this.productskuColl.findIndex(sku => sku.skuid === skuid  && sku.sno === sno );  
  
  
      var _specification={
        specificationid:receivedEntry.specificationid,
        specificationname:receivedEntry.specificationname,
        value:receivedEntry.value,
        productid:0,
        skuid:0
      }
      this.productskuColl[index].productspecification.push(_specification);
      
    });
        
  }
  
  fnDeleteSpecificationProduct(skuid,sno,prodindex){
    this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Faq?')
    .then((confirmed) => 
          {
            if(confirmed){
                const index = this.productskuColl.findIndex(sku => sku.skuid === skuid  && sku.sno === sno );  
                if(index>-1){
                  this.productskuColl[index].productspecification.splice(prodindex, 1); 
                }
          }
          });
  }
  
  fnEditSpecificationProduct(skuid,sno,prodindex){
  
    const modalRef = this.productFindService.open(ProductspecificationComponent ,{ size: 'lg' });
    modalRef.componentInstance.doctype ="PRODUCT SPECIFICATION" ;
    modalRef.componentInstance.mode ="EDIT" ;
  
    const index = this.productskuColl.findIndex(sku => sku.skuid === skuid  && sku.sno === sno );  
    if(index>-1){      
      modalRef.componentInstance.specificationid=this.productskuColl[index].productspecification[prodindex].specificationid;
      modalRef.componentInstance.editorValue=this.productskuColl[index].productspecification[prodindex].value;    
    }
  
  
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
       
      const index = this.productskuColl.findIndex(sku => sku.skuid === skuid  && sku.sno === sno ); 
  
      this.productskuColl[index].productspecification[prodindex].specificationid=receivedEntry.specificationid;
      this.productskuColl[index].productspecification[prodindex].specificationname=receivedEntry.specificationname;
      this.productskuColl[index].productspecification[prodindex].value=receivedEntry.value;
      
    });
  }
  
  //Combo Product
  fnAddComboProduct(skuid,sno){
    
    
    const modalRef = this.productFindService.open(ProductfindComponent ,{ size: <any>'xl'  });
    modalRef.componentInstance.doctype ="COMBO PRODUCT" ;
  
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
  
      const index = this.productskuColl.findIndex(sku => sku.skuid === skuid  && sku.sno === sno ); 
  
      for(let wbo of receivedEntry){
  
        if(this.productskuColl[index].productcombo.length===0){
          wbo.comboproductid=wbo.productid;
          wbo.comboskuid=wbo.skuid;
          this.productskuColl[index].productcombo.push(wbo);
        }
        else{
  
         const exitindex = this.productskuColl[index].productcombo.findIndex(sku => sku.productid === wbo.productid  && sku.skuid===wbo.skuid ); 
  
          if(exitindex<0){
            wbo.comboproductid=wbo.productid;
            wbo.comboskuid=wbo.skuid;
            this.productskuColl[index].productcombo.push(wbo);
          }
  
        }
       
  
      }
    });
  
  
  }
  
  fnDeleteComboProduct(skuid,sno,prodindex){
    this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Combo Product')
    .then((confirmed) => 
          {
            if(confirmed){
                  const index = this.productskuColl.findIndex(sku => sku.skuid === skuid  && sku.sno === sno );  
                  if(index>-1){
                    this.productskuColl[index].productcombo.splice(prodindex, 1); 
                  }
        }
      });
  }
  
  


  private fnShowProgress(){
    this.showProgress=true;
  }
  private fnHideProgress(){
    this.showProgress=false;
  }
  
  private  fnShowErrorMsg(ex){
    console.log(ex)
    this.toastr.warning('Internal Error', ex);       
  }
  
  
  
    

}