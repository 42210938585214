import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { TabHeadingDirective } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.css']
})
export class AdduserComponent implements OnInit {

//Mode Values
isaddmode:boolean=false;
iseditmode:boolean=false;
isdeletemode:boolean=false;
isviewmode:boolean=false;

//Save Validation
submitted:boolean;
successfully:boolean;

userroleColl:any=[];

branchColl:any=[];

show: boolean;

//Edit Get Values
userColl:any=[];
userid:number=0

landingscreenColl:any=[];

userform=new FormGroup({
  username:new FormControl('',Validators.required),         
  userpwd:new FormControl('',Validators.required),         
  displayname:new FormControl(''),         
  landingscreen:new FormControl(''),         
  uroleid:new FormControl(0,Validators.required),    
  userid:new FormControl(0),      
 inactive:new FormControl(false),     
});

isloading:boolean=false;
isprivilage:boolean=false;  
showProgress:boolean=false;
formname:String="User";

_routerSub = Subscription.EMPTY;
_routerparamsSub = Subscription.EMPTY;
_routerLoginSub = Subscription.EMPTY

constructor(private appService: DhukanDataService,private route: ActivatedRoute,
   public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
    this._routerSub = this.route.queryParams.subscribe(params => {
      
          
       if(params.id!==undefined){
        this.userid = params.id
      }    

     });

     this.fnServiceChanges();
}


 
fnServiceChanges(){
  this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}

ngOnInit() {
    try{
      if(this.loginService.fnCheckUser()){ 
        if(this.fnCheckUserPririvilage()){       
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
              

      
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}
ngOnDestroy(): void {
  if (this._routerSub !== undefined) {
    this._routerSub.unsubscribe();
  }
  if (this._routerparamsSub !== undefined) {
    this._routerparamsSub.unsubscribe();
  }

  if (this._routerLoginSub !== undefined) {
    this._routerLoginSub.unsubscribe();
  }
}
fnOnInitForm(){
   try{
       this.isaddmode=true;
       this.userform.reset();
       this.fnInitializeUserFormGroup(); 

       if(this.userid>0){
         this.fngetUser();
       }
       else{
         this.fngetBranch();
       }
       this.fngetUserRole();
       this.fnGetLandingScreen();
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}


fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.userid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}

fnInitializeUserFormGroup(){
   this.userform.setValue({
      username:'',
      userpwd:'',
      displayname:'',
      landingscreen:'',
      uroleid:null,
      userid:0,      
     inactive:false,      
   })
}



fnGetLandingScreen(){
  this.landingscreenColl=[];
  this.landingscreenColl.push({name:'ORDER',displayname:'Order'})
  this.landingscreenColl.push({name:'SHIPMENT',displayname:'Shipment'})
  this.landingscreenColl.push({name:'SETTLEMENT',displayname:'Settlement'})
  this.landingscreenColl.push({name:'PRODUCT',displayname:'Product'})
  this.landingscreenColl.push({name:'DASHBOARD',displayname:'Dashboard'})

  
}


//Get User Role
fngetUserRole() {        
  var data = {
    'uroleid':0,                
  }
  this.appService.getUserRole(data)
  .subscribe(
    (res)=>{                  
      this.userroleColl=res.json().result;     
    },
  (err)=>{          
    this.toastr.error('Error', err);
  })
}


//Get Branch
fngetBranch() {        
  var data = {
    'branchid':0,                
  }
  this.appService.getBranch(data)
  .subscribe(
    (res)=>{                  
      this.branchColl=res.json().result;     
    },
  (err)=>{          
    this.toastr.error('Error', err);
  })
}


//Get 
fngetUser() {        
  this.fnShowProgress();
     var data = {
       'userid':this.userid,            
     }
     this.appService.getUser(data)
     .subscribe(       
       (res)=>{ 
        this.fnHideProgress();                 
         this.userColl=res.json().result[0];
         this.branchColl=res.json().result[1];
      

         var editdata = {
           'userid':this.userColl[0].userid,
           'username':this.userColl[0].username, 
           'userpwd':this.userColl[0].userpwd, 
           'displayname':this.userColl[0].displayname, 
           'landingscreen':this.userColl[0].landingscreen,
           'uroleid':this.userColl[0].uroleid,
           'inactive':this.userColl[0].inactive,          
         }
         this.fnEditMode(editdata);
     },
     (err)=>{          
       this.toastr.error('Error', err);
     })
}

//Save Btn Click
fnSaveBtnClick(){
   try{
     this.submitted=true;
     this.successfully=false;
     //Validate
     if(this.userform.valid){
         //Check
         var data = {
               'userid': this.userid,       
               'username':this.userform.get('username').value,                           
               'userpwd':this.userform.get('userpwd').value,                           
               'displayname':this.userform.get('displayname').value,
               'landingscreen':this.userform.get('landingscreen').value,
               'uroleid':this.userform.get('uroleid').value,                           
               'inactive': 0,  
               'createdby': this.loginService.fnGetModUserId(),    
               'modifiedby':this.loginService.fnGetModUserId(), 
               'userbranchColl':this.fnGetUserBranch(),
         }
         this.appService.fnCheckUser(data).subscribe(
           (res)=>{
               var _checkresult=res.json().result[0].checkfield;
             
               if(_checkresult==="done"){
                     if (this.userform.get('userid').value>0){
                       //Update Department
                       this.fnSetUser(data);
                     }
                     else{
                       //Insert Department
                       this.fnInsertUser(data);
                     }
               }
               else{        
                     if(_checkresult==="name"){
                       this.userform.controls['username'].setErrors({'notfound': true});
                     }
               }
         },
         (err)=>{
             this.toastr.error('Error', err);           
         });
      }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}

//Insert
fnInsertUser(data){    
 this.fnShowProgress();
   this.appService.fnInsertUser(data).subscribe(
     (res)=>{
       this.fnHideProgress();
     if(res.json().result!=""){
       this.toastr.success('', "The new user has been added successfully.Insert Successfully");

       this.fnInsertActivityLog('INSERT',0,data.username);

       this.fnCloseUser();
     }},
     (err)=>{
      this.fnHideProgress();
       this.toastr.error('Error', err);
     });

}

//Set
fnSetUser(data){    
  this.fnShowProgress()
this.appService.fnSetUser(data).subscribe(
 (res)=>{
  this.fnHideProgress();
 if(res.json().result!=""){
     this.toastr.success('', "The user has been updated successfully.");

     this.fnInsertActivityLog('EDIT',this.userid,data.username);

     this.fnCloseUser();        
 }},
 (err)=>{
  this.fnHideProgress();
   this.toastr.error('Error', err);
 });

}

//Close
fnCloseUser(){
try{
  this.router.navigate(['/User']);
}catch(ex){
   this.fnShowErrorMsg(ex);
}
}

private fnEditMode(deptBO){
 this.userform.setValue({
    username:deptBO.username,
    userid:deptBO.userid,
    userpwd:deptBO.userpwd,
    displayname:deptBO.displayname,
    landingscreen:deptBO.landingscreen,
    uroleid:deptBO.uroleid,
   inactive:deptBO.inactive,   
 });

this.isaddmode=false;
this.iseditmode=true;
this.isdeletemode=false;
this.isviewmode=false;
}


fnGetUserBranch(){
  let _a:any=[];
  for(let abo of this.branchColl){
    if(abo.ischeck===true || abo.ischeck===1){
      _a.push(abo);
    }    
  }
  return _a;

}


fnShowpassword() {
 
  this.show = !this.show;
}

checkValue(values:any,index){
  this.branchColl[index].ischeck=values.currentTarget.checked;    
}


 
fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}


private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


private  fnShowErrorMsg(ex){
this.toastr.warning('Internal Error', ex);       
}


}
