import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-addsettings',
  templateUrl: './addsettings.component.html',
  styleUrls: ['./addsettings.component.css']
})
export class AddsettingsComponent implements OnInit {

//Mode Values
isaddmode:boolean=false;
iseditmode:boolean=false;
isdeletemode:boolean=false;
isviewmode:boolean=false;

//Save Validation
submitted:boolean;
successfully:boolean;


//Edit Get Values
settingsColl:any=[];
  settingsid: number = 0
  settingsBO: any = {};


  pageColl: any = [];
//set image
image;
strImage: any;

picurl:string="";
picname:string="";

settingsform=new FormGroup({
  settingstype:new FormControl(''),         
  settingsname:new FormControl(''), 
  settingsvalue:new FormControl(''),  
  linktype:new FormControl(''),  
  linktargetid:new FormControl(''),         
  picimage:new FormControl(null),   
  settingsid:new FormControl(0),    
});

isloading:boolean=false;
isprivilage:boolean=false;  
showProgress:boolean=false;
formname:String="Settings";

settingsmode:String=''

_routerSub = Subscription.EMPTY;
_routerparamsSub = Subscription.EMPTY;
_routerLoginSub = Subscription.EMPTY

constructor(private appService: DhukanDataService,private route: ActivatedRoute,
   public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
    this._routerSub = this.route.queryParams.subscribe(params => {
      if(params.id!==undefined){
        this.settingsid = params.id
      }  
     });
     this.fnServiceChanges();

}


 
 fnServiceChanges(){
  this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}

ngOnInit() {
    try{
      if(this.loginService.fnCheckUser()){ 
        if(this.fnCheckUserPririvilage()){       
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
              
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}
ngOnDestroy(): void {
  if (this._routerSub !== undefined) {
    this._routerSub.unsubscribe();
  }
  if (this._routerparamsSub !== undefined) {
    this._routerparamsSub.unsubscribe();
  }

  if (this._routerLoginSub !== undefined) {
    this._routerLoginSub.unsubscribe();
  }
}
fnOnInitForm(){

   try{
       this.isaddmode=true;
       this.settingsform.reset();
       this.fnInitializesettingsFormGroup(); 

       if(this.settingsid>0){
         this.fngetsettings();
       }
     
     this.fngetPage();
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}


fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.settingsid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}

fnInitializesettingsFormGroup(){
   this.settingsform.setValue({
    settingstype:'',         
    settingsname:'', 
    settingsvalue:'',  
    linktype:'',  
    linktargetid:'',         
    settingsid:0,   
    picimage:null
   })
}

//Get 
fngetsettings() {        
     var data = {
       'settingsid':this.settingsid,            
     }
     this.appService.getSettings(data)
     .subscribe(
       (res)=>{                  
         this.settingsColl=res.json().result;
         this.settingsBO = this.settingsColl[0];

         if (this.settingsColl[0].linktype === 'PAGE') {
          this.settingsColl[0].settingsvalue=Number.parseInt(this.settingsColl[0].settingsvalue)
         }
         var editdata = {
           'settingsid':this.settingsColl[0].settingsid,
           'settingstype':this.settingsColl[0].settingstype, 
           'settingsvalue':this.settingsColl[0].settingsvalue, 
           'settingsname':this.settingsColl[0].settingsname, 
           'linktype':this.settingsColl[0].linktype, 
           'linktargetid':this.settingsColl[0].linktargetid, 
           'settingsmode':this.settingsColl[0].mode
         }
         this.fnEditMode(editdata);
     },
     (err)=>{          
       this.toastr.error('Error', err);
     })
}

//Save Btn Click
fnSaveBtnClick(){
   try{
     this.submitted=true;
     this.successfully=false;
     //Validate
     if(this.settingsform.valid){
         //Check
         var data = {
               'settingsid': this.settingsid,       
               'settingstype':this.settingsform.get('settingstype').value,            
               'settingsvalue':this.settingsform.get('settingsvalue').value,
               'settingsname':this.settingsform.get('settingsname').value,
               'picimage': this.strImage,
               'linktype':this.settingsform.get('linktype').value,
               linktargetid:this.settingsform.get('linktargetid').value,               
               modifiedby:this.loginService.fnGetModUserId()
         }
        
         if (this.settingsform.get('settingsid').value>0){
              //Update Department
              this.fnSetSettings(data);
        }
                  
              
        
      }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}


//Set
fnSetSettings(data){    

this.appService.fnSetSettings(data).subscribe(
 (res)=>{
 if(res.json().result!=""){
     this.toastr.success('', "The settings has been updated successfully.");

     this.fnInsertActivityLog('EDIT',this.settingsid,data.settingsname);

     this.fnCloseSettings();        
 }},
 (err)=>{
   this.toastr.error('Error', err);
 });

}

//Close
fnCloseSettings(){
try{
  this.router.navigate(['/Settings']);
}catch(ex){
   this.fnShowErrorMsg(ex);
}
}

private fnEditMode(deptBO){

 this.settingsform.setValue({
  settingsid:deptBO.settingsid,
  settingstype:deptBO.settingstype, 
  settingsvalue:deptBO.settingsvalue, 
  settingsname:deptBO.settingsname, 
  linktype:deptBO.linktype, 
  linktargetid:deptBO.linktargetid, 
  picimage:null
 });

 this.picurl =deptBO.settingsvalue;   


 this.settingsmode=deptBO.settingsmode

 if(this.settingsmode==='IMAGE'){
  this.isviewmode=true;
 }
this.isaddmode=false;
this.iseditmode=true;
this.isdeletemode=false;
//this.isviewmode=false;
}



//Image Import
changeListener($event): void {
  try{
     this.readThis($event.target);
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
  }
  
  readThis(inputValue: any): void {
  
   var file: File = inputValue.files[0];
   var myReader: FileReader = new FileReader();
  
   myReader.onloadend = (e) => {
       this.image = myReader.result;
       this.strImage = this.image.split(',')[1];
   }
   myReader.readAsDataURL(file);
  }
  
  removeimage(){
   this.image =null;
   this.strImage =null;
   this.picurl="";
  }
  
  
  fnOpenFileBrowser(event:any){
  
    event.preventDefault();
  
    let element:HTMLElement=document.getElementById('image') as HTMLElement;
    element.click();
  
  }


 fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}


  
fngetPage() {        
 
  var data = {
    'pageid':0            
  }


    this.appService.getPageByListView(data)
    .subscribe(
      (res)=>{                  
        this.pageColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}
  
private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}



private  fnShowErrorMsg(ex){
this.toastr.warning('Internal Error', ex);       
}





}
