import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import 'jspdf';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { OrderaddressmapComponent } from '../../orderaddressmap/orderaddressmap.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { CancelorderComponent } from '../cancelorder/cancelorder.component';
import { ProductfindComponent } from '../../productfind/productfind.component';
import { EditordersComponent } from '../editorders/editorders.component';
import { PrintorderComponent } from '../printorder/printorder.component';
import { DatePipe } from '@angular/common';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker/models';
import { OrderoptionComponent } from '../orderoption/orderoption.component';
import { EditdeliverydateComponent } from '../editdeliverydate/editdeliverydate.component';
import { ZoomproductComponent } from '../zoomproduct/zoomproduct.component';
import { CustomeravaildateComponent } from '../customeravaildate/customeravaildate.component';
import { Subscription } from 'rxjs';
import { DiscountorderComponent } from '../discountorder/discountorder.component';
declare let jsPDF;

@Component({
  selector: 'app-addorders',
  templateUrl: './addorders.component.html',
  styleUrls: ['./addorders.component.css']
})
export class AddordersComponent implements OnInit {
//Mode Values
isaddmode:boolean=false;
iseditmode:boolean=false;
isdeletemode:boolean=false;
isviewmode:boolean=false;

//Save Validation
submitted:boolean;
successfully:boolean;


//Edit Get Values
statusColl:any=[];

ordermainColl:any=[];
ordermainBo:any={};
orderdetailsColl:any=[];
orderpayColl:any=[];
orderpayBo:any={};
orderaddressColl:any=[];
orderaddressBo:any={};
orderid:number=0;
customerid:number=0;
statusid:number=0;

isorderedit:boolean=false;
isorderconfirm:boolean=false;
isordershipment:boolean=false;
isprintdoslip:boolean=false;
isprintorder:boolean=false;

dateCustomClasses: DatepickerDateCustomClasses[];

deliveryorderBO:any={};

deliveryorderColl:any=[];
deliveryorderdetailscoll:any=[];
deliveryorderdetailallcoll:any=[];
deliverytypeColl:any=[];
branchColl:any=[];

shipmentdetailscoll:any=[];

subtotal=0;
discountamount=0;
taxableamt=0;
taxamt=0;
nettotal=0;

distance:any;

orderform=new FormGroup({
  orderno:new FormControl(''),         
  orderid:new FormControl(0),      
  orderdate:new FormControl(''),         
  customername:new FormControl(''),         
  mobileno:new FormControl(''),      
  addressnickname:new FormControl(''),      
  houseno:new FormControl(''),      
  street:new FormControl(''),      
  areano:new FormControl(''),      
  cityname:new FormControl(''),      
  landmark:new FormControl(''),      
  statusid:new FormControl(0),      
  status:new FormControl(''),       
});

orderconfirmform=new FormGroup({
  deliverytypeid:new FormControl(null),  
  expectdeliverydate:new FormControl(null),  
  expectdeliverytime:new FormControl(null),  
  branchid:new FormControl(null,Validators.required),  
})
@ViewChild('reportContent',{static:false}) reportContent: ElementRef;

isloading:boolean=false;
isprivilage:boolean=false;  
showProgress:boolean=false;
formname:String="Order";

_routerSub = Subscription.EMPTY;
_routerparamsSub = Subscription.EMPTY;
_routerLoginSub = Subscription.EMPTY

constructor(private appService: DhukanDataService,private route: ActivatedRoute,
   public router: Router,private toastr: ToastrService,private confirmationDialogService: ConfirmationDialogService,
   private productFindService: NgbModal,private loginService:LoginDataService,private datePipe: DatePipe) { 
    this._routerSub = this.route.queryParams.subscribe(params => {
      if(params.id!==undefined){
        this.orderid = params.id
      }  

      if(params.cid!==undefined){
        this.customerid = params.cid
      } 

      
   
    });

    this.dateCustomClasses = [
      { date: new Date(), classes: ['bg-warning'] },
    ];
    
    this.fnServiceChanges();
}

fnServiceChanges(){
  this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}



ngOnInit() {
  try{
    if(this.loginService.fnCheckUser()){ 
      if(this.fnCheckUserPririvilage()){       
        this.isloading=true;
        this.fnOnInitForm();
      }
      else{
        this.fnUserFailedForm();
      }
    }
    else{
      this.loginService.fnGetUserRoleList();
    }
            
  }catch(ex){
    this.fnShowErrorMsg(ex);
  }
}
ngOnDestroy(): void {
  if (this._routerSub !== undefined) {
    this._routerSub.unsubscribe();
  }
  if (this._routerparamsSub !== undefined) {
    this._routerparamsSub.unsubscribe();
  }

  if (this._routerLoginSub !== undefined) {
    this._routerLoginSub.unsubscribe();
  }
}
fnOnInitForm(){
   try{
       this.isaddmode=true;
       this.orderform.reset();
       this.fnInitializeStatusFormGroup(); 
       this.fngetDeliveryType();
       this.fngetBranch();
       this.fngetStatus();

       if(this.orderid>0){
         this.fngetOrder();
       }
     
       
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}


fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.orderid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }
  

  this.isorderedit= this.loginService.fnCheckUserRoleAction("Order",'ALTER')
  this.isorderconfirm= this.loginService.fnCheckUserRoleAction("OrderConfirm",'ALTER')
  this.isordershipment= this.loginService.fnCheckUserRoleAction("Shipment",'CREATE')

  this.isprintdoslip= this.loginService.fnCheckUserRoleAction("DOPrint",'ALTER')
  this.isprintorder= this.loginService.fnCheckUserRoleAction("OrderPrint",'ALTER')


  if(this.isorderconfirm && !_check){
    _check=this.isorderconfirm;
  }
  else if(this.isordershipment && !_check){
    _check=this.isordershipment;
  }


  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}



fnInitializeStatusFormGroup(){
   this.orderform.setValue({
    orderno:'',         
    orderid:0,      
    orderdate:'', 
    customername:'',      
    mobileno:'', 
    addressnickname:'', 
    houseno:'', 
    street:'', 
    areano:'', 
    cityname:'', 
    landmark:'', 
    statusid:null, 
    status:'',   
   })

   this.orderconfirmform.setValue({
    deliverytypeid:null,
    expectdeliverydate:null,
    expectdeliverytime:null,
    branchid:null,
   })
}

fngetDeliveryType() {   
  
    var data = {
          'deliverytypeid': 0,            
    }
    this.appService.getDeliveryType(data)
        .subscribe(
          (res)=>{                  
            this.fnHideProgress();       
            if(res.json().status==200){                          
              this.deliverytypeColl=res.json().result;
             
            }
            else if(res.json().status==400){                          
              this.toastr.error('Error', res.json().err_field);
            }
           
           
        },
          (err)=>{      
            this.fnHideProgress();           
            this.toastr.error('Error', err);
          })
}

fngetBranch() {   
  
  var data = {
        'branchid': 0,            
  }
  this.appService.getBranch(data)
      .subscribe(
        (res)=>{                  
          this.fnHideProgress();       
          if(res.json().status==200){                          
            this.branchColl=res.json().result;
           
          }
          else if(res.json().status==400){                          
            this.toastr.error('Error', res.json().err_field);
          }
         
         
      },
        (err)=>{      
          this.fnHideProgress();           
          this.toastr.error('Error', err);
        })
}



fngetOrder() {   
  this.fnShowProgress()     
  var data = {
    'orderid':this.orderid,            
  }
  this.appService.getOrderbyOrderid(data)
  .subscribe(
    (res)=>{  
      this.fnHideProgress();                
      this.ordermainColl=res.json().result[0];
      this.orderdetailsColl=res.json().result[1];
      this.orderpayColl=res.json().result[2];
      this.orderaddressColl=res.json().result[3];
      this.deliveryorderColl=res.json().result[4];
      this.deliveryorderdetailallcoll=res.json().result[5];

      this.ordermainBo=this.ordermainColl[0]
      
      if(this.orderaddressColl.length){
        for(let abo of this.orderaddressColl){
          if(abo.addresstype==='D'){
            this.orderaddressBo=abo;

            this.distance=this.getDistanceFromLatLonInKm(this.orderaddressBo.latitude,this.orderaddressBo.longitude,this.orderaddressBo.arealatitude,this.orderaddressBo.arealongitude).toFixed(1);
           
          }
        }
      }

      if(this.orderpayColl.length){
        this.orderpayBo=this.orderpayColl[0];
      }
      if(this.deliveryorderColl.length){
        this.deliveryorderBO=this.deliveryorderColl[0];      
      }
      else{
        this.deliveryorderBO=this.ordermainBo;
      }
      
      

      var editdata = {        
        orderno:this.ordermainColl[0].orderno,     
        orderid:this.ordermainColl[0].orderid,    
        orderdate:this.ordermainColl[0].orderdate,
        customername:this.ordermainColl[0].customername,     
        mobileno:this.ordermainColl[0].mobile,
        addressnickname:this.ordermainColl[0].addressnickname,
        houseno:this.ordermainColl[0].houseno,
        street:this.ordermainColl[0].street,
        areano:this.ordermainColl[0].areaname,
        cityname:this.ordermainColl[0].cityname,
        landmark:this.ordermainColl[0].landmark,
        statusid:this.ordermainColl[0].statusid,
        status:this.ordermainColl[0].status,               
      }
      this.fnEditMode(editdata);

      let _a :any=[]
      for(let abo of  this.deliveryorderColl){
        abo.deliveryorderdetailscoll=_a;
      }

    
    for(let abo1 of  this.deliveryorderColl){
      let _dcoll :any=[]

      for(let abo2 of  this.deliveryorderdetailallcoll){        
        if(abo1.deliveryorderid === abo2.deliveryorderid)
        {
          _dcoll.push(abo2);
        
        }      
      }
      abo1.deliveryorderdetailscoll=_dcoll;
    }

    this.fnCalcSummary();


  },
  (err)=>{          
    this.fnHideProgress()
    this.toastr.error('Error', err);
  })
}

fnCalcSummary(){
  this.subtotal=0;
  this.discountamount=0;
  this.taxableamt=0;
  this.taxamt=0;
  this.nettotal=0;

  for(let abo of this.orderdetailsColl){

    if(abo.iscancelled){    
      continue;
    }

    this.subtotal=this.subtotal+abo.subtotal;
    this.discountamount=this.discountamount+abo.discamt;
    this.taxableamt=this.taxableamt+abo.sttaxable;
    this.taxamt=this.taxamt+abo.sttaxamt;
    this.nettotal=this.nettotal+abo.nettotal;
  }
}

//Get 
fngetStatus() {        
     var data = {
       'statusid':0,            
     }
     this.appService.getStatus(data)
     .subscribe(
       (res)=>{                  
         this.statusColl=res.json().result;  
     },
     (err)=>{          
       this.toastr.error('Error', err);
     })
}

//Save Btn Click
fnSaveBtnClick(){
   try{
     this.submitted=true;
     this.successfully=false;
     //Validate
    // if(this.orderform.valid){
         //Check
         var data = {
               'orderid': this.orderid,       
               'statusid':this.orderform.get('statusid').value,            
             }
       
          if (this.orderform.get('orderid').value>0){
                //Update Department
             //  this.fnSetOrderStatus(data);
          }
                     
     // }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}

//Close
fnCloseOrder(){
try{

  if(this.customerid>0){
    let navigationExtras: NavigationExtras = {
      queryParams: {
        'id': this.customerid,
      }
      }

    this.router.navigate(['/CustomerView'], navigationExtras);
  }
  else{
   
    this.router.navigate(['/Orders']);
    
  }
    

}catch(ex){
   this.fnShowErrorMsg(ex);
}
}

//Confirm Status
fnConfirmStatusBtnClick(){
  try{
    this.submitted=true;
    this.successfully=false;


    if(this.orderconfirmform.valid){

      // var _currentdt=new Date();
      // var _deliverydt=this.orderconfirmform.get('expectdeliverydate').value;

      // var regex = new RegExp(':', 'g');
      // var _currenttime=new Date().getHours().toString()+":"+new Date().getMinutes();
      // var _deliverytime=this.orderconfirmform.get('expectdeliverytime').value;


      // console.log(this.fnFormatDatetime(_deliverydt))
      // console.log(this.fnFormatDatetime(_currentdt.toDateString()))

     
      // if(Date.parse(_deliverydt)<Date.parse(_currentdt.toDateString())){

      //   this.orderconfirmform.controls['expectdeliverydate'].setErrors({'notfound': true});
      //   return
      // }
      // else if(this.fnFormatDatetime(_deliverydt)===this.fnFormatDatetime(_currentdt.toDateString()) && parseInt(_deliverytime.replace(regex, ''), 10)< parseInt(_currenttime.replace(regex, ''), 10)){
      //   this.orderconfirmform.controls['expectdeliverytime'].setErrors({'notfound': true});
      //   return
      // }


      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to Confirm Order?')
      .then((confirmed) => 
            {
              if(confirmed){                
                this.fnConfirmStatus();
              }                            
            });            
    }

  }catch(ex){
    this.fnShowErrorMsg(ex);
  }
}

fnConfirmStatus(){
  var data = {
    'orderid': this.orderid,       
    'status':'CONFIRMED',     
    'deliverytypeid': this.orderconfirmform.get('deliverytypeid').value,  
    'expectdeliverydate':this.fnFormatDatetime(this.orderconfirmform.get('expectdeliverydate').value),  
    'expectdeliverytime': this.orderconfirmform.get('expectdeliverytime').value,         
    'branchid':this.orderconfirmform.get('branchid').value,       
    'modifiedby': this.loginService.fnGetModUserId()         
  }

  if (this.orderform.get('orderid').value > 0) {
    this.fnShowProgress();

    //Update Department
    this.appService.fnSetOrderConfirm(data).subscribe(
      (res)=>{
        this.fnHideProgress();
      if(res.json().result!=""){
        var _result=res.json().result[0];
        var _checkresult="";
  
        if(_result.length){
          _checkresult=_result[0].checkresult;

          if(_checkresult==='DONE'){
            this.fnInsertActivityLog('CONFIRMED',this.ordermainBo.orderno,this.ordermainBo.mobile,0);


           
            //this.fnInsertActivityLogShipment('INSERT',res.json().result,this.ordermainBo.orderno,res.json().result);
            this.toastr.success('', "The order has been confirmed successfully.");
            this.fnCloseOrder();         
            

            //this.fnSaveShipment();
          }
          else{
            this.toastr.warning('', "Already confirmed this order.");
            this.fnCloseOrder();  
          }
          
        }
        
        
        }},
      (err) => {
        this.fnHideProgress();
        this.toastr.error('Error', err);
      });
    
  }
  
}



//Save Shipment
fnSaveShipment(){
  try{
    this.shipmentdetailscoll=[];

    for(let _abo of this.orderdetailsColl){
      if(!_abo.iscancelled){        
        _abo.deliveryqty=_abo.qty;

        this.shipmentdetailscoll.push(_abo)
      }      
    }
    
    //Check
    var data = {
              'deliveryorderid': 0,       
              'deliveryorderno':'',            
              'deliveryorderdate':this.fnFormatDatetime(new Date()), 
              'productcount':this.shipmentdetailscoll.length,
              'qtytotal':this.fnGetQtyCount(),
              'shipmenttypeid':null, 
              'trackno':'', 
              'branchid':this.orderconfirmform.get('branchid').value,              
              'orderid':this.orderid,
              'createdby': this.loginService.fnGetModUserId(),    
              'modifiedby':this.loginService.fnGetModUserId(),  
              'deliveryorderdetailscoll':this.shipmentdetailscoll
    }
      
    //Insert Shipment
    this.fnInsertShipment(data);
      
     
  }catch(ex){
    this.fnShowErrorMsg(ex);
  }
}

//Insert
fnInsertShipment(data){    
  this.fnShowProgress();
   this.appService.fnInsertDeliveryOrder(data).subscribe(
     (res)=>{
       this.fnHideProgress();
      if(res.json().message==="DONE"){
        this.fnInsertActivityLogShipment('INSERT',res.json().result,this.ordermainBo.orderno,res.json().result);
        this.toastr.success('', "The order has been confirmed successfully.");
        this.fnCloseOrder();         
      }
      else if(res.json().message==="FAILED"){
        this.toastr.warning('', "Already shipped this Order");
        this.fnCloseOrder();  
      }
    },
     (err)=>{
      this.fnHideProgress();
       this.toastr.error('Error', err);
     });

}



//Sales Invoice
fnSetSalesInvoice(){   
  var data = {
    'orderid':this.orderid,
    'modifiedby':this.loginService.fnGetModUserId(),  
  }
  this.fnShowProgress();
   this.appService.fnSetSalesInvoice(data).subscribe(
     (res)=>{
       this.fnHideProgress();
     if(res.json().result!=""){
      this.toastr.success('', "The order has been confirmed successfully.");
      this.fnCloseOrder();  
     }},
     (err)=>{
      this.fnHideProgress();
       this.toastr.error('Error', err);
     });

}


fnGetQtyCount(){
  let _aqty:Number=0;

  for(let _abo of this.shipmentdetailscoll){
    _aqty=_aqty + _abo.deliveryqty;
  }

  return _aqty;
}

// Order Option
fnOrderOption(){
  const modalRef = this.productFindService.open(OrderoptionComponent ,{ size: <any>'l' });

  modalRef.componentInstance.orderid=this.ordermainBo.orderid;  
  modalRef.componentInstance.orderno = this.ordermainBo.orderno;  
  modalRef.componentInstance.doctype = '';
  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

    if(receivedEntry==='REJECTORDER'){
      this.fnCancelOrder();
    }
    else if(receivedEntry==='REMOVEDELIVERYCHARGES'){
      this.fnRemoveDeliveryChargesOrder();
    }
  });
      
}

//Cancel Order
fnCancelOrder(){
  const modalRef = this.productFindService.open(CancelorderComponent ,{ size: <any>'l' });
  modalRef.componentInstance.doctype ="CANCELORDER" ;
  modalRef.componentInstance.docname ="Order Cancell" ;
  modalRef.componentInstance.orderid=this.ordermainBo.orderid;  
  modalRef.componentInstance.orderno=this.ordermainBo.orderno;  
  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
    var _checkresult=receivedEntry[0].checkresult;
    if(_checkresult==='DONE'){
      this.fnInsertActivityLog('CANCELORDER',this.ordermainBo.orderno,"",0);
      this.fnSendMailByOrder('CANCELORDER');
    }
    else{
      this.toastr.warning('', "Already confirmed this order.");
      window.location.reload()
    }
   
    
  
  });
      
}

//Remove Delivery Charges Order
fnRemoveDeliveryChargesOrder(){

  this.confirmationDialogService.confirm('Confirm..', 'Do you  want to Remove delivery Charges Order?')
  .then((confirmed) => 
        {
          if(confirmed){                
           
            var data = {
              'orderid':this.orderid,                    
              'isdelivchremoved':true,      
              'modifiedby':this.loginService.fnGetModUserId(),    
          
            }
            this.appService.fnSetOrderRemovedDeliveryCharges(data)
            .subscribe(
              (res)=>{                  
                this.fnInsertActivityLog('REMOVEDELIVERYCHARGESORDER',this.ordermainBo.orderno,"",0);
          
                window.location.reload() 
              },
            (err)=>{          
              //this.toastr.error('Error', err);
            })
          }                            
  });  



      
}

//Change Delivery Date Time Order 
fnChangeDeliveryDt(){

  
  const modalRef = this.productFindService.open(EditdeliverydateComponent ,{ size: <any>'l' });

  modalRef.componentInstance.orderid=this.ordermainBo.orderid;  
  modalRef.componentInstance.expectdeliverydate=this.fnConvertDatetime(this.fnFormatDatetime(this.ordermainBo.expectdeliverydate));
  modalRef.componentInstance.expectdeliverytime=this.ordermainBo.expectdeliverytime;  
  
  
  console.log(this.ordermainBo.expectdeliverydate +" " +this.ordermainBo.expectdeliverytime)

  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
    
    this.fnInsertActivityLog('CHANGEDELIVERYDT',this.ordermainBo.orderno,"",0);

    window.location.reload() 
    
  });
      
}

//Change Customer Avail Date Time Order 
fnChangeCustomerAvailDt(){

  
  const modalRef = this.productFindService.open(CustomeravaildateComponent ,{ size: <any>'l' });

  modalRef.componentInstance.orderid=this.ordermainBo.orderid;  
  modalRef.componentInstance.customeravaildate=this.fnConvertDatetime(this.fnFormatDatetime(this.ordermainBo.customeravaildate));
  modalRef.componentInstance.customeravailtime=this.ordermainBo.customeravailtime;  
  
  
  console.log(this.ordermainBo.customeravaildate +" " +this.ordermainBo.customeravailtime)

  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
    
    this.fnInsertActivityLog('CHANGECUSTOMERAVAILDT',this.ordermainBo.orderno,"",0);

    window.location.reload() 
    
  });
      
}


//Cancel Product
fnCancelProductOrder(index){
  const modalRef = this.productFindService.open(CancelorderComponent ,{ size: <any>'l' });
  modalRef.componentInstance.doctype ="CANCELPRODUCT" ;
  modalRef.componentInstance.docname ="Product Cancell" ;
  modalRef.componentInstance.orderno=this.ordermainBo.orderno;
  modalRef.componentInstance.productBO=this.orderdetailsColl[index];
  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

    var _checkresult=receivedEntry[0].checkresult;
    if(_checkresult==='DONE'){
      this.fnInsertActivityLog('CANCELPRODUCT',this.ordermainBo.orderno,this.orderdetailsColl[index].sku+"-"+this.orderdetailsColl[index].productname,0);     
      this.fnSendMailByOrder('CANCELLEDPRODUCT');
    }
    else{
      this.toastr.warning('', "Already confirmed this order.");
      window.location.reload()
    }
   

    
  });
      
}

//Show Cancel Reason
fnShowCancelReason(str){
  this.confirmationDialogService.confirm('ALERT', str);       
}

//Add Product
fnSelectProduct(){
 
  const modalRef = this.productFindService.open(ProductfindComponent ,{ size: <any>'xl' });
  modalRef.componentInstance.doctype ="SELECT PRODUCT" ;

  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

    for(let wbo of receivedEntry){

      var data = {
        'orderid':this.orderid,            
        'productid':wbo.productid,            
        'skuid':wbo.skuid,            
        'modifiedby':this.loginService.fnGetModUserId(),            
      }
      this.appService.fnSetOrderItemAdd(data)
      .subscribe(
        (res)=>{        
            this.fnInsertActivityLog('ADDPRODUCT',this.ordermainBo.orderno,wbo.productid+"-"+wbo.skuid,0);          
            window.location.reload();
        },
      (err)=>{          
        //this.toastr.error('Error', err);
      })
    }
  });

}

//Qty Change Product
fnQtyChangeProductOrder(index){
  const modalRef = this.productFindService.open(EditordersComponent ,{ size: <any>'l' },);
  modalRef.componentInstance.doctype ="" ;
  modalRef.componentInstance.orderno=this.ordermainBo.orderno;
  //modalRef.componentInstance.productBO=this.orderdetailsColl[index];
  modalRef.componentInstance.orderdetailsid=this.orderdetailsColl[index].orderdetailsid;
  modalRef.componentInstance.orderid=this.orderid;
  modalRef.componentInstance.qty=this.orderdetailsColl[index].qty;
  modalRef.componentInstance.productname=this.orderdetailsColl[index].productname;
  modalRef.componentInstance.paytype=  this.orderpayBo.paytype;
  modalRef.componentInstance.oldqty=this.orderdetailsColl[index].oldqty;


  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
    var _checkresult=receivedEntry[0].checkresult;
    if(_checkresult==='DONE'){
      this.fnInsertActivityLog('QTYCHANGEPRODUCT',this.ordermainBo.orderno,this.orderdetailsColl[index].sku+"-"+this.orderdetailsColl[index].productname,0);        
     
    }
    else{
      this.toastr.warning('', "Already confirmed this order.");
    }
    window.location.reload()      
   
  });
      
}



//Discount Change Product
fnDiscountChangeProductOrder(index){
  const modalRef = this.productFindService.open(DiscountorderComponent ,{ size: <any>'l' },);
  modalRef.componentInstance.doctype ="" ;
  modalRef.componentInstance.orderno=this.ordermainBo.orderno;
  //modalRef.componentInstance.productBO=this.orderdetailsColl[index];
  modalRef.componentInstance.orderdetailsid=this.orderdetailsColl[index].orderdetailsid;
  modalRef.componentInstance.orderid=this.orderid;
  modalRef.componentInstance.discamt=this.orderdetailsColl[index].discamt;
  modalRef.componentInstance.productname=this.orderdetailsColl[index].productname;
  modalRef.componentInstance.paytype=  this.orderpayBo.paytype;
  //modalRef.componentInstance.oldqty=this.orderdetailsColl[index].oldqty;


  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
    var _checkresult=receivedEntry[0].checkresult;
    if(_checkresult==='DONE'){
      this.fnInsertActivityLog('DISCOUNTCHANGEPRODUCT',this.ordermainBo.orderno,this.orderdetailsColl[index].sku+"-"+this.orderdetailsColl[index].productname,0);        
     
    }
    else{
      this.toastr.warning('', "Already confirmed this order.");
    }
    window.location.reload()      
   
  });
      
}


fnAddDeliveryOrder(){
  try{

    let navigationExtras: NavigationExtras = {
      queryParams: {
        'oid': this.orderid,
        'ltype':'order'
      }
      }

    this.router.navigate(['/AddShipment'], navigationExtras);
    
  }catch(ex){
     this.fnShowErrorMsg(ex);
  }
}

fnEditDeliveryOrder(aBO){
  try{

    let navigationExtras: NavigationExtras = {
      queryParams: {
        'oid': this.orderid,
        'id': aBO.deliveryorderid,
        'ltype':'order'
      }
      }

    this.router.navigate(['/AddShipment'], navigationExtras);
    
  }catch(ex){
     this.fnShowErrorMsg(ex);
  }
}

//Zoom Product
fnShowZoomProduct(skuid){
  const modalRef = this.productFindService.open(ZoomproductComponent ,{ size: <any>'l' },);
  modalRef.componentInstance.doctype ="" ;
  modalRef.componentInstance.skuid=skuid;
  modalRef.componentInstance.fnGetProductInfo();
  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
 
   
  });
      
}

//Send Mail
fnSendMailByOrder(mailtype){
  
  this.fnShowProgress();
  var data = {
    orderid:this.orderid,
    mailtype:mailtype,      
  }
  this.appService.fnSendMailByOrder(data)
      .subscribe(
        (res)=>{           
         this.fnHideProgress();
          if(res.json().status==200){                          
            this.fnSendNotificationByOrder(mailtype)
          }
          else if(res.json().status==400){     
            this.fnHideProgress();                        
            this.toastr.error('Error', res.json().err_field);
          }                          
      },
        (err)=>{          
          this.fnHideProgress();       
          this.toastr.error('Error', err);
  })
}


fnSendNotificationByOrder(type){
  
  this.fnShowProgress();
  var data = {
    orderid:this.orderid,
    actiontype:type,      
  }
  this.appService.fnSendNotificationByOrder(data)
      .subscribe(
        (res)=>{           
         this.fnHideProgress();
          if(res.json().status==200){                          
            window.location.reload()
          }
          else if(res.json().status==400){     
            this.fnHideProgress();                        
            this.toastr.error('Error', res.json().err_field);
          }                          
      },
        (err)=>{          
          this.fnHideProgress();       
          this.toastr.error('Error', err);
  })
}


private fnEditMode(deptBO){
 this.orderform.setValue({
  orderno:deptBO.orderno,     
  orderid:deptBO.orderid,    
  orderdate:deptBO.orderdate,
  customername:deptBO.customername,     
  mobileno:deptBO.mobileno,
  addressnickname:deptBO.addressnickname,
  houseno:deptBO.houseno,
  street:deptBO.street,
  areano:deptBO.areano,
  cityname:deptBO.cityname,
  landmark:deptBO.landmark,
  statusid:deptBO.statusid,
  status:deptBO.status,
 });

 this.ordermainBo.expectdeliverydate= this.fnConvertDatetime(this.fnFormatDatetime(this.ordermainBo.expectdeliverydate));

 this.orderconfirmform.setValue({
  deliverytypeid:this.ordermainBo.deliverytypeid,
   expectdeliverydate:this.ordermainBo.expectdeliverydate,
  expectdeliverytime:this.ordermainBo.expectdeliverytime,
  branchid:null,
 });


// console.log(this.ordermainBo.expectdeliverydt)


this.isaddmode=false;
this.iseditmode=true;
this.isdeletemode=false;
this.isviewmode=false;
}

public fnDeleteConfirm(deptid) {
  try{
    this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Delivery Order?')
    .then((confirmed) => 
          {
            if(confirmed){
              this.fnDeleteDeliveryOrder(deptid)
            }          
        }        
    );     
    //.catch(() => );
  }catch(ex){
    this.fnShowErrorMsg(ex);
  }
}

private fnDeleteDeliveryOrder(deptid){
     var data = {
       'deliveryorderid': deptid,            
     }
    this.appService.fnDeleteDeliveryOrder(data)
      .subscribe(
        (res)=>{ 
          if(res.json().status===200){
            this.toastr.success('', " The Delivery Order has been deleted successfully.");                 
            window.location.reload();
          }else{
            this.toastr.error('',res.json().err_field);   
        }
        },
        (err)=>{          
          this.toastr.error('Error', err);
        })
}

fnGeoLocationDeliveryAddress(latitude,longitude){
  
  
  const modalRef = this.productFindService.open(OrderaddressmapComponent ,{ size: <any>'xl'  });
  modalRef.componentInstance.doctype ="COMBO PRODUCT" ;
  modalRef.componentInstance.latitude =latitude ;
  modalRef.componentInstance.longitude =longitude ;
  modalRef.componentInstance.arealatitude =this.orderaddressBo.arealatitude ;
  modalRef.componentInstance.arealongitude =this.orderaddressBo.arealongitude ;

  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

    
  });


}

fnEditOrderAddress(aBO){
  try{

    let navigationExtras: NavigationExtras = {
      queryParams: {
        'oid': this.orderid,
        'id': aBO.orderaddressid,
      }
      }

    this.router.navigate(['/AddOrderAddress'], navigationExtras);
    
  }catch(ex){
     this.fnShowErrorMsg(ex);
  }
}




downloadPdf() {
  const doc = new jsPDF();
  const specialElementHandlers = {
    '#editor': function (element, renderer) {
      return true;
    }
  };

  const content = this.reportContent.nativeElement;

  doc.fromHTML(content.innerHTML, 15, 15, {
    'width': 190,
    'elementHandlers': specialElementHandlers
  });

  doc.save('asdfghj' + '.pdf');
}

//PRINT

fnPrintOrderItem(){

  const modalRef = this.productFindService.open(PrintorderComponent ,{ size: <any>'xl' });
  modalRef.componentInstance.doctype ="PRINT PICKLIST" ;
  modalRef.componentInstance.orderid=this.orderid;  
  modalRef.componentInstance.fnPrintPickList();

  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
   // window.location.reload()      
  });
  


  // var data = {
  //   'orderid':this.orderid,            
  // }
  // this.appService.fnGetInvoiceOrder(data)
  // .subscribe(
  //   (res)=>{                  
     
     
  // },
  // (err)=>{          
  //   this.toastr.error('Error', err);
  // })
}

fnPrintOrder(){

  const modalRef = this.productFindService.open(PrintorderComponent ,{ size: <any>'xl' });
  modalRef.componentInstance.doctype ="PRINT ORDER" ;
  modalRef.componentInstance.orderid=this.orderid;
  modalRef.componentInstance.fnPrintOrder();

  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
   // window.location.reload()      
  });
  


  // var data = {
  //   'orderid':this.orderid,            
  // }
  // this.appService.fnGetInvoiceOrder(data)
  // .subscribe(
  //   (res)=>{                  
     
     
  // },
  // (err)=>{          
  //   this.toastr.error('Error', err);
  // })
}

fnPrintInvoice(){

    const modalRef = this.productFindService.open(PrintorderComponent ,{ size: <any>'xl' });
    modalRef.componentInstance.doctype ="PRINT INVOICE" ;
    modalRef.componentInstance.orderid=this.orderid;
    modalRef.componentInstance.fnPrintOrderInvoice();
  
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
        
    });
    
  
  
}

fnPrintDOSlip(){

    const modalRef = this.productFindService.open(PrintorderComponent ,{ size: <any>'xl' });
    modalRef.componentInstance.doctype ="PRINT DOSLIP" ;
    modalRef.componentInstance.deliveryorderid=this.deliveryorderBO.deliveryorderid;
    modalRef.componentInstance.fnPrintDeliveryOrderSlip();
  
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
        
    });
    
  
  
}


fnViewDeliveryOrder(deptid){
  try{
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'oid': this.orderid,
          'id': deptid,
          'ltype':'list'
        }
      }

    this.router.navigate(['/AddShipment'], navigationExtras);
  }catch(ex){
    this.fnShowErrorMsg(ex);
  }
}

getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
  var dLon = this.deg2rad(lon2-lon1); 
  var a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ; 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c; // Distance in km
  return d;
}

deg2rad(deg) {
  return deg * (Math.PI/180)
}


fnFormatDatetime(dt){
  if(dt){
    if(dt===""){
      return null
    }
    else{
      return this.datePipe.transform(dt,"yyyy-MM-dd")  
    }
    
    }else {
    return null
  }
  
}

fnConvertDatetime(dt){
  if(dt){
    if(dt===""){
      return null
    }
    else{
      

      var dateParts = dt.split("-"); 

       var sdate =new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]+1); 

       sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());

       
       
       return sdate;
    }
    
    }else {
    return null
  }
 
}


fnInsertActivityLog(action,refno,desc,doid){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
      orderid:this.orderid,
      deliveryorderid:doid
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}


fnInsertActivityLogShipment(action,refno,desc,doid){
  try{
    var data={
      logtype:action,
      logat:"SHIPMENT",
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
      orderid:this.orderid,
      deliveryorderid:doid
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}



private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


private  fnShowErrorMsg(ex){
this.toastr.warning('Internal Error', ex);       
}


}
