import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: string) {
    var datePipe = new DatePipe("en-IN");
    if(value){
     //value = datePipe.transform(value, 'dd-MM-yyyy');
     value = datePipe.transform(value, 'dd-MM-yyyy', '+0400');
    }
     return value;
 }
}
