import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-adddeliveryorder',
  templateUrl: './adddeliveryorder.component.html',
  styleUrls: ['./adddeliveryorder.component.css']
})
export class AdddeliveryorderComponent implements OnInit {
//Mode Values
isaddmode:boolean=false;
iseditmode:boolean=false;
isdeletemode:boolean=false;
isviewmode:boolean=false;

//Save Validation
submitted:boolean;
successfully:boolean;


//Edit Get Values
orderColl:any=[];
orderdetailsColl:any=[];


deliveryorderColl:any=[];
deliveryorderdetailscoll:any=[];
deliveryorderid:number=0

deliveryorderform=new FormGroup({
  deliveryorderno:new FormControl('',Validators.required),         
  deliveryorderdate:new FormControl(''),           
  deliveryorderid:new FormControl(0),       
});

currentorderBO:any={};

_routerSub = Subscription.EMPTY;
_routerparamsSub = Subscription.EMPTY;
_routerLoginSub = Subscription.EMPTY

constructor(private appService: DhukanDataService,private route: ActivatedRoute,
   public router: Router,private toastr: ToastrService,private datePipe: DatePipe,private loginService:LoginDataService) { 
    this._routerSub = this.route.queryParams.subscribe(params => {
      if(params.id!==undefined){
        this.deliveryorderid = params.id
      }  

     });
}

ngOnInit() {
   try{
       this.isaddmode=true;
       this.deliveryorderform.reset();
       this.fnInitializeStatusFormGroup(); 

       if(this.deliveryorderid>0){
         this.fngetDeliveryOrder();
       }
       this.fngetOrderListView();
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}
ngOnDestroy(): void {
  if (this._routerSub !== undefined) {
    this._routerSub.unsubscribe();
  }
  if (this._routerparamsSub !== undefined) {
    this._routerparamsSub.unsubscribe();
  }

  if (this._routerLoginSub !== undefined) {
    this._routerLoginSub.unsubscribe();
  }
}
fnInitializeStatusFormGroup(){
   this.deliveryorderform.setValue({
      deliveryorderno:'NEW',
      deliveryorderdate:'',
      deliveryorderid:0,           
   })
}


fngetOrderListView() {   
  var data = {
        'statusid': 0,            
  }
  this.appService.getOrderListView(data)
      .subscribe(
        (res)=>{                  
          if(res.json().status==200){                          
            this.orderColl=res.json().result;           
            console.log(this.orderColl)
          }
          else if(res.json().status==400){                          
            this.toastr.error('Error', res.json().err_field);
          }                          
      },
        (err)=>{          
          this.toastr.error('Error', err);
        })
}


fngetOrderDetails(pBO) {   
  this.currentorderBO=pBO
    var data = {
      'orderid': pBO.orderid,            
    }
    this.appService.getOrderbyDeliverOrder(data)
    .subscribe(
      (res)=>{        
        console.log(res.json())               
        this.orderdetailsColl=res.json().result[1];
        for(let _abo of this.deliveryorderdetailscoll){
          const index = this.orderdetailsColl.findIndex(sku => sku.orderdetailsid ===  _abo.orderdetailsid  );  
          if(index>-1){
            this.orderdetailsColl[index].deliveryqty=_abo.deliveryqty;          
            this.orderdetailsColl[index].isentry=true;
          }                    
        }

        for(let _abo of this.orderdetailsColl){
          if(!_abo.deliveryqty){
            _abo.deliveryqty=0;
          }
          _abo.availableqty=_abo.qty-_abo.prevqty;       
          if(_abo.isentry){
            _abo.availableqty=_abo.availableqty+_abo.deliveryqty;       
          }
          
        }


      },
      (err)=>{          
        this.toastr.error('Error', err);
      }) 
}


//Get 
fngetDeliveryOrder() {        
     var data = {
       'deliveryorderid':this.deliveryorderid,            
     }
     this.appService.getDeliveryOrderbyOrderid(data)
     .subscribe(
       (res)=>{                  
         this.deliveryorderColl=res.json().result[0];
         this.deliveryorderdetailscoll=res.json().result[1];

         var editdata = {
           'deliveryorderid':this.deliveryorderColl[0].deliveryorderid,
           'deliveryorderno':this.deliveryorderColl[0].deliveryorderno,            
           'deliveryorderdate':this.deliveryorderColl[0].deliveryorderdate,            
         }
         this.fnEditMode(editdata);
     },
     (err)=>{          
       this.toastr.error('Error', err);
     })
}

//Save Btn Click
fnSaveBtnClick(){
   try{
     this.submitted=true;
     this.successfully=false;
     //Validate
     if(this.deliveryorderform.valid){
         //Check
         var data = {
               'deliveryorderid': this.deliveryorderid,       
               'deliveryorderno':this.deliveryorderform.get('deliveryorderno').value,            
               'deliveryorderdate':this.fnFormatDatetime(this.deliveryorderform.get('deliveryorderdate').value), 
               'productcount':this.deliveryorderdetailscoll.length,
               'qtytotal':this.fnGetQtyCount(),
               'createdby': this.loginService.fnGetModUserId(),    
               'modifiedby':this.loginService.fnGetModUserId(),  
               'deliveryorderdetailscoll':this.deliveryorderdetailscoll
         }
         if (this.deliveryorderform.get('deliveryorderid').value>0){
          //Update Department
          this.fnSetDeliveryOrder(data);
        }
        else{
          //Insert Department
          this.fnInsertDeliveryOrder(data);
        }
      }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}

//Insert
fnInsertDeliveryOrder(data){    
 
   this.appService.fnInsertDeliveryOrder(data).subscribe(
     (res)=>{
     if(res.json().result!=""){
       this.toastr.success('', "The new deliveryorder has been added successfully.Insert Successfully");
       this.fnCloseDeliveryOrder();
     }},
     (err)=>{
       this.toastr.error('Error', err);
     });

}

//Set
fnSetDeliveryOrder(data){    

this.appService.fnSetDeliveryOrder(data).subscribe(
 (res)=>{
 if(res.json().result!=""){
     this.toastr.success('', "The deliveryorder has been updated successfully.");
     this.fnCloseDeliveryOrder();        
 }},
 (err)=>{
   this.toastr.error('Error', err);
 });

}

//Close
fnCloseDeliveryOrder(){
try{
  this.router.navigate(['/DeliveryOrders']);
}catch(ex){
   this.fnShowErrorMsg(ex);
}
}

private fnEditMode(deptBO){
 this.deliveryorderform.setValue({
  deliveryorderno:deptBO.deliveryorderno,
  deliveryorderdate:deptBO.deliveryorderdate,
  deliveryorderid:deptBO.deliveryorderid,     
 });

this.isaddmode=false;
this.iseditmode=true;
this.isdeletemode=false;
this.isviewmode=false;
}



fnAddDeliveryOrderItems(){

  for(let _abo of this.orderdetailsColl){
      if(_abo.deliveryqty>0){
        _abo.orderno=this.currentorderBO.orderno;
        
        _abo.orderqty=_abo.qty;

        const index = this.deliveryorderdetailscoll.findIndex(sku => sku.orderdetailsid ===  _abo.orderdetailsid  );  
        if(index>-1){
          this.deliveryorderdetailscoll[index].orderqty=_abo.qty;
          this.deliveryorderdetailscoll[index].deliveryqty=_abo.deliveryqty;
        }
        else{
          this.deliveryorderdetailscoll.push(_abo);
        }
      }
  }

}

fnGetQtyCount(){
  let _aqty:Number=0;

  for(let _abo of this.deliveryorderdetailscoll){
    _aqty=_aqty + _abo.deliveryqty;
  }

  return _aqty;
}


fnFormatDatetime(dt){
  if(dt){
    if(dt===""){
      return null
    }
    else{
      return this.datePipe.transform(dt,"yyyy-MM-dd")  
    }
    
    }else {
    return null
  }
  
}

fnConvertDatetime(dt){
  if(dt){
    if(dt===""){
      return null
    }
    else{
      

      var dateParts = dt.split("-"); 

       var sdate =new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]+1); 

       sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());

       
       
       return sdate;
    }
    
    }else {
    return null
  }
 
}





private  fnShowErrorMsg(ex){
this.toastr.warning('Internal Error', ex);       
}




}
