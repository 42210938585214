import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductfindComponent } from '../../productfind/productfind.component';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-addproductsection',
  templateUrl: './addproductsection.component.html',
  styleUrls: ['./addproductsection.component.css']
})
export class AddproductsectionComponent implements OnInit {

//Mode Values
isaddmode:boolean=false;
iseditmode:boolean=false;
isdeletemode:boolean=false;
isviewmode:boolean=false;

//Save Validation
submitted:boolean;
successfully:boolean;


//Edit Get Values
productsectionColl:any=[];
productsectionid:number=0
sectiontypeid=null;

sectiontypeColl:any=[];

productsectionform=new FormGroup({
  sectiontypeid:new FormControl(null,Validators.required),  
  productname:new FormControl('',Validators.required),         
  productsectionid:new FormControl(0),      
  productid:new FormControl(0),    
  skuid:new FormControl(0),    
  sequence:new FormControl(null),    
 inactive:new FormControl(false),     
});

isloading:boolean=false;
isprivilage:boolean=false;  
showProgress:boolean=false;
formname:String="Area";

_routerSub = Subscription.EMPTY;
_routerparamsSub = Subscription.EMPTY;
_routerLoginSub = Subscription.EMPTY

constructor(private appService: DhukanDataService,private route: ActivatedRoute,
   public router: Router,private toastr: ToastrService, private productFindService: NgbModal,private loginService:LoginDataService) { 
    this._routerSub = this.route.queryParams.subscribe(params => {
     
       if(params.id!==undefined){
        this.productsectionid = params.id
      }   

       this.sectiontypeid = params.sectiontypeid       
     });
     this.fnServiceChanges();
}


fnServiceChanges(){
  this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}

ngOnInit() {
    try{
      if(this.loginService.fnCheckUser()){ 
        if(this.fnCheckUserPririvilage()){       
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
              
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}
ngOnDestroy(): void {
  if (this._routerSub !== undefined) {
    this._routerSub.unsubscribe();
  }
  if (this._routerparamsSub !== undefined) {
    this._routerparamsSub.unsubscribe();
  }

  if (this._routerLoginSub !== undefined) {
    this._routerLoginSub.unsubscribe();
  }
}
fnOnInitForm(){
   try{
       this.isaddmode=true;
       this.productsectionform.reset();
       this.fnInitializeProductSectionFormGroup();        
       this.fnGetSectionname();

       if(this.productsectionid>0){
        this.fngetProductSection();
      }

   }catch(ex){
     console.log(ex);
     this.fnShowErrorMsg(ex);
   }
}



fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.productsectionid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}

fnInitializeProductSectionFormGroup(){
    let id=this.sectiontypeid;

   this.productsectionform.setValue({
     sectiontypeid:Number(id),
      productsectionid:0,      
      productid:0,
      skuid:0,
      sequence:null,
      productname:'',
     inactive:false,      
   })
}




//Get 
fngetProductSection() {        
  var data = {
    'productsectionid':this.productsectionid,            
  }
  this.appService.getProductSection(data)
  .subscribe(
    (res)=>{                  
      this.productsectionColl=res.json().result;
      var editdata = this.productsectionColl[0];
      this.fnEditMode(editdata);
  },
  (err)=>{          
    this.toastr.error('Error', err);
  })
}



fnGetSectionname(){
 
  var data = {
    'sectiontypeid':0,            
  }
  this.appService.getSectionType(data)
  .subscribe(
    (res)=>{                  
      this.sectiontypeColl=res.json().result; 
  },
  (err)=>{          
    this.toastr.error('Error', err);
  })

}

//Save Btn Click
fnSaveBtnClick(){
   try{
     this.submitted=true;
     this.successfully=false;
     //Validate
     if(this.productsectionform.valid){
         //Check
         var data = {
               'productsectionid': this.productsectionid,       
               'sectiontypeid':this.productsectionform.get('sectiontypeid').value,            
               'productid':this.productsectionform.get('productid').value,            
               'skuid':this.productsectionform.get('skuid').value,            
               'sequence':this.productsectionform.get('sequence').value,                  
               'productname':this.productsectionform.get('productname').value,                     
               'inactive':this.productsectionform.get('inactive').value,  
               'createdby': this.loginService.fnGetModUserId(),    
               'modifiedby':this.loginService.fnGetModUserId(), 
         }
         if (this.productsectionform.get('productsectionid').value>0){
           this.fnSetProductSection(data);      
         }
         else{
            this.fnInsertProductSection(data);      
         }
         
      }
   }catch(ex){
     console.log(ex);
     this.fnShowErrorMsg(ex);
   }
}

//Insert
fnInsertProductSection(data){    
 
   this.appService.fnInsertProductSection(data).subscribe(
     (res)=>{
     if(res.json().result!=""){
       this.toastr.success('', "The new ProductSection has been added successfully.Insert Successfully");

       this.fnInsertActivityLog('INSERT',0,data.productname);

       this.fnCloseProductSection();
     }},
     (err)=>{
       this.toastr.error('Error', err);
     });

}

//Set
fnSetProductSection(data){    
 
  this.appService.fnSetProductSection(data).subscribe(
    (res)=>{
    if(res.json().result!=""){
      this.toastr.success('', "The  ProductSection has been update successfully.");

      this.fnInsertActivityLog('EDIT',this.productsectionid,data.productname);

      this.fnCloseProductSection();
    }},
    (err)=>{
      this.toastr.error('Error', err);
    });

}


fnSelectProduct(){
 
  const modalRef = this.productFindService.open(ProductfindComponent ,{ size: <any>'xl' });
  modalRef.componentInstance.doctype ="SELECT PRODUCT" ;

  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

    for(let wbo of receivedEntry){

      this.productsectionform.setValue({
        sectiontypeid:this.productsectionform.get('sectiontypeid').value,
        productsectionid:this.productsectionform.get('productsectionid').value,
        sequence:this.productsectionform.get('sequence').value,
        inactive:this.productsectionform.get('inactive').value,   
        productid:wbo.productid,
        skuid:wbo.skuid,
        productname:wbo.productname,
      });

    }
  });

}

//Close
fnCloseProductSection(){
try{
  let navigationExtras: NavigationExtras = {
    queryParams: {    
      'sectiontypeid':this.sectiontypeid        
    }
  }

  this.router.navigate(['/ProductSection'], navigationExtras);
}catch(ex){
   this.fnShowErrorMsg(ex);
}
}



private fnEditMode(deptBO){

  this.productsectionform.setValue({    
       sectiontypeid:deptBO.sectiontypeid,      
       productname:deptBO.productname,
       productsectionid:deptBO.productsectionid,   
       productid:deptBO.productid,   
       skuid:deptBO.skuid,   
       sequence:deptBO.sequence,   
       inactive:deptBO.inactive,   
  });
 
 
 this.isaddmode=false;
 this.iseditmode=true;
 this.isdeletemode=false;
 this.isviewmode=false;
 }
 

   
 fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}

 

private  fnShowErrorMsg(ex){
this.toastr.warning('Internal Error', ex);       
}



}
