import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { Router, NavigationExtras } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
declare var $ :any;


@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.css']
})
export class StatusComponent implements OnInit ,AfterViewInit, OnDestroy {
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: {};

  step1class="";
  step2class="";
  step3class="";
  step4class="";
   
  statusColl:any=[];

  isadd:boolean=false;
  isedit:boolean=false;
  isdelete:boolean=false;
  isview:boolean=false;
  isprint:boolean=false;
   isexport:boolean=false; 
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
showProgress:boolean=false;
  formname:String="Status";

  _routerSub = Subscription.EMPTY;
  _routerLoginSub = Subscription.EMPTY
  
  constructor( private appService: DhukanDataService,public router: Router,
    private confirmationDialogService: ConfirmationDialogService,private toastr: ToastrService ,private loginService:LoginDataService) {  this.fnServiceChanges();}

  //User Check
  fnServiceChanges(){
    this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
          if(this.loginService.fnCheckUser()){                
              this.fnCheckPrivilage();
              if(this.isview){
                this.isloading=true;
                this.fnOnInitForm();
              }
              else{
                this.fnUserFailedForm();
              }    
          }
          else{
            this.fnUserFailedForm();
          }
    });
  }

  ngOnInit() {
    try{      
      this.dtOptions = {
        searching: true,       
        pageLength: 25,
        serverSide: false,
        processing: true,
        dom:'<"top"f>rt<"bottom"ilp><"clear">',   
        columns:[  
          {title:'Action'},     
          {title:'Status'},                           
        ],  
        order:[1, 'desc'],
        scrollX: true,                 
        scrollCollapse: true, 
        buttons: [        
          {
            title: this.formname +" List",
            extend: "print",            
            exportOptions: {                
                columns: [  1, 2,  ]
            }
          },
          {
            title: this.formname +" List",
            extend: "excel",            
            exportOptions: {
                columns: [  1, 2,  ]
            }
          },        
        ] ,                                   
      };

      if(this.loginService.fnCheckUser()){         
        this.fnCheckPrivilage();
        if(this.isview){
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }          
      }
      else{
        this.loginService.fnGetUserRoleList();
      }

    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

       
  fnOnInitForm(){
    this.fngetStatus();     
  }

  fnCheckPrivilage(){
    this.isadd=this.loginService.fnCheckUserRoleAction(this.formname,'CREATE');
    this.isedit=this.loginService.fnCheckUserRoleAction(this.formname,'ALTER');
    this.isdelete=this.loginService.fnCheckUserRoleAction(this.formname,'DELETE');
    this.isview=this.loginService.fnCheckUserRoleAction(this.formname,'VIEW');
    this.isprint=this.loginService.fnCheckUserRoleAction(this.formname,'PRINT');
    this.isexport=this.loginService.fnCheckUserRoleAction(this.formname,'EXPORT');
  }

  fnUserFailedForm(){
    this.isprivilage=true;
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    if (this._routerSub !== undefined) {
      this._routerSub.unsubscribe();
    }
  
    if (this._routerLoginSub !== undefined) {
      this._routerLoginSub.unsubscribe();
    }
  }
 
  fngetStatus() { 
           
    if(this.showProgress){
      return;
    }
    
    this.fnShowProgress();  
      var data = {
            'statusid': 0,            
      }
      this.appService.getStatus(data)
          .subscribe(
            (res)=>{      
              this.fnHideProgress();            
              if(res.json().status==200){                          
                this.statusColl=res.json().result;
                this.fnSetDataTable();
              }
              else if(res.json().status==400){                          
                this.toastr.error('Error', res.json().err_field);
              }
             
             
          },
            (err)=>{          
              this.fnHideProgress();
              this.toastr.error('Error', err);
            })
  }

  fnAddStatus(){
    try{
      this.router.navigate(['/AddStatus']);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnEditStatus(deptid){
    try{
        let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': deptid,
        }
        }
  
      this.router.navigate(['/AddStatus'], navigationExtras);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  public fnDeleteConfirm(deptid) {
    try{
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Status?')
      .then((confirmed) => 
            {
              if(confirmed){
                this.fnDeleteStatus(deptid)
              }          
          }        
      );     
      //.catch(() => );
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteStatus(deptid){
       var data = {
         'statusid': deptid,            
       }
      this.appService.fnDeleteStatus(data)
        .subscribe(
          (res)=>{ 
            if(res.json().status===200){
              this.toastr.success('', " The status has been deleted successfully.");                 

              this.fnInsertActivityLog('DELETE',deptid,this.fnGetDescription(deptid));
              
              this.fngetStatus();
            }else{
              this.toastr.error('',res.json().err_field);   
          }
          },
          (err)=>{          
            this.toastr.error('Error', err);
          })
  }

      
  private fnSetDataTable(){
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {      
      dtInstance.destroy();      
      this.dtTrigger.next();
    });

    this.fnSetStatus();
  }

  
      
  fnPrintData(){
    try{
    
      this.dtElement.dtInstance.then((dtInstance:any) => {      
        dtInstance.buttons(0,0).trigger();
      });                       
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnExportData(){
    try{
    
      this.dtElement.dtInstance.then((dtInstance:any) => {      
        dtInstance.buttons(0,1).trigger();
      });                       
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }
  
  
fnGetDescription(id){
  const index = this.statusColl.findIndex(sku => sku.statusid === id  ); 

  if(index){
    return this.statusColl[index].status;
  }
  else{
    return "";
  }

}

fnInsertActivityLog(action,refno,desc){
try{
  var data={
    logtype:action,
    logat:this.formname,
    refnum:refno,
    logdescr:desc,
    logby:this.loginService.fnGetModUserId(),
  }


  this.appService.fnInsertActivity(data).subscribe(
    (res)=>{
    },
    (err)=>{
   
    });

}catch{

}
}

  

  
private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


  private  fnShowErrorMsg(ex){
    this.toastr.warning('Internal Error', ex);       
  }

  private fnSetStatus(){

    this.step1class="";
    this.step2class="";
    this.step3class="";
    this.step4class="";

    this.step1class= "is-complete";
    this.step2class="is-complete";
    this.step3class="is-complete";
    this.step4class="is-active";

  }


}
