import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-addwarehouse',
  templateUrl: './addwarehouse.component.html',
  styleUrls: ['./addwarehouse.component.css']
})
export class AddwarehouseComponent implements OnInit {
//Mode Values
isaddmode:boolean=false;
iseditmode:boolean=false;
isdeletemode:boolean=false;
isviewmode:boolean=false;

//Save Validation
submitted:boolean;
successfully:boolean;


//Edit Get Values
warehouseColl:any=[];
warehouseid:number=0

warehouseform=new FormGroup({
  warehousename:new FormControl('',Validators.required),         
  warehouseid:new FormControl(0),      
 inactive:new FormControl(false),     
});

isloading:boolean=false;
isprivilage:boolean=false;  
showProgress:boolean=false;
formname:String="Warehouse";

_routerSub = Subscription.EMPTY;
_routerparamsSub = Subscription.EMPTY;
_routerLoginSub = Subscription.EMPTY

constructor(private appService: DhukanDataService,private route: ActivatedRoute,
   public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
    this._routerSub = this.route.queryParams.subscribe(params => {
        if(params.id!==undefined){
          this.warehouseid = params.id
        }  
     });

     this.fnServiceChanges();
}



fnServiceChanges(){
  this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}

ngOnInit() {
    try{
      if(this.loginService.fnCheckUser()){ 
        if(this.fnCheckUserPririvilage()){       
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
              
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}
ngOnDestroy(): void {
  if (this._routerSub !== undefined) {
    this._routerSub.unsubscribe();
  }
  if (this._routerparamsSub !== undefined) {
    this._routerparamsSub.unsubscribe();
  }

  if (this._routerLoginSub !== undefined) {
    this._routerLoginSub.unsubscribe();
  }
}
fnOnInitForm(){
   try{
       this.isaddmode=true;
       this.warehouseform.reset();
       this.fnInitializeWarehouseFormGroup(); 

       if(this.warehouseid>0){
         this.fngetWarehouse();
       }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}


fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.warehouseid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}     

fnInitializeWarehouseFormGroup(){
   this.warehouseform.setValue({
      warehousename:'',
      warehouseid:0,      
     inactive:false,      
   })
}

//Get 
fngetWarehouse() {        
     var data = {
       'warehouseid':this.warehouseid,            
     }
     this.appService.getWarehouse(data)
     .subscribe(
       (res)=>{                  
         this.warehouseColl=res.json().result;
         var editdata = {
           'warehouseid':this.warehouseColl[0].warehouseid,
           'warehousename':this.warehouseColl[0].warehousename, 
           'inactive':this.warehouseColl[0].inactive,          
         }
         this.fnEditMode(editdata);
     },
     (err)=>{          
       this.toastr.error('Error', err);
     })
}

//Save Btn Click
fnSaveBtnClick(){
   try{
     this.submitted=true;
     this.successfully=false;
     //Validate
     if(this.warehouseform.valid){
         //Check
         var data = {
               'warehouseid': this.warehouseid,       
               'warehousename':this.warehouseform.get('warehousename').value,                         
               'inactive': 0,  
               'createdby': this.loginService.fnGetModUserId(),    
               'modifiedby':this.loginService.fnGetModUserId(), 
         }
         this.appService.fnCheckWarehouse(data).subscribe(
           (res)=>{
               var _checkresult=res.json().result[0].checkfield;
             
               if(_checkresult==="done"){
                     if (this.warehouseform.get('warehouseid').value>0){
                       //Update Department
                       this.fnSetWarehouse(data);
                     }
                     else{
                       //Insert Department
                       this.fnInsertWarehouse(data);
                     }
               }
               else{        
                     if(_checkresult==="name"){
                       this.warehouseform.controls['warehousename'].setErrors({'notfound': true});
                     }
               }
         },
         (err)=>{
             this.toastr.error('Error', err);           
         });
      }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}

//Insert
fnInsertWarehouse(data){    
 
   this.appService.fnInsertWarehouse(data).subscribe(
     (res)=>{
     if(res.json().result!=""){
       this.toastr.success('', "The new warehouse has been added successfully.Insert Successfully");

       this.fnInsertActivityLog('INSERT',0,data.warehousename);

       this.fnCloseWarehouse();
     }},
     (err)=>{
       this.toastr.error('Error', err);
     });

}

//Set
fnSetWarehouse(data){    

this.appService.fnSetWarehouse(data).subscribe(
 (res)=>{
 if(res.json().result!=""){
     this.toastr.success('', "The warehouse has been updated successfully.");

     this.fnInsertActivityLog('EDIT',this.warehouseid,data.warehousename);

     this.fnCloseWarehouse();        
 }},
 (err)=>{
   this.toastr.error('Error', err);
 });

}

//Close
fnCloseWarehouse(){
try{
  this.router.navigate(['/Warehouse']);
}catch(ex){
   this.fnShowErrorMsg(ex);
}
}

private fnEditMode(deptBO){
 this.warehouseform.setValue({
    warehousename:deptBO.warehousename,
    warehouseid:deptBO.warehouseid,
   inactive:deptBO.inactive,   
 });

this.isaddmode=false;
this.iseditmode=true;
this.isdeletemode=false;
this.isviewmode=false;
}

 
fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}


private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


private  fnShowErrorMsg(ex){
this.toastr.warning('Internal Error', ex);       
}



}
