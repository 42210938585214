import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-adddeliverycharges',
  templateUrl: './adddeliverycharges.component.html',
  styleUrls: ['./adddeliverycharges.component.css']
})
export class AdddeliverychargesComponent implements OnInit {

//Mode Values
isaddmode:boolean=false;
iseditmode:boolean=false;
isdeletemode:boolean=false;
isviewmode:boolean=false;

//Save Validation
submitted:boolean;
successfully:boolean;


//Edit Get Values
taxgroupColl:any=[];
deliverytypeColl:any=[];
warehouseColl:any=[];
deliverychargesColl:any=[];
deliverychargesid:number=0

deliverychargesform=new FormGroup({
  deliverytypeid:new FormControl(null,Validators.required),
  warehouseid:new FormControl(null,Validators.required),    
  taxgrpid:new FormControl(null,Validators.required),         
  fromamt:new FormControl(''),         
  toamt:new FormControl(''),
  chargesamt:new FormControl(''),
  deliverychargeid:new FormControl(0),      
 inactive:new FormControl(false),     
});


isloading:boolean=false;
isprivilage:boolean=false;  
showProgress:boolean=false;
formname:String="DeliveryCharges";

_routerSub = Subscription.EMPTY;
_routerparamsSub = Subscription.EMPTY;
_routerLoginSub = Subscription.EMPTY

constructor(private appService: DhukanDataService,private route: ActivatedRoute,
   public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
    this._routerSub = this.route.queryParams.subscribe(params => {
      if(params.id!==undefined){
        this.deliverychargesid = params.id
      }  
     });
     this.fnServiceChanges();
}


fnServiceChanges(){
  this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}

ngOnInit() {
    try{
      if(this.loginService.fnCheckUser()){ 
        if(this.fnCheckUserPririvilage()){       
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
              
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}
ngOnDestroy(): void {
  if (this._routerSub !== undefined) {
    this._routerSub.unsubscribe();
  }
  if (this._routerparamsSub !== undefined) {
    this._routerparamsSub.unsubscribe();
  }

  if (this._routerLoginSub !== undefined) {
    this._routerLoginSub.unsubscribe();
  }
}
fnOnInitForm(){

   try{
       this.isaddmode=true;
       this.deliverychargesform.reset();
       this.fnInitializeDeliveryChargesFormGroup(); 

       if(this.deliverychargesid>0){
         this.getDeliveryCharges();
       }
       this.fngetWarehouse();
       this.fngetDeliveryType();
       this.fngetTaxGroup();
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}


fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.deliverychargesid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}

fnInitializeDeliveryChargesFormGroup(){
   this.deliverychargesform.setValue({
      deliverytypeid:null,
      warehouseid:null,
      taxgrpid:null,
      fromamt:'',
      toamt:'',
      chargesamt:'',
      deliverychargeid:0,      
      inactive:false,      
   })
}

//Get 
fngetDeliveryType() {  

    var data = {
          'deliverytypeid': 0,            
    }
    this.appService.getDeliveryType(data)
        .subscribe(
          (res)=>{                  
            this.fnHideProgress();       
            if(res.json().status==200){                          
              this.deliverytypeColl=res.json().result;
            }
            else if(res.json().status==400){                          
              this.toastr.error('Error', res.json().err_field);
            }
           
           
        },
          (err)=>{      
            this.fnHideProgress();           
            this.toastr.error('Error', err);
          })
}

fngetTaxGroup() {  

  var data = {
        'taxgrpid': 0,            
  }
  this.appService.getTaxGroup(data)
      .subscribe(
        (res)=>{                  
          this.fnHideProgress();       
          if(res.json().status==200){                          
            this.taxgroupColl=res.json().result;
          }
          else if(res.json().status==400){                          
            this.toastr.error('Error', res.json().err_field);
          }
         
         
      },
        (err)=>{      
          this.fnHideProgress();           
          this.toastr.error('Error', err);
        })
}


fngetWarehouse() {        
  var data = {
    'warehouseid':0,            
  }
  this.appService.getWarehouse(data)
  .subscribe(
    (res)=>{                  
      this.warehouseColl=res.json().result;     
    },
  (err)=>{          
    this.toastr.error('Error', err);
  })
}

getDeliveryCharges() {        
     var data = {
       'deliverychargeid':this.deliverychargesid,            
     }
     this.appService.getDeliveryCharges(data)
     .subscribe(
       (res)=>{                  
         this.deliverychargesColl=res.json().result;
         var editdata = {
           'deliverytypeid':this.deliverychargesColl[0].deliverytypeid,
           'deliverychargeid':this.deliverychargesColl[0].deliverychargeid,
           'warehouseid':this.deliverychargesColl[0].warehouseid, 
           'taxgrpid':this.deliverychargesColl[0].taxgrpid, 
           'fromamt':this.deliverychargesColl[0].fromamt, 
           'toamt':this.deliverychargesColl[0].toamt, 
           'chargeamt':this.deliverychargesColl[0].chargeamt, 
           'inactive':this.deliverychargesColl[0].inactive,          
         }
         this.fnEditMode(editdata);
     },
     (err)=>{          
       this.toastr.error('Error', err);
     })
}

//Save Btn Click
fnSaveBtnClick(){
   try{
     this.submitted=true;
     this.successfully=false;
     //Validate
     if(this.deliverychargesform.valid){
         //Check
         var data = {
                'deliverytypeid':this.deliverychargesform.get('deliverytypeid').value, 
               'deliverychargeid': this.deliverychargesid,       
               'warehouseid':this.deliverychargesform.get('warehouseid').value,            
               'taxgrpid':this.deliverychargesform.get('taxgrpid').value,            
               'fromamt':this.deliverychargesform.get('fromamt').value,            
               'toamt':this.deliverychargesform.get('toamt').value,
               'chargeamt':this.deliverychargesform.get('chargesamt').value,
               'inactive': 0,  
               'createdby': this.loginService.fnGetModUserId(),    
               'modifiedby':this.loginService.fnGetModUserId(), 
         }
         if (this.deliverychargesform.get('deliverychargeid').value>0){
            //Update Department
            this.fnSetDeliveryCharges(data);
          }
          else{
            //Insert Department
            this.fnInsertDeliveryCharges(data);
          }
      }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}

//Insert
fnInsertDeliveryCharges(data){    
 
   this.appService.fnInsertDeliveryCharges(data).subscribe(
     (res)=>{
     if(res.json().result!=""){
       this.toastr.success('', "The new deliverycharges has been added successfully.Insert Successfully");

       this.fnInsertActivityLog('INSERT',0,data.deliverytypeid);

       this.fnCloseDeliveryCharges();
     }},
     (err)=>{
       this.toastr.error('Error', err);
     });

}

//Set
fnSetDeliveryCharges(data){    

this.appService.fnSetDeliveryCharges(data).subscribe(
 (res)=>{
 if(res.json().result!=""){
     this.toastr.success('', "The deliverycharges has been updated successfully.");

     this.fnInsertActivityLog('EDIT',this.deliverychargesid,data.deliverytypeid);

     this.fnCloseDeliveryCharges();        
 }},
 (err)=>{
   this.toastr.error('Error', err);
 });

}

//Close
fnCloseDeliveryCharges(){
try{
  this.router.navigate(['/DeliveryCharges']);
}catch(ex){
   this.fnShowErrorMsg(ex);
}
}

private fnEditMode(deptBO){

 this.deliverychargesform.setValue({
    deliverytypeid:deptBO.deliverytypeid,
    warehouseid:deptBO.warehouseid,
    taxgrpid:deptBO.taxgrpid,
    fromamt:deptBO.fromamt,
    toamt:deptBO.toamt,
    chargesamt:deptBO.chargeamt,
    deliverychargeid:deptBO.deliverychargeid,
    inactive:deptBO.inactive,   
 });


this.isaddmode=false;
this.iseditmode=true;
this.isdeletemode=false;
this.isviewmode=false;
}


   
fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}



private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


private  fnShowErrorMsg(ex){
this.toastr.warning('Internal Error', ex);       
}




}
