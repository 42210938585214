import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-salesbyproduct',
  templateUrl: './salesbyproduct.component.html',
  styleUrls: ['./salesbyproduct.component.css']
})
export class SalesbyproductComponent implements OnInit {

 
  mainColl:any=[];
  customerColl:any=[];

  isadd:boolean=false;
  isedit:boolean=false;
  isdelete:boolean=false;
  isview:boolean=false;
  isprint:boolean=false;
   isexport:boolean=false; 
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
showProgress:boolean=false;
  formname:String="ProductByCustomer";

  fromdate:any=null;
  todate:any=null;
  isshowfromdate: boolean = false;
  customerid:any = null;
  
  private gridApi;
  private gridColumnApi;
  pinnedBottomRowData;

  columnDefs = [ 
    { headerName: 'Customer', field: 'customername', resizable: true, sortable: true, filter: true },
    { headerName: 'Mobile', field: 'mobile', resizable: true , sortable: true, filter: true },
    { headerName: 'Barcode', field: 'sku', resizable: true , sortable: true, filter: true },
    { headerName: 'Productname', field: 'productname', resizable: true, sortable: true, filter: true },
    { headerName: 'Promo', field: 'offercode', sortable: true, filter: true }, 
    { headerName: 'Department', field: 'deptname', resizable: true, sortable: true, filter: true },
    { headerName: 'Subcategory', field: 'subcategoryname', resizable: true, sortable: true, filter: true },
    { headerName: 'Brand', field: 'brandname', resizable: true , sortable: true, filter: true },
    { headerName: 'Uom', field: 'uom', resizable: true , sortable: true, filter: true },
    { headerName: 'Measure', field: 'uomqty', sortable: true, filter: true },
    { headerName: 'Sales Qty', field: 'qty', sortable: true, filter: true, valueFormatter:  this.fnFormatDecimal } ,
    { headerName: 'Price W/O VAT', field: 'pricewotax', sortable: true, filter: true,valueFormatter:  this.fnFormatDecimal },
    { headerName: 'Price Incl VAT', field: 'price', sortable: true, filter: true,valueFormatter: this.fnFormatDecimal },
    { headerName: 'SubTotal Incl VAT', field: 'subtotal', sortable: true, filter: true,valueFormatter:  this.fnFormatDecimal  },
    { headerName: 'Discount', field: 'discamt', sortable: true, filter: true, valueFormatter:  this.fnFormatDecimal  },
    { headerName: 'NetTotal', field: 'nettotal', sortable: true, filter: true, valueFormatter: this.fnFormatDecimal  },    
    { headerName: 'Taxable Amt', field: 'taxableamt', sortable: true, filter: true, valueFormatter:  this.fnFormatDecimal  },
    { headerName: 'Tax Amt', field: 'taxamt', sortable: true, filter: true ,valueFormatter:  this.fnFormatDecimal },    
   
  ]

  _routerSub = Subscription.EMPTY;
  _routerLoginSub = Subscription.EMPTY
  
  constructor( private appService: DhukanDataService,public router: Router,
    private confirmationDialogService: ConfirmationDialogService,private datePipe: DatePipe,
    private toastr: ToastrService,private loginService:LoginDataService)
     {     this.fnServiceChanges(); }

 //User Check
 fnServiceChanges(){
  this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){                
            this.fnCheckPrivilage();
            if(this.isview){
              this.isloading=true;
              this.fnOnInitForm();
            }
            else{
              this.fnUserFailedForm();
            }    
        }
        else{
          this.fnUserFailedForm();
        }
  });
  }  


  ngOnInit() {
    try{
      
      this.fromdate=new Date();
      this.todate=new Date();
      
     
      if(this.loginService.fnCheckUser()){         
        this.fnCheckPrivilage();
        if(this.isview){
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }          
      }
      else{
        this.loginService.fnGetUserRoleList();
      }


      
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm(){
    this.fngetReportListView();     

    this.fngetCustomer();
  }

  fnCheckPrivilage(){
    this.isadd=this.loginService.fnCheckUserRoleAction(this.formname,'CREATE');
    this.isedit=this.loginService.fnCheckUserRoleAction(this.formname,'ALTER');
    this.isdelete=this.loginService.fnCheckUserRoleAction(this.formname,'DELETE');
    this.isview=this.loginService.fnCheckUserRoleAction(this.formname,'VIEW');
    this.isprint=this.loginService.fnCheckUserRoleAction(this.formname,'PRINT');
    this.isexport=this.loginService.fnCheckUserRoleAction(this.formname,'EXPORT');
  }

  fnUserFailedForm(){
    this.isprivilage=true;
  }


  ngAfterViewInit(): void {
 
  }

  ngOnDestroy(): void {
   
    if (this._routerSub !== undefined) {
      this._routerSub.unsubscribe();
    }
  
    if (this._routerLoginSub !== undefined) {
      this._routerLoginSub.unsubscribe();
    }
  }
  
  
    
  fngetCustomer() {
 
    var data = {
      'customerid': 0,
    }
    this.appService.getCustomerAdmin(data)
      .subscribe(
        (res) => {

          this.fnHideProgress();
          if (res.json().status == 200) {
            this.customerColl = res.json().result;            
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }


        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }

  fngetReportListView() {   
    if(this.showProgress){
      return;
    }

    this.fnShowProgress();

    var fromdate = null;
    if (!this.isshowfromdate) {
      fromdate = this.fnFormatDatetime(this.fromdate);
    }

      var data = {
            'fromdate':fromdate, 
            'todate':this.fnFormatDatetime(this.todate), 
            'formname': 'SALESBYPRODUCT',       
            'customerid':this.customerid,
            'modifiedby': this.loginService.fnGetModUserId(),
      }
      this.appService.getReportSalesView(data)
          .subscribe(
            (res)=>{      
              this.fnHideProgress();                   
              if(res.json().status==200){                          
                this.mainColl = res.json().result[0];
                
                this.pinnedBottomRowData = res.json().result[1];

                this.fnSetDataTable();
             
              }
              else if(res.json().status==400){                          
                this.toastr.error('Error', res.json().err_field);
              }                          
          },
            (err)=>{          
              this.fnHideProgress();       
              this.toastr.error('Error', err);
            })
  }

 
  
  //Grid
  fnGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  

  private fnSetDataTable(){
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();

      var allColIds = this.gridColumnApi.getAllColumns()
          .map(column => column.colId);
          this.gridColumnApi.autoSizeColumns(allColIds);

      // If you want to resize all columns
      this.gridColumnApi.autoSizeAllColumns();


    }
  
  }

  public fnSearchDatatable(value){    
   
  }


  
fnFormatDatetime(dt){
  if(dt){
    if(dt===""){
      return null
    }
    else{
      return this.datePipe.transform(dt,"yyyy-MM-dd")  
    }
    
    }else {
    return null
  }
  
}

fnConvertDatetime(dt){
  if(dt){
    if(dt===""){
      return null
    }
    else{
      

      var dateParts = dt.split("-"); 

       var sdate =new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]+1); 

       sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());

       
       
       return sdate;
    }
    
    }else {
    return null
  }
 
}


      
fnPrintData(){
  try{
  
                      
  }catch(ex){
    this.fnShowErrorMsg(ex);
  }    
}

fnExportData(){
  try{
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'ProductByCustomer',
      //columnSeparator: document.querySelector("#columnSeparator").value
    };
    this.gridApi.exportDataAsCsv(params);
                          
  }catch(ex){
    this.fnShowErrorMsg(ex);
  }    
}
  
fnFormatDecimal(params) {
  if (params.value) {
   return params.value.toFixed(2)
  }    
}

  
private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


  private  fnShowErrorMsg(ex){
    this.toastr.warning('Internal Error', ex);       
  }



}
