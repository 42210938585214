import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Alert } from 'selenium-webdriver';
import { TabHeadingDirective } from 'ngx-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductfaqComponent } from '../productfaq/productfaq.component';
import { SectiontypeComponent } from '../sectiontype/sectiontype.component';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ProductorderbyComponent } from './productorderby/productorderby.component';
declare var $ :any;

@Component({
  selector: 'app-productsection',
  templateUrl: './productsection.component.html',
  styleUrls: ['./productsection.component.css']
})
export class ProductsectionComponent implements OnInit,AfterViewInit , OnDestroy{
  
  productsectionColl:any=[];

  sectiontypeColl:any=[];
  sectiontypeid=null;
  sectiontypename=""
  sectiontypedisplayname=""

  sectionname:string="DEALOFTHEDAY";

  
  bannertypeColl:any=[];
  bannertypeid=0
  bannerColl:any=[];

  isadd:boolean=false;
  isedit:boolean=false;
  isdelete:boolean=false;
  isview:boolean=false;
  isprint:boolean=false;
   isexport:boolean=false; 
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
showProgress:boolean=false;
  formname:String="ProductSection";

  
  filterby:String="ACTIVE";

  

  private gridApi;
  private gridColumnApi;

  columnDefs = [
    { headerName: 'Product Name', field: 'productname', sortable: true, filter: true},
    { headerName: 'Sku', field: 'sku', sortable: true, filter: true },
    { headerName: 'Department', field: 'deptname', sortable: true, filter: true },
    { headerName: 'Category', field: 'categoryname', sortable: true, filter: true },
    { headerName: 'SubCategory', field: 'subcategoryname', sortable: true, filter: true },
    { headerName: 'Brand', field: 'brandname', sortable: true, filter: true },
    { headerName: 'OrderNo', field: 'sequence', sortable: true, filter: true },
  ]

  gridOptions = {
    rowSelection: 'single',
    rowHeight: 40,
    columnDefs: this.columnDefs,
    defaultColDef: {
      resizable: true,
      flex: 1,
      width: 100,
      minWidth: 100,
      enableValue: false,
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: true,
    },
  };

  
  _routerSub = Subscription.EMPTY;
  _routerLoginSub = Subscription.EMPTY


  constructor( private appService: DhukanDataService,public router: Router,private route: ActivatedRoute,
    private confirmationDialogService: ConfirmationDialogService,private toastr: ToastrService, private productFindService: NgbModal,private loginService:LoginDataService) {
      this._routerSub = this.route.queryParams.subscribe(params => {   
        if(params.sectionname!=undefined){
          this.sectionname = params.sectionname; 
        }            
      });
    
    this.fnServiceChanges();
  }
   
  fnServiceChanges(){
    this._routerLoginSub =  this.loginService.serviceloginChange.subscribe((value) => {
          if(this.loginService.fnCheckUser()){                
              this.fnCheckPrivilage();
              if(this.isview){
                this.isloading=true;
                this.fnOnInitForm();
              }
              else{
                this.fnUserFailedForm();
              }    
          }
          else{
            this.fnUserFailedForm();
          }
    });
  }

  ngOnInit() {
    try{
         
    if(this.loginService.fnCheckUser()){         
      this.fnCheckPrivilage();
      if(this.isview){
        this.isloading=true;
        this.fnOnInitForm();
      }
      else{
        this.fnUserFailedForm();
      }          
    }
    else{
      this.loginService.fnGetUserRoleList();
    }


    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }
  
  

  fnOnInitForm(){
  
    this.fngetBannerType();
    this.fnGetSectionname();
  }

  fnCheckPrivilage(){
    this.isadd=this.loginService.fnCheckUserRoleAction(this.formname,'CREATE');
    this.isedit=this.loginService.fnCheckUserRoleAction(this.formname,'ALTER');
    this.isdelete=this.loginService.fnCheckUserRoleAction(this.formname,'DELETE');
    this.isview=this.loginService.fnCheckUserRoleAction(this.formname,'VIEW');
    this.isprint=this.loginService.fnCheckUserRoleAction(this.formname,'PRINT');
    this.isexport=this.loginService.fnCheckUserRoleAction(this.formname,'EXPORT');
  }

  fnUserFailedForm(){
    this.isprivilage=true;
  }
  

  ngAfterViewInit(): void {
   
  }

  ngOnDestroy(): void {
   
    if (this._routerSub !== undefined) {
      this._routerSub.unsubscribe();
    }
  
    if (this._routerLoginSub !== undefined) {
      this._routerLoginSub.unsubscribe();
    }
  }

  
  
  //Grid
  fnGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;   
  }

  
  //Event  
  fnActionEditClick() {
    if (!this.gridApi) {
      return;
    }
    
    if (this.gridApi.getSelectedRows().length > 0 ) {
      var data = this.gridApi.getSelectedRows()[0];
      this.fnEditProductSection(data.productsectionid);
   }   
  }

  fnActionDeleteClick() {
    if (!this.gridApi) {
      return;
    }
    
    if (this.gridApi.getSelectedRows().length > 0 ) {
      var data = this.gridApi.getSelectedRows()[0];
      this.fnDeleteConfirm(data.productsectionid);
   }   
  }

  fnActionExportClick()  {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'delaofthedaylist',      
    };
    this.gridApi.exportDataAsCsv(params);
  }



  fngetBannerType() {        
  
    var data = {
      'bannertypeid':0,            
    }
    this.appService.getBannerType(data)
    .subscribe(
      (res)=>{                  
        this.bannertypeColl=res.json().result; 
                  
        for(let wbo of this.bannertypeColl){
          if(wbo.bannertype=="DEALOFTHEDAY"){
            this.bannertypeid=wbo.bannertypeid;
          }
        }

       
        this.fngetBanner();    
    },
    (err)=>{          
      this.toastr.error('Error', err);
    })
  }

  
  fngetBanner() {   
   
    var data = {
          'bannerid':0,
          'bannertypeid': this.bannertypeid,            
    }
    this.appService.getBanner(data)
        .subscribe(
          (res)=>{                  
            if(res.json().status==200){                          
              this.bannerColl=res.json().result;

                        
            }
            else if(res.json().status==400){                          
              this.toastr.error('Error', res.json().err_field);
            }
           
        },
          (err)=>{          
            this.toastr.error('Error', err);
          })
  }


    
fnAddProductOrderByBtnClick(){
  const modalRef = this.productFindService.open(ProductorderbyComponent ,{ size:  <any>'xl' });
  modalRef.componentInstance.doctype ="Product " ;
  modalRef.componentInstance.mode ="EDIT" ;

  modalRef.componentInstance.productColl = this.productsectionColl;

  console.log(this.productsectionColl);

  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
    window.location.reload()      

  });
      
}

  fnGetSectionname(){
    // this.productsectionnameColl.push({name:'DEALOFTHEDAY',displayname:'Deal Of The Day'})
    // this.productsectionnameColl.push({name:'BESTSELLINGPRODUCT',displayname:'Best Seliing Product'})
    // this.productsectionnameColl.push({name:'DUKANEXCLUSIVE',displayname:'Dukan Exclusive'})  
    // this.productsectionnameColl.push({name:'FESTIVALSAVINGPACK',displayname:'Festival Saving Pack'})
  
    this.sectiontypeColl=[];
    var data = {
      'sectiontypeid':0,            
    }
    this.appService.getSectionType(data)
    .subscribe(
      (res)=>{                  
        this.sectiontypeColl=res.json().result; 
                      
        if(this.sectiontypeColl.length>0){
          this.sectiontypeid=this.sectiontypeColl[0].sectiontypeid;
          this.sectiontypedisplayname=this.sectiontypeColl[0].sectiontypedisplayname;
          this.sectiontypename=this.sectiontypeColl[0].sectiontypename;
        }
        
        this.fngetProductSection();         
    },
    (err)=>{          
      this.toastr.error('Error', err);
    })

  }

  fnChangeSectionType(){
               
    for(let wbo of this.sectiontypeColl){

      if(wbo.sectiontypeid===this.sectiontypeid){
        this.sectiontypename=wbo.sectiontypename;
        this.sectiontypedisplayname=wbo.sectiontypedisplayname;
      }
    }

    

  }

  fnChangeDisplayName(){

    const modalRef = this.productFindService.open(SectiontypeComponent ,{ size: 'lg' });
    modalRef.componentInstance.doctype ="Section Type" ;
  
    for(let wbo of this.sectiontypeColl){

      if(wbo.sectiontypeid===this.sectiontypeid){
      
        modalRef.componentInstance.sectiontypeid=this.sectiontypeid;
        modalRef.componentInstance.sectiontypename=this.sectiontypename;
        modalRef.componentInstance.sectiontypedisplayname=wbo.sectiontypedisplayname;
        modalRef.componentInstance.linktype=wbo.linktype;
        modalRef.componentInstance.linktargetid=wbo.linktargetid;
        modalRef.componentInstance.linkurl=wbo.linkurl;
        modalRef.componentInstance.linkhtml=wbo.linkhtml; 
        modalRef.componentInstance.productname=wbo.productname;
      }
    }
    modalRef.componentInstance.fnEditSectionType();

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
         
      var data={
        sectiontypeid:receivedEntry.sectiontypeid,
        sectiontypename:receivedEntry.sectiontypename,
        sectiontypedisplayname:receivedEntry.sectiontypedisplayname,
        linktype:receivedEntry.linktype,
        linktargetid:receivedEntry.linktargetid,
        linkurl:receivedEntry.linkurl,
        linkhtml:receivedEntry.linkhtml,
      }
   
  
      this.appService.fnSetSectionType(data)
      .subscribe(
        (res)=>{                  
            this.fnGetSectionname();
      },
      (err)=>{          
        this.toastr.error('Error', err);
      })

      
        
    });
      
    
  }

  fngetProductSection() {   
    if(this.showProgress){
      return;
    }

      var data = {
            'productsectionid': 0,   
            'sectiontypeid':this.sectiontypeid        
      }
      this.appService.getProductSection(data)
          .subscribe(
            (res)=>{                  
              if (res.json().status == 200) {                          
                var productsectionallColl:any = [];
                productsectionallColl = res.json().result;
                
                productsectionallColl = res.json().result;
                
                this.productsectionColl = [];
             
                  for (let abo of productsectionallColl) {
                    if (this.filterby === 'ACTIVE') { 
                      if (abo.inactive === 0) {
                        this.productsectionColl.push(abo)
                      }                        
                    }
                    else if (this.filterby === 'INACTIVE') { 
                      if (abo.inactive === 1) {
                        this.productsectionColl.push(abo)
                      }  
                    }
                    else{
                      this.productsectionColl.push(abo)
                    }
                  }
                
                this.fnSetDataTable();
           
              }
              else if(res.json().status==400){                          
                this.toastr.error('Error', res.json().err_field);
              }
             
             
          },
            (err)=>{          
              this.toastr.error('Error', err);
            })
  }

  fnAddProductSection(){
    try{
      let navigationExtras: NavigationExtras = {
        queryParams: {         
          'sectiontypeid':this.sectiontypeid        
        }
      }

      this.router.navigate(['/AddProductSection'], navigationExtras);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnEditProductSection(deptid){
    try{
        let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': deptid,
          'sectionname':this.sectionname        
        }
        }
  
      this.router.navigate(['/AddProductSection'], navigationExtras);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  public fnDeleteConfirm(deptid) {
    try{
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete ProductSection?')
      .then((confirmed) => 
            {
              if(confirmed){
                this.fnDeleteProductSection(deptid)
              }          
          }        
      );     
      //.catch(() => );
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteProductSection(deptid){
       var data = {
         'productsectionid': deptid,            
       }
      this.appService.fnDeleteProductSection(data)
        .subscribe(
          (res)=>{ 
            if(res.json().status===200){
              this.toastr.success('', " The ProductSection has been deleted successfully.");                 
              this.fngetProductSection();
            }else{
              this.toastr.error('',res.json().err_field);   
             }
          },
          (err)=>{          
            this.toastr.error('Error', err);
          })
  }


  fnAddBanner(){
    try{
      let navigationExtras: NavigationExtras = {
        queryParams: {         
          'bannertypeid':this.bannertypeid ,
          'section':this.sectionname          
        }
      }

      this.router.navigate(['/AddBanner'],navigationExtras);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }    
  }

  fnEditBanner(deptid){
    try{
        let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': deptid,
          'bannertypeid':this.bannertypeid,
          'section':this.sectionname                
        }
        }
  
      this.router.navigate(['/AddBanner'], navigationExtras);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  public fnDeleteBannerConfirm(deptid) {
    try{
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete banner?')
      .then((confirmed) => 
            {
              if(confirmed){
                this.fnDeleteBanner(deptid)
              }          
          }        
      );     
      //.catch(() => );
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  private fnDeleteBanner(deptid){
       var data = {
         'bannerid': deptid,            
       }
      this.appService.fnDeleteBanner(data)
        .subscribe(
          (res)=>{ 
            if(res.json().status==200){   
              this.toastr.success('', " The banner has been deleted successfully.");                 

              this.fnInsertActivityLog('DELETE',deptid,this.fnGetDescription(deptid));

              this.fngetBanner();
            }
            else if(res.json().status==400){                          
              this.toastr.error('Error', res.json().err_field);
            }
          },
          (err)=>{          
            this.toastr.error('Error', err);
          })
  }
  
  private fnSetDataTable(){
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  

  }


fnGetDescription(id){
  const index = this.productsectionColl.findIndex(sku => sku.productsectionid === id  ); 

  if(index){
    return this.productsectionColl[index].productid;
  }
  else{
    return "";
  }

}

fnInsertActivityLog(action,refno,desc){
try{
  var data={
    logtype:action,
    logat:this.formname,
    refnum:refno,
    logdescr:desc,
    logby:this.loginService.fnGetModUserId(),
  }


  this.appService.fnInsertActivity(data).subscribe(
    (res)=>{
    },
    (err)=>{
   
    });

}catch{

}
}



  private  fnShowErrorMsg(ex){
    this.toastr.warning('Internal Error', ex);       
  }


}
