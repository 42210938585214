import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'datetimeFormat'
})
export class DatetimeFormatPipe implements PipeTransform {

 
  transform(value: string) {

    
    var datePipe = new DatePipe("en-IN");
    if(value){
      // value = datePipe.transform(value, 'dd-MM-yyyy h:mm:ss a');
     // value = datePipe.transform(value, 'dd-MM-yyyy h:mm:ss a');
      value = datePipe.transform(value, 'dd-MM-yyyy h:mm:ss a', 'UTC +2:30');
    }     
     return value;
 }

}
