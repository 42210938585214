import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';





@Injectable({
  providedIn: 'root'
})
export class FibaseDataService {

  items: Observable<any[]>;
  itemsboy: Observable<any[]>;

  

  constructor(private http:HttpClient,private firestore: AngularFireDatabase) { }


    
  public fnGetShipmentList(){
    
    this.items = this.firestore.list('/ShipmentChanges').valueChanges();
 
    return this.items; 
    
  }

  public fnGetDeliveryBoyLocation(){
    
    this.itemsboy = this.firestore.list('/DeliveryBoyLocation').valueChanges();
 
    return this.itemsboy; 
    
  }
}
