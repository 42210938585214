   import { AppSettings } from '../constants/constants';
import { Injectable } from '@angular/core';
import {  HttpErrorResponse } from '@angular/common/http';
import { Http, Headers } from '@angular/http';
import { Observable, throwError } from 'rxjs'
import { catchError, map,share } from 'rxjs/operators';
import { HttpClient } from 'selenium-webdriver/http';
import { DeviceDetectorService } from 'ngx-device-detector';


@Injectable(
   {
   providedIn: 'root'
 }
)
export class DhukanDataService {
   deviceInfo = null;
   ipAddress:any;

  constructor(private http: Http,private deviceService: DeviceDetectorService) {
   this.deviceInfo = this.deviceService.getDeviceInfo();
   this.fnGetIpAddress();
   console.log(this.deviceInfo)
  }

  fnpostInputParams(url, params) {
    const headers = new Headers({    
        //'Content-Type':  "application/JSON",
       // 'token': (localStorage.adtoken === undefined) ? '' : JSON.parse(localStorage.adtoken)                
    });

   
    return this.http.post(AppSettings.base_url + url, params, { headers: headers });
  }
  
  //Department

  fnInsertDepartment(params) {     
     
     return this.fnpostInputParams(AppSettings.insertDepartmentUrl, params);
  }

  fnSetDepartment(params) {
     
     return this.fnpostInputParams(AppSettings.setDepartmentUrl, params);
    }


  getDepartment(params) {  
     
     return this.fnpostInputParams(AppSettings.getDepartmentUrl, params).pipe(share());
  }


  fnDeleteDepartment(params) {
   
   return this.fnpostInputParams(AppSettings.deleteDepartmentUrl, params);
  }

  fnCheckDepartment(params) {     
   
   return this.fnpostInputParams(AppSettings.checkDepartmentUrl, params);
  }



//CATEGORY

fnInsertCategory(params) {     
   
   return this.fnpostInputParams(AppSettings.insertCategoryUrl, params);
}

fnSetCategory(params) {
   
   return this.fnpostInputParams(AppSettings.setCategoryUrl, params);
  }


getCategory(params) {  
   
   return this.fnpostInputParams(AppSettings.getCategoryUrl, params);
}


fnDeleteCategory(params) {
 
 return this.fnpostInputParams(AppSettings.deleteCategoryUrl, params);
}

fnCheckCategory(params) {     
 
 return this.fnpostInputParams(AppSettings.checkCategoryUrl, params);
}



//SUBCATEGORY

fnInsertSubCategory(params) {     
 
 return this.fnpostInputParams(AppSettings.insertSubCategoryUrl, params);
}

fnSetSubCategory(params) {  
return this.fnpostInputParams(AppSettings.setSubCategoryUrl, params);

}


getSubCategory(params) {  

 return this.fnpostInputParams(AppSettings.getSubCategoryUrl, params);
}


fnDeleteSubCategory(params) {

return this.fnpostInputParams(AppSettings.deleteSubCategoryUrl, params);
}

fnCheckSubCategory(params) {     

   
return this.fnpostInputParams(AppSettings.checkSubCategoryUrl, params);
}



//BRAND

fnInsertBrand(params) {     
   
   return this.fnpostInputParams(AppSettings.insertBrandUrl, params);
}

fnSetBrand(params) {
   
   return this.fnpostInputParams(AppSettings.setBrandUrl, params);
  }


getBrand(params) {  
   
   return this.fnpostInputParams(AppSettings.getBrandUrl, params);
}


fnDeleteBrand(params) {
 
 return this.fnpostInputParams(AppSettings.deleteBrandUrl, params);
}

fnCheckBrand(params) {     
 
 return this.fnpostInputParams(AppSettings.checkBrandUrl, params);
}




//UOM

fnInsertUom(params) {     
 
 return this.fnpostInputParams(AppSettings.insertUomUrl, params);
}

fnSetUom(params) {
 
 return this.fnpostInputParams(AppSettings.setUomUrl, params);
}


getUom(params) {  
 
 return this.fnpostInputParams(AppSettings.getUomUrl, params);
}


fnDeleteUom(params) {
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.deleteUomUrl, params);
}

fnCheckUom(params) {     
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.checkUomUrl, params);
}



//STATUS

fnInsertStatus(params) {     
 
 return this.fnpostInputParams(AppSettings.insertStatusUrl, params);
}

fnSetStatus(params) {
 
 return this.fnpostInputParams(AppSettings.setStatusUrl, params);
}


getStatus(params) {  
 
 return this.fnpostInputParams(AppSettings.getStatusUrl, params);
}


fnDeleteStatus(params) {
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.deleteStatusUrl, params);
}

fnCheckStatus(params) {     
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.checkStatusUrl, params);
}





//WAREHOUSE

fnInsertWarehouse(params) {     
 
 return this.fnpostInputParams(AppSettings.insertWarehouseUrl, params);
}

fnSetWarehouse(params) {
 
 return this.fnpostInputParams(AppSettings.setWarehouseUrl, params);
}


getWarehouse(params) {  
 
 return this.fnpostInputParams(AppSettings.getWarehouseUrl, params);
}


fnDeleteWarehouse(params) {
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.deleteWarehouseUrl, params);
}

fnCheckWarehouse(params) {     
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.checkWarehouseUrl, params);
}




//City

fnInsertCity(params) {     
 
 return this.fnpostInputParams(AppSettings.insertCityUrl, params);
}

fnSetCity(params) {
 
 return this.fnpostInputParams(AppSettings.setCityUrl, params);
}


getCity(params) {  
 
 return this.fnpostInputParams(AppSettings.getCityUrl, params);
}


fnDeleteCity(params) {
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.deleteCityUrl, params);
}

fnCheckCity(params) {     
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.checkCityUrl, params);
}







//Area

fnInsertArea(params) {     
 
 return this.fnpostInputParams(AppSettings.insertAreaUrl, params);
}

fnSetArea(params) {
 
 return this.fnpostInputParams(AppSettings.setAreaUrl, params);
}


getArea(params) {  
 
 return this.fnpostInputParams(AppSettings.getAreaUrl, params);
}


fnDeleteArea(params) {
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.deleteAreaUrl, params);
}

fnCheckArea(params) {     
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.checkAreaUrl, params);
}





//Vendor

fnInsertVendor(params) {     
 
 return this.fnpostInputParams(AppSettings.insertVendorUrl, params);
}

fnSetVendor(params) {
 
 return this.fnpostInputParams(AppSettings.setVendorUrl, params);
}


getVendor(params) {  
 
 return this.fnpostInputParams(AppSettings.getVendorUrl, params);
}


fnDeleteVendor(params) {
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.deleteVendorUrl, params);
}

fnCheckVendor(params) {     
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.checkVendorUrl, params);
}



//Tax Group

fnInsertTaxGroup(params) {     
 
 return this.fnpostInputParams(AppSettings.insertTaxGroupUrl, params);
}

fnSetTaxGroup(params) {
 
 return this.fnpostInputParams(AppSettings.setTaxGroupUrl, params);
}


getTaxGroup(params) {  
 
 return this.fnpostInputParams(AppSettings.getTaxGroupUrl, params);
}


fnDeleteTaxGroup(params) {
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.deleteTaxGroupUrl, params);
}

fnCheckTaxGroup(params) {     
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.checkTaxGroupUrl, params);
}



//FoodSymbol

fnInsertFoodSymbol(params) {     
 
 return this.fnpostInputParams(AppSettings.insertFoodSymbolUrl, params);
}

fnSetFoodSymbol(params) {
 
 return this.fnpostInputParams(AppSettings.setFoodSymbolUrl, params);
}


getFoodSymbol(params) {  
 
 return this.fnpostInputParams(AppSettings.getFoodSymbolUrl, params);
}


fnDeleteFoodSymbol(params) {
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.deleteFoodSymbolUrl, params);
}

fnCheckFoodSymbol(params) {     
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.checkFoodSymbolUrl, params);
}




//Certificate

fnInsertCertificate(params) {     
 
 return this.fnpostInputParams(AppSettings.insertCertificateUrl, params);
}

fnSetCertificate(params) {
 
 return this.fnpostInputParams(AppSettings.setCertificateUrl, params);
}


getCertificate(params) {  
 
 return this.fnpostInputParams(AppSettings.getCertificateUrl, params);
}


fnDeleteCertificate(params) {
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.deleteCertificateUrl, params);
}

fnCheckCertificate(params) {     
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.checkCertificateUrl, params);
}






//Banner Type

fnInsertBannerType(params) {     
 
 return this.fnpostInputParams(AppSettings.insertBannerTypeUrl, params);
}

fnSetBannerType(params) {
 
 return this.fnpostInputParams(AppSettings.setBannerTypeUrl, params);
}


getBannerType(params) {  
 
 return this.fnpostInputParams(AppSettings.getBannerTypeUrl, params);
}


fnDeleteBannerType(params) {
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.deleteBannerTypeUrl, params);
}

fnCheckBannerType(params) {     
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.checkBannerTypeUrl, params);
}



//Banner 

fnInsertBanner(params) {     
 
 return this.fnpostInputParams(AppSettings.insertBannerUrl, params);
}

fnSetBanner(params) {
 
 return this.fnpostInputParams(AppSettings.setBannerUrl, params);
}


getBanner(params) {  
 
 return this.fnpostInputParams(AppSettings.getBannerUrl, params);
}


fnDeleteBanner(params) {
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.deleteBannerUrl, params);
}

fnCheckBanner(params) {     
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.checkBannerUrl, params);
}





//Product 

fnInsertProduct(params) {     
 
 return this.fnpostInputParams(AppSettings.insertProductUrl, params);
}

fnSetProduct(params) {
 
 return this.fnpostInputParams(AppSettings.setProductUrl, params);
}

fnSetProductSku(params) {
 
   return this.fnpostInputParams(AppSettings.setProductSkuUrl, params);
  }
  

getProduct(params) {  
 
 return this.fnpostInputParams(AppSettings.getProductUrl, params);
}


getProductByListView(params) {  
 
 return this.fnpostInputParams(AppSettings.getProductListViewUrl, params);
}


fnDeleteProduct(params) {
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.deleteProductUrl, params);
}

fnDeleteProductSku(params) {
 
 return this.fnpostInputParams(AppSettings.deleteProductSkuUrl, params);
  }
  
  getAutoCodeProduct(params) {  
   
   return this.fnpostInputParams(AppSettings.getautocodeproduct, params);
  }
  

fnCheckProduct(params) {     
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.checkProductUrl, params);
}


fnCheckProductSku(params) {     
 
 return this.fnpostInputParams(AppSettings.checkProductSkuUrl, params);
  }
  
  fnSetProductSkuPublish(params) {
 
   return this.fnpostInputParams(AppSettings.setProductSkuPublishUrl, params);
  }
  
  fnSetProductSkuOutOfStock(params) {
 
   return this.fnpostInputParams(AppSettings.setProductSkuOutOfStockUrl, params);
  }
  
  fnSetProductSkuByProductgroup(params) {
 
   return this.fnpostInputParams(AppSettings.setProductSkuByProductGroupUrl, params);
  }

   
   
  fnInsertImportProduct(params) {

   return this.fnpostInputParams(AppSettings.insertImportProductUrl, params);
}

//ProductSection
fnInsertProductSection(params) {     
 
 return this.fnpostInputParams(AppSettings.insertProductSectionUrl, params);
}

fnSetProductSection(params) {     
 
   return this.fnpostInputParams(AppSettings.setProductSectionUrl, params);
  }
  

getProductSection(params) {  
 
 return this.fnpostInputParams(AppSettings.getProductSectionUrl, params);
}


fnDeleteProductSection(params) {
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.deleteProductSectionUrl, params);
}


fnSetSectionType(params) {     
 
 return this.fnpostInputParams(AppSettings.setSectionTypeUrl, params);
}



getSectionType(params) {  
 
 return this.fnpostInputParams(AppSettings.getSectionTypeUrl, params);
}


//Specification 
fnInsertSpecification(params) {     
 
 return this.fnpostInputParams(AppSettings.insertSpecificationUrl, params);
}

fnSetSpecification(params) {
 
 return this.fnpostInputParams(AppSettings.setSpecificationUrl, params);
}


getSpecification(params) {  
 
 return this.fnpostInputParams(AppSettings.getSpecificationUrl, params);
}


fnDeleteSpecification(params) {
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.deleteSpecificationUrl, params);
}

fnCheckSpecification(params) {     
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.checkSpecificationUrl, params);
}





//DeliveryType 
fnInsertDeliveryType(params) {     
 
 return this.fnpostInputParams(AppSettings.insertDeliveryTypeUrl, params);
}

fnSetDeliveryType(params) {
 
 return this.fnpostInputParams(AppSettings.setDeliveryTypeUrl, params);
}


getDeliveryType(params) {  
 
 return this.fnpostInputParams(AppSettings.getDeliveryTypeUrl, params);
}


fnDeleteDeliveryType(params) {
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.deleteDeliveryTypeUrl, params);
}

fnCheckDeliveryType(params) {     
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.checkDeliveryTypeUrl, params);
}




//DeliveryBoy 
fnInsertDeliveryBoy(params) {     
 
 return this.fnpostInputParams(AppSettings.insertDeliveryBoyUrl, params);
}

fnSetDeliveryBoy(params) {
 
 return this.fnpostInputParams(AppSettings.setDeliveryBoyUrl, params);
}


getDeliveryBoy(params) {  
 
 return this.fnpostInputParams(AppSettings.getDeliveryBoyUrl, params);
}


fnDeleteDeliveryBoy(params) {
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.deleteDeliveryBoyUrl, params);
}

fnCheckDeliveryBoy(params) {     
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.checkDeliveryBoyUrl, params);
}


//DeliveryCharges 
fnInsertDeliveryCharges(params) {     
 
 return this.fnpostInputParams(AppSettings.insertDeliveryChargesUrl, params);
}

fnSetDeliveryCharges(params) {
 
 return this.fnpostInputParams(AppSettings.setDeliveryChargesUrl, params);
}


getDeliveryCharges(params) {  
 
 return this.fnpostInputParams(AppSettings.getDeliveryChargesUrl, params);
}


fnDeleteDeliveryCharges(params) {
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.deleteDeliveryChargesUrl, params);
}






//DeliverySlot 
fnInsertDeliverySlot(params) {     
 
 return this.fnpostInputParams(AppSettings.insertDeliverySlotUrl, params);
}

fnSetDeliverySlot(params) {
 
 return this.fnpostInputParams(AppSettings.setDeliverySlotUrl, params);
}


getDeliverySlot(params) {  
 
 return this.fnpostInputParams(AppSettings.getDeliverySlotUrl, params);
}


fnDeleteDeliverySlot(params) {
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.deleteDeliverySlotUrl, params);
}

fnCheckDeliverySlot(params) {     
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.checkDeliverySlotUrl, params);
}




//Coupon 
fnInsertCoupon(params) {     
 
 return this.fnpostInputParams(AppSettings.insertCouponUrl, params);
}

fnSetCoupon(params) {
 
 return this.fnpostInputParams(AppSettings.setCouponUrl, params);
}


getCoupon(params) {  
 
 return this.fnpostInputParams(AppSettings.getCouponUrl, params);
}


fnDeleteCoupon(params) {
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.deleteCouponUrl, params);
}

fnCheckCoupon(params) {     
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.checkCouponUrl, params);
}


//Paymode 
fnInsertPaymode(params) {     
 
 return this.fnpostInputParams(AppSettings.insertPaymodeUrl, params);
}

fnSetPaymode(params) {
 
 return this.fnpostInputParams(AppSettings.setPaymodeUrl, params);
}


getPaymode(params) {  
 
 return this.fnpostInputParams(AppSettings.getPaymodeUrl, params);
}


fnDeletePaymode(params) {
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.deletePaymodeUrl, params);
}

fnCheckPaymode(params) {     
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.checkPaymodeUrl, params);
}




//ProductGroup 
fnInsertProductGroup(params) {     
 
   return this.fnpostInputParams(AppSettings.insertProductGroupUrl, params);
  }
  
  fnSetProductGroup(params) {
   
   return this.fnpostInputParams(AppSettings.setProductGroupUrl, params);
  }
  
  
  getProductGroup(params) {  
   
   return this.fnpostInputParams(AppSettings.getProductGroupUrl, params);
  }
  
  
  fnDeleteProductGroup(params) {
  
  return this.fnpostInputParams(AppSettings.deleteProductGroupUrl, params);
  }
  
  fnCheckProductGroup(params) {     
  
  return this.fnpostInputParams(AppSettings.checkProductGroupUrl, params);
  }

  



//Notification 
fnInsertNotification(params) {     
 
   return this.fnpostInputParams(AppSettings.insertNotificationUrl, params);
  }
  
  fnSetNotification(params) {
   
   return this.fnpostInputParams(AppSettings.setNotificationUrl, params);
  }
  
  
  getNotification(params) {  
   
   return this.fnpostInputParams(AppSettings.getNotificationUrl, params);
  }
  
  
  fnDeleteNotification(params) {
  const headers = new Headers({ 'Content-Type':  "application/JSON" });
  return this.fnpostInputParams(AppSettings.deleteNotificationUrl, params);
  }
  
  fnCheckNotification(params) {     
  const headers = new Headers({ 'Content-Type':  "application/JSON" });
  return this.fnpostInputParams(AppSettings.checkNotificationUrl, params);
  }

  
 

//Branch 
fnInsertBranch(params) {     
 
   return this.fnpostInputParams(AppSettings.insertBranchUrl, params);
  }
  
  fnSetBranch(params) {
   
   return this.fnpostInputParams(AppSettings.setBranchUrl, params);
  }
  
  
  getBranch(params) {  
   
   return this.fnpostInputParams(AppSettings.getBranchUrl, params);
  }
  
  
  fnDeleteBranch(params) {
  const headers = new Headers({ 'Content-Type':  "application/JSON" });
  return this.fnpostInputParams(AppSettings.deleteBranchUrl, params);
  }
  
  fnCheckBranch(params) {     
  const headers = new Headers({ 'Content-Type':  "application/JSON" });
  return this.fnpostInputParams(AppSettings.checkBranchUrl, params);
  }

//User Role 
fnInsertUserRole(params) {     
 
 return this.fnpostInputParams(AppSettings.insertUserRoleUrl, params);
}

fnSetUserRole(params) {
 
 return this.fnpostInputParams(AppSettings.setUserRoleUrl, params);
}


getUserRole(params) {  
 
 return this.fnpostInputParams(AppSettings.getUserRoleUrl, params);
}

getUserRoleRights(params) {  
 
 return this.fnpostInputParams(AppSettings.getUserRoleRightsUrl, params);
}



fnDeleteUserRole(params) {
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.deleteUserRoleUrl, params);
}

fnCheckUserRole(params) {     
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.checkUserRoleUrl, params);
}


//Customer Admin 
fnInsertCustomerAdmin(params) {     
 
 return this.fnpostInputParams(AppSettings.insertCustomerAdminUrl, params);
}

fnSetCustomerAdmin(params) {
 
 return this.fnpostInputParams(AppSettings.setCustomerAdminUrl, params);
}


getCustomerAdmin(params) {  
 
 return this.fnpostInputParams(AppSettings.getCustomerAdminUrl, params);
}

getCustomerAdminView(params) {  
 
 return this.fnpostInputParams(AppSettings.getCustomerAdminViewUrl, params);
}

fnCheckCustomerAdmin(params) {     
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.checkCustomerAdminUrl, params);
}


getCustomerSearch(params) {  
 
   return this.fnpostInputParams(AppSettings.getCustomerSerachUrl, params);
  }


//Address Admin 
fnInsertAddressAdmin(params) {     
 
 return this.fnpostInputParams(AppSettings.insertAddressAdminUrl, params);
}

fnSetAddressAdmin(params) {
 
 return this.fnpostInputParams(AppSettings.setAddressAdminUrl, params);
}


getAddressAdmin(params) {  
 
 return this.fnpostInputParams(AppSettings.getAddressAdminUrl, params);
}

fnDeleteAddressAdmin(params) {     
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.deleteAddressAdminUrl, params);
}







//User 
fnInsertUser(params) {     
 
 return this.fnpostInputParams(AppSettings.insertUserUrl, params);
}

fnSetUser(params) {
 
 return this.fnpostInputParams(AppSettings.setUserUrl, params);
}


getUser(params) {  
 
 return this.fnpostInputParams(AppSettings.getUserUrl, params);
}

getUserListView(params) {  
 
   return this.fnpostInputParams(AppSettings.getUserListviewUrl, params);
  }
  

fnDeleteUser(params) {
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.deleteUserUrl, params);
}

fnCheckUser(params) {     
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.checkUserUrl, params);
}

getUserlogin(params) {  
 
 return this.fnpostInputParams(AppSettings.getUserLoginUrl, params);
}





//Contentmanagement

fnSetContentmanagement(params) {
 
 return this.fnpostInputParams(AppSettings.setContentmanagementUrl, params);
}


getContentmanagement(params) {  
 
 return this.fnpostInputParams(AppSettings.getContentmanagementUrl, params);
}


//Page 

fnInsertPage(params) {     
 
 return this.fnpostInputParams(AppSettings.insertPageUrl, params);
}

fnSetPage(params) {
 
 return this.fnpostInputParams(AppSettings.setPageUrl, params);
}

getPage(params) {  
 
 return this.fnpostInputParams(AppSettings.getPageUrl, params);
}


getPageByListView(params) {  
 
 return this.fnpostInputParams(AppSettings.getPageListViewUrl, params);
}


fnDeletePage(params) {
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.deletePageUrl, params);
}


getLayoutType(params) {  
 
 return this.fnpostInputParams(AppSettings.getLayoutTypeUrl, params);
}

fnSetPageLayoutOrder(params) {
 
   return this.fnpostInputParams(AppSettings.setPageLayoutOrderUrl, params);
}
   
   
//Order
fnSetOrderStatus(params) {
 
 return this.fnpostInputParams(AppSettings.setorderstatusUrl, params);
}

fnSetOrderConfirm(params) {
 
   return this.fnpostInputParams(AppSettings.setorderconfirmUrl, params);
  }


getOrderListView(params){
 
 return this.fnpostInputParams(AppSettings.getorderUrl, params);
}

getOrderStatus(params){
 
   return this.fnpostInputParams(AppSettings.getorderstatusbyorderUrl, params);
}


fnSetOrder(params) {
 
 return this.fnpostInputParams(AppSettings.setorderUrl, params);
}

fnSetOrderCancelled(params) {
 
   return this.fnpostInputParams(AppSettings.setordercancelledUrl, params);
  }

  fnSetOrderRemovedDeliveryCharges(params) {
 
   return this.fnpostInputParams(AppSettings.setorderremovedeliverychargeUrl, params);
  }

  fnSetOrderDeliveryDate(params) {
 
   return this.fnpostInputParams(AppSettings.setorderDeliveryDateUrl, params);
  }

  fnSetOrderCustomerAvailDate(params) {
 
   return this.fnpostInputParams(AppSettings.setorderCustomerAvailDateUrl, params);
  }

getOrderbyOrderid(params) {
 
 return this.fnpostInputParams(AppSettings.getorderByorderidUrl, params);
}



getOrderbyDeliverOrder(params) {
 
 return this.fnpostInputParams(AppSettings.getorderByDeliveryorderUrl, params);
}


fnSetOrderAddress(params) {
 
 return this.fnpostInputParams(AppSettings.setorderaddressUrl, params);
}

getOrderAddressbyOrderAddressid(params) {
 
 return this.fnpostInputParams(AppSettings.getorderaddressByorderaddressidUrl, params);
}


fnSetOrderItemCancelled(params) {
 
   return this.fnpostInputParams(AppSettings.setorderitemcancelledUrl, params);
  }

 
fnSetOrderItemAdd(params) {
 
   return this.fnpostInputParams(AppSettings.setorderitemaddUrl, params);
  } 

  
fnSetOrderItemQtyChange(params) {
 
   return this.fnpostInputParams(AppSettings.setorderitemqtychangesUrl, params);
}
   
fnSetOrderItemDiscountChange(params) {
 
   return this.fnpostInputParams(AppSettings.setorderitemdiscountchangesUrl, params);
  }
  
  
  fnSetSalesInvoice(params) {
 
   return this.fnpostInputParams(AppSettings.setsalesinvoiceUrl, params);
  }
  


fnGetInvoiceOrder(params){

   return this.fnpostInputParams(AppSettings.getinvoiceorderUrl, params);
  }

  
fnGetPrintProductOrder(params){

   return this.fnpostInputParams(AppSettings.getprintproductorderUrl, params);
}

  
fnGetPrintDeliveryOrder(params){

   return this.fnpostInputParams(AppSettings.getprintdeliveryorderUrl, params);
}

 
fnGetPrintOrder(params){

   return this.fnpostInputParams(AppSettings.getprintorderUrl, params);
}

fnGetPrintInvoiceOrder(params){

   return this.fnpostInputParams(AppSettings.getprintinvoiceorderUrl, params);
}


//Delivery Order

fnInsertDeliveryOrder(params) {
 
 return this.fnpostInputParams(AppSettings.insertdeliveryorderUrl, params);
}

fnSetDeliveryOrder(params) {
 
 return this.fnpostInputParams(AppSettings.setdeliveryorderUrl, params);
}

fnInsertDeliveryOrderDetails(params) {
 
 return this.fnpostInputParams(AppSettings.insertdeliveryorderdetailsUrl, params);
}

fnDeleteDeliveryOrder(params) {
 
 return this.fnpostInputParams(AppSettings.deletedeliveryorderUrl, params);
}

getDeliveryOrderListView(params){
 
 return this.fnpostInputParams(AppSettings.getdeliveryorderUrl, params);
}


getDeliveryOrderbyOrderid(params) {
 
 return this.fnpostInputParams(AppSettings.getdeliveryorderBydeliveryorderidUrl, params);
}


getShipment(params){
 
 return this.fnpostInputParams(AppSettings.getshipmenttypeUrl, params);
}


fnSetDeliveryOrderAssigned(params) {
 
   return this.fnpostInputParams(AppSettings.setdeliveryorderassignedUrl, params);
}


fnSetDeliveryOrderPicked(params) {
 
   return this.fnpostInputParams(AppSettings.setdeliveryorderpickedUrl, params);
}



fnSetDeliveryOrderDelivered(params) {
 
   return this.fnpostInputParams(AppSettings.setdeliveryorderdeliveredUrl, params);
}


fnSetDeliveryOrderReceived(params) {
 
   return this.fnpostInputParams(AppSettings.setdeliveryorderreceivedUrl, params);
}

fnSetDeliveryOrderReadyToPick(params) {
 
   return this.fnpostInputParams(AppSettings.setdeliveryorderreadytopickUrl, params);
}

fnGetDeliveryOrderStatus(params) {
 
   return this.fnpostInputParams(AppSettings.getdeliveryorderstatusbyorderUrl, params);
}

   
fnSetOrderErpInfo(params) {
 
   return this.fnpostInputParams(AppSettings.setordererpinfoUrl, params);
}
   

//Offer

fnInsertOffer(params) {
 
 return this.fnpostInputParams(AppSettings.insertOfferUrl, params);
}

fnSetOffer(params) {
 
 return this.fnpostInputParams(AppSettings.setOfferUrl, params);
}


fnInsertOfferDetails(params) {
 
 return this.fnpostInputParams(AppSettings.insertOfferdetailsUrl, params);
}

fnDeleteOffer(params) {
 
 return this.fnpostInputParams(AppSettings.deleteOfferUrl, params);
}

getOfferListView(params){
 
 return this.fnpostInputParams(AppSettings.getOfferUrl, params);
}


getOfferbyOfferid(params) {
 
 return this.fnpostInputParams(AppSettings.getOfferByOfferidUrl, params);
}


fnCheckOffer(params) {
 
 return this.fnpostInputParams(AppSettings.checkOfferUrl, params);
}

fnCheckOfferProducts(params){
 
   return this.fnpostInputParams(AppSettings.checkOfferProductsUrl, params);
  }
  


//Wallet
fnInsertWalletAdmin(params) {     
 
 return this.fnpostInputParams(AppSettings.insertWalletAdminUrl, params);
}

fnSetWalletAdmin(params) {
 
 return this.fnpostInputParams(AppSettings.setWalletAdminUrl, params);
}


getWalletAdmin(params) {  
 
 return this.fnpostInputParams(AppSettings.getWalletAdminUrl, params);
}

fnDeleteWalletAdmin(params) {     
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.deleteWalletAdminUrl, params);
}

getWalletListView(params){
 
 return this.fnpostInputParams(AppSettings.getwalletUrl, params);
}

//Loyalty
fnInsertLoyaltyAdmin(params) {     
 
 return this.fnpostInputParams(AppSettings.insertLoyaltyAdminUrl, params);
}

fnSetLoyaltyAdmin(params) {
 
 return this.fnpostInputParams(AppSettings.setLoyaltyAdminUrl, params);
}


getLoyaltyAdmin(params) {  
 
 return this.fnpostInputParams(AppSettings.getLoyaltyAdminUrl, params);
}

fnDeleteLoyaltyAdmin(params) {     
const headers = new Headers({ 'Content-Type':  "application/JSON" });
return this.fnpostInputParams(AppSettings.deleteLoyaltyAdminUrl, params);
}

getLoyaltyListView(params){
 
 return this.fnpostInputParams(AppSettings.getloyaltyUrl, params);
}




//Settings

fnSetSettings(params) {
 
   return this.fnpostInputParams(AppSettings.setSettingsUrl, params);
  }
  
  
  getSettings(params) {  
   
   return this.fnpostInputParams(AppSettings.getSettingsUrl, params);
  }

  

  
//PriceChange

fnInsertPriceChange(params) {
 
   return this.fnpostInputParams(AppSettings.insertPriceChangeUrl, params);
  }
  
  fnSetPriceChange(params) {
   
   return this.fnpostInputParams(AppSettings.setPriceChangeUrl, params);
  }
  
  
  fnInsertPriceChangeDetails(params) {
   
   return this.fnpostInputParams(AppSettings.insertPriceChangedetailsUrl, params);
  }
  
  fnDeletePriceChange(params) {
   
   return this.fnpostInputParams(AppSettings.deletePriceChangeUrl, params);
  }
  
  getPriceChangeListView(params){
   
   return this.fnpostInputParams(AppSettings.getPriceChangeUrl, params);
  }
  
  
  getPriceChangebyPriceChangeid(params) {
   
   return this.fnpostInputParams(AppSettings.getPriceChangeByPriceChangeidUrl, params);
  }
  
 
  fnSetPriceChangePost(params) {
   
   return this.fnpostInputParams(AppSettings.setPriceChangePostUrl, params);
  }
   
fnInsertImport(params) {
 
   return this.fnpostInputParams(AppSettings.insertImportUrl, params);
  }
  

  

//Reason 
fnInsertReason(params) {     
 
   return this.fnpostInputParams(AppSettings.insertReasonUrl, params);
  }
  
  fnSetReason(params) {
   
   return this.fnpostInputParams(AppSettings.setReasonUrl, params);
  }
  
  
  getReason(params) {  
   
   return this.fnpostInputParams(AppSettings.getReasonUrl, params);
  }
  
  
  fnDeleteReason(params) {
  const headers = new Headers({ 'Content-Type':  "application/JSON" });
  return this.fnpostInputParams(AppSettings.deleteReasonUrl, params);
  }
  
  fnCheckReason(params) {     
  const headers = new Headers({ 'Content-Type':  "application/JSON" });
  return this.fnpostInputParams(AppSettings.checkReasonUrl, params);
  }

  
  //Refund
  getRefundByListview(params) {  
   
   return this.fnpostInputParams(AppSettings.getRefundByListviewUrl, params);
  }
  
  getRefund(params) {  
 
   return this.fnpostInputParams(AppSettings.getRefundUrl, params);
  }
  

  setRefundByRefundid(params) {  
 
   return this.fnpostInputParams(AppSettings.setRefundByrefundidUrl, params);
  }
  
   //Common 

   fnGetIpAddress(){
      this.http.get('https://jsonip.com')
      .subscribe( data => {        
        this.ipAddress = data.json()
       
      })

   }

   fnInsertActivity(data) {     

      var _orderid=0,_deliveryorderid=0;
      if(data.orderid!==undefined){
         _orderid=data.orderid;
      }
      if(data.deliveryorderid!==undefined){
         _deliveryorderid=data.deliveryorderid;
      }

      var dept_para={
         logtype:data.logtype,
         logat:data.logat,
         refnum:data.refnum,
         logdescr:data.logdescr,
         logby:data.logby,
         ipaddress:this.ipAddress.ip,
         devicename:this.deviceInfo.browser+" "+this.deviceInfo.os,
         orderid:_orderid,
         deliveryorderid:_deliveryorderid,
      };
 
      return this.fnpostInputParams(AppSettings.insertactivitylogUrl, dept_para);
   }


   
  getCartListByAdmin(params){
 
   return this.fnpostInputParams(AppSettings.getcartlistbyadminUrl, params);
  }


  
  getCartByCartid(params){
 
   return this.fnpostInputParams(AppSettings.getcartbycartidbyadminUrl, params);
  }

  //Report



  getReportActivityLog(params){
 
   return this.fnpostInputParams(AppSettings.getreportactivitylogUrl, params);
  }
  getReportSalesView(params){
 
   return this.fnpostInputParams(AppSettings.getreportsalesUrl, params);
  }


   //Mail

   fnSendMailByOrder(params){
 
   return this.fnpostInputParams(AppSettings.sendmailbyorderUrl, params);
  }


   //Dashboard

   fnGetDashboardAdmin(params){
 
      return this.fnpostInputParams(AppSettings.getdashboardadminUrl, params);
     }


  
   //Notification
   fnSendNotificationByOrder(params){
 
      return this.fnpostInputParams(AppSettings.sendnotificationbyorderUrl, params);
     }

     
   //PushNofication
   fnSendPushNotification(title:string="",content:string="",linkid:string="",target:string="",linkid1:string="") {

    var imageUrl="https://i2.wp.com/savethehighstreet.org/wp/wp-content/uploads/2017/03/general-enquiry-icon.png?ssl=1"
    imageUrl="";
    var gameUrl="";

    let url = 'https://fcm.googleapis.com/fcm/send';
    let body = 
     {
      "data":{
        "title":title,
        "content":content,
        "imageUrl":imageUrl,
        "gameUrl":gameUrl,
        "linkid":linkid,
        "linkid1":linkid1
      },
      "to":"/topics/"+target
     };
  
    let headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': 'key=AAAACwdsT7c:APA91bFVVE6fcIVaVmNC5wNQ9hFJHi4vnsmvbMC8hfstoUtwrK2RvyQhTPHnShM-xwQZ1vKU_Py8x1K8zMqA4SeJC78tid9AGavTBTNSTo9S-j5lqBkRicJO1IgeVnDAT4WauYHUVcAn'
    });
    let options = { headers: headers };
  
    this.http.post(url, body,options).subscribe(data => {
     // console.log(data);
    });
  


   

   }
  


   fnSendNotification(data,deviceColl) {

      
      var imageUrl=""      
      
      if(data.notificationimgpath!=""){
         imageUrl=data.notificationimgpath;       
      }

      if(data.customimgpath!=""){
         imageUrl=data.customimgpath;       
      }

      if(data.customerid>0){
         var target=[]; 
         
         
        
         for(let abo of deviceColl){
            target.push(abo.deviceid)
         }

        

         if(target.length>0){

            let url = 'https://fcm.googleapis.com/fcm/send';
            let body = 
             {
                "notification":{
                    "title":data.title,
                    "body":data.notificationmsg,
                    "image":imageUrl,              
                  },
                  "data": {
                     "image": imageUrl,         
                     // "click_action": "FLUTTER_NOTIFICATION_CLICK",        
                     // "linktype": data.linktype,               
                     // "linktargetid":data.linktargetid,               
                  },
                  "registration_ids":target
             };
          
            
            let headers = new Headers({
              'Content-Type': 'application/json',
              'Authorization': 'key='+data.notifiapi
            });
            let options = { headers: headers };    
            return this.http.post(url, body,options);
         }

      }
      else{
         let url = 'https://fcm.googleapis.com/fcm/send';
         let body = 
         {
            "notification":{
               "title":data.title,
               "body":data.notificationmsg,
               "image":imageUrl,              
               },
               "data": {
                  "image": imageUrl,       
                  // "click_action": "FLUTTER_NOTIFICATION_CLICK",        
                  // "linktype": data.linktype,               
                  // "linktargetid":data.linktargetid,   
               },
               "to":data.target
         };
      
         let headers = new Headers({
         'Content-Type': 'application/json',
         'Authorization': 'key='+data.notifiapi
         });
         let options = { headers: headers };    
         return this.http.post(url, body,options);
      }
    

   }



   
   fnSendNotificationCart(data) {

      
      var imageUrl=""      
      
     
      let url = 'https://fcm.googleapis.com/fcm/send';
      let body = 
      {
         "notification":{
            "title":data.title,
            "body":data.notificationmsg,
            "image":imageUrl,              
            },
            "data": {
               "image": imageUrl,         
            },
            "to":data.target
      };
   
      let headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': 'key='+data.notifiapi
      });
      let options = { headers: headers };    
      return this.http.post(url, body, options);
      

   }

   
  private fnExeception(err:HttpErrorResponse){
    if(err.error instanceof ErrorEvent){
        console.error('Client Side Error',err.error.message);
    }else{
         console.error('Server Side Error',err);
    }
    return throwError("This is problem with dept service--"+err.error.ExceptionMessage);
}


// "data":{
//    "title":data.title,
//    "content":data.notificationmsg,
//    "imageUrl":imageUrl,
//    "gameUrl":gameUrl,
//    "linkid":0,
//    "linkid1":0
//  },

}


