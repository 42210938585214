import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-addcontentmanagement',
  templateUrl: './addcontentmanagement.component.html',
  styleUrls: ['./addcontentmanagement.component.css']
})
export class AddcontentmanagementComponent implements OnInit {
//Mode Values
isaddmode:boolean=false;
iseditmode:boolean=false;
isdeletemode:boolean=false;
isviewmode:boolean=false;

//Save Validation
submitted:boolean;
successfully:boolean;


//Edit Get Values
contentColl:any=[];
contentid:number=0

contentform=new FormGroup({
  contenttype:new FormControl('',Validators.required),         
  value:new FormControl(''),  
  contentdisplayname:new FormControl(''),  
  linkurl:new FormControl(''),         
  contentid:new FormControl(0),  
  ismobile:new FormControl(false),  
  isweb:new FormControl(false),       
});

isloading:boolean=false;
isprivilage:boolean=false;  
showProgress:boolean=false;
formname:String="ContentManagement";

_routerSub = Subscription.EMPTY;
_routerparamsSub = Subscription.EMPTY;
_routerLoginSub = Subscription.EMPTY

constructor(private appService: DhukanDataService,private route: ActivatedRoute,
   public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
    this._routerSub = this.route.queryParams.subscribe(params => {
      if(params.id!==undefined){
        this.contentid = params.id
      }  
     });
     this.fnServiceChanges();

}


 
 fnServiceChanges(){
  this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}

ngOnInit() {
    try{
      if(this.loginService.fnCheckUser()){ 
        if(this.fnCheckUserPririvilage()){       
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
              
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}
ngOnDestroy(): void {
  if (this._routerSub !== undefined) {
    this._routerSub.unsubscribe();
  }
  if (this._routerparamsSub !== undefined) {
    this._routerparamsSub.unsubscribe();
  }

  if (this._routerLoginSub !== undefined) {
    this._routerLoginSub.unsubscribe();
  }
}
fnOnInitForm(){

   try{
       this.isaddmode=true;
       this.contentform.reset();
       this.fnInitializeContentFormGroup(); 

       if(this.contentid>0){
         this.fngetContent();
       }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}


fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.contentid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}

fnInitializeContentFormGroup(){
   this.contentform.setValue({
    contenttype:'',
    contentid:0,      
     value:'',
     contentdisplayname:'',
     linkurl:'',
     ismobile:false,
     isweb:false,
   })
}

//Get 
fngetContent() {        
     var data = {
       'contentid':this.contentid,            
     }
     this.appService.getContentmanagement(data)
     .subscribe(
       (res)=>{                  
         this.contentColl=res.json().result;
        
         var editdata = {
           'contentid':this.contentColl[0].contentid,
           'contenttype':this.contentColl[0].contenttype, 
           'value':this.contentColl[0].value, 
           'contentdisplayname':this.contentColl[0].contentdisplayname, 
           'linkurl':this.contentColl[0].linkurl, 
           ismobile:this.contentColl[0].ismobile, 
           isweb:this.contentColl[0].isweb,   
         }
         this.fnEditMode(editdata);
     },
     (err)=>{          
       this.toastr.error('Error', err);
     })
}

//Save Btn Click
fnSaveBtnClick(){
   try{
     this.submitted=true;
     this.successfully=false;
     //Validate
     if(this.contentform.valid){
         //Check
         var data = {
               'contentid': this.contentid,       
               'contenttype':this.contentform.get('contenttype').value,            
               'value':this.contentform.get('value').value,
               'contentdisplayname':this.contentform.get('contentdisplayname').value,
               'linkurl':this.contentform.get('linkurl').value,
               ismobile:this.contentform.get('ismobile').value,
               isweb:this.contentform.get('isweb').value,  
         }
        
         if (this.contentform.get('contentid').value>0){
              //Update Department
              this.fnSetContentmanagement(data);
        }
                  
              
        
      }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}


//Set
fnSetContentmanagement(data){    

this.appService.fnSetContentmanagement(data).subscribe(
 (res)=>{
 if(res.json().result!=""){
     this.toastr.success('', "The content has been updated successfully.");

     this.fnInsertActivityLog('EDIT',this.contentid,data.contentdisplayname);


     this.fnCloseContent();        
 }},
 (err)=>{
   this.toastr.error('Error', err);
 });

}

//Close
fnCloseContent(){
try{
  this.router.navigate(['/Content']);
}catch(ex){
   this.fnShowErrorMsg(ex);
}
}

private fnEditMode(deptBO){

 this.contentform.setValue({
   contenttype:deptBO.contenttype,
   contentid:deptBO.contentid,
   value:deptBO.value,   
   contentdisplayname:deptBO.contentdisplayname, 
   linkurl:deptBO.linkurl, 
   ismobile:deptBO.ismobile, 
   isweb:deptBO.isweb, 
 });

this.isaddmode=false;
this.iseditmode=true;
this.isdeletemode=false;
this.isviewmode=false;
}


fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}


private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


private  fnShowErrorMsg(ex){
this.toastr.warning('Internal Error', ex);       
}




}
