import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductfindComponent } from '../../productfind/productfind.component';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-addbanner',
  templateUrl: './addbanner.component.html',
  styleUrls: ['./addbanner.component.css']
})
export class AddbannerComponent implements OnInit {

 
  //Mode Values
  isaddmode:boolean=false;
  iseditmode:boolean=false;
  isdeletemode:boolean=false;
  isviewmode:boolean=false;

  //Save Validation
  submitted:boolean;
  successfully:boolean;

  //set image
  image;
  strImage: any;
  mobileimage;
  mobilestrImage: any;

  //Edit Get Values
  bannerColl:any=[];
  bannerid:number=0
  bannertypeColl:any=[];
  linktypeColl:any=[];
  parabannertypeid=0

  deptColl:any=[];
  categoryColl:any=[];
  subcategoryColl:any=[];
  brandColl:any=[];
  vendorColl:any=[];
  pageColl:any=[];
  offerColl:any=[];
  contentColl:any=[];
  productgroupColl: any = [];
  
  picurl:string="";
  mobilepicurl:string="";

  sectionname:string="";

  bannerform=new FormGroup({
      bannerid:new FormControl(0),
      bannertypeid:new FormControl(0,Validators.required),
      bannername:new FormControl('',Validators.required),         
      bannerwebsitepic:new FormControl(''),
      bannermobilepic:new FormControl(''),
      linktype:new FormControl(''), 
      linktargetid:new FormControl(null), 
      linkurl:new FormControl(''),       
      linkhtml:new FormControl(''),       
      websiteimage:new FormControl(null),        
      mobileimage:new FormControl(null),        
      inactive:new FormControl(false),     
      deptid:new FormControl(null), 
      categoryid:new FormControl(null), 
      subcategoryid:new FormControl(null), 
      brandid:new FormControl(null), 
      vendorid:new FormControl(null), 
      pageid:new FormControl(null), 
    offerid: new FormControl(null), 
    productgroupid:new FormControl(null),             
      contentid:new FormControl(null), 
      productid:new FormControl(null),             
      productname:new FormControl(''), 
      bannernameadd:new FormControl(''), 
      orderby:new FormControl(null), 
  });

  isloading:boolean=false;
  isprivilage:boolean=false;  
showProgress:boolean=false;
  formname:String="Banner";

  _routerSub = Subscription.EMPTY;
_routerparamsSub = Subscription.EMPTY;
_routerLoginSub = Subscription.EMPTY

constructor(private appService: DhukanDataService,private route: ActivatedRoute,
     public router: Router,private toastr: ToastrService, private productFindService: NgbModal,private loginService:LoginDataService) { 
      this._routerSub = this.route.queryParams.subscribe(params => {
          if(params.id!==undefined){
            this.bannerid = params.id
          }  

          if(params.bannertypeid!==undefined){
            this.parabannertypeid = params.bannertypeid;                  
          }     
          
          this.sectionname = params.section      
          
       });

       this.fnServiceChanges();
}


fnServiceChanges(){
  this._routerLoginSub =this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}



 

ngOnInit() {
  try{
    if(this.loginService.fnCheckUser()){ 
      if(this.fnCheckUserPririvilage()){       
        this.isloading=true;
        this.fnOnInitForm();
      }
      else{
        this.fnUserFailedForm();
      }
    }
    else{
      this.loginService.fnGetUserRoleList();
    }
            
  }catch(ex){
    this.fnShowErrorMsg(ex);
  }
}
ngOnDestroy(): void {
  if (this._routerSub !== undefined) {
    this._routerSub.unsubscribe();
  }

  if (this._routerLoginSub !== undefined) {
    this._routerLoginSub.unsubscribe();
  }
}
fnOnInitForm(){
     try{
         this.isaddmode=true;
         this.bannerform.reset();
         this.fnInitializeBannerFormGroup(); 

         if(this.bannerid>0){
           this.fngetBanner();
         }
         this.fngetBannerType();
         this.fngetLinkType();
       

     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
}



fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.bannerid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}


fnInitializeBannerFormGroup(){
     this.bannerform.setValue({
      bannerid:0,
      bannertypeid:null,
      bannername:'',         
      bannerwebsitepic:'',
      bannermobilepic:'',
      linktype:'', 
      linktargetid:null, 
      linkurl:'',  
      linkhtml:'',     
      websiteimage:null,        
      mobileimage:null,        
      inactive:false, 
      deptid:null, 
      categoryid:null, 
      subcategoryid:null, 
      brandid:null, 
      vendorid:null,
      pageid:null,
      offerid:null,
      contentid:null,
      productid:null,  
      productname:'',
      bannernameadd:'',   
       orderby: null,
       productgroupid:null
     })
}

fngetBannerType() {        
  var data = {
    'bannertypeid':0,            
  }
  this.appService.getBannerType(data)
  .subscribe(
    (res)=>{                  
      this.bannertypeColl=res.json().result;   

      
      if(this.bannerid===0){
        let id=Number(this.parabannertypeid);
        this.bannerform.patchValue({bannertypeid:id});  
      }      

  },
  (err)=>{          
    this.toastr.error('Error', err);
  })
}

fngetLinkType() { 
  let _type=[
    {display:"Department",name:"DEPARTMENT"},
    {display:"Category",name:"CATEGORY"},
    {display:"SubCategory",name:"SUBCATEGORY"},
    {display:"Brand",name:"BRAND"},
    {display:"Vendor",name:"VENDOR"},
    {display:"Page",name:"PAGE"},
    {display:"Product",name:"PRODUCT"},
    { display: "Offer", name: "OFFER" },
    {display:"ProductGroup",name:"PRODUCTGROUP"},
    {display:"Html",name:"HTML"},
    {display:"WebUrl",name:"WEBURL"},
    {display:"Offers Page",name:"OFFERS"},
    {display:"Newarrivals Page",name:"NEWARRIVALS"},
    { display: "Dealoftheday Page", name: "DEALOFTHEDAY" },
    {display:"Cart Page",name:"CART"},
  ]

  this.linktypeColl=_type;
}

fngetDepartment() {        
  var data = {
    'deptid':0,            
  }
  this.appService.getDepartment(data)
  .subscribe(
    (res)=>{                  
      this.deptColl=res.json().result;     
    },
  (err)=>{          
    this.toastr.error('Error', err);
  })
}

fngetCategory() {        
 
  var data = {
    'categoryid':0,        
    'deptid':0,     
  }


    this.appService.getCategory(data)
    .subscribe(
      (res)=>{                  
        this.categoryColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}

fngetSubCategory() {        
 
  var data = {
    'categoryid':0,        
    'subcategoryid':0,     
  }


    this.appService.getSubCategory(data)
    .subscribe(
      (res)=>{                  
        this.subcategoryColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}

fngetBrand() {        
 
  var data = {
    'brandid':0            
  }


    this.appService.getBrand(data)
    .subscribe(
      (res)=>{                  
        this.brandColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}

fngetVendor() {        
 
  var data = {
    'vendorid':0            
  }

    this.appService.getVendor(data)
    .subscribe(
      (res)=>{                  
        this.vendorColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}


  
fngetProductGroup() {        
 
  var data = {
    'productgroupid':0            
  }


    this.appService.getProductGroup(data)
    .subscribe(
      (res)=>{                  
        this.productgroupColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}

  
  
fngetPage() {        
 
  var data = {
    'pageid':0            
  }


    this.appService.getPageByListView(data)
    .subscribe(
      (res)=>{                  
        this.pageColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}

fngetOffer() {        
 
  var data = {
    'offerid':0            
  }


    this.appService.getOfferListView(data)
    .subscribe(
      (res)=>{                  
        this.offerColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}

fngetContent() {        
 
  var data = {
    'contentid':0            
  }


    this.appService.getContentmanagement(data)
    .subscribe(
      (res)=>{                  
        this.contentColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}

fnLinkTypeChange(){
  let type=this.bannerform.get('linktype').value;
  if(type==="DEPARTMENT"){
    this.fngetDepartment();
  }
  else if(type==="CATEGORY"){
    this.fngetCategory();
  }
  else if(type==="SUBCATEGORY"){
    this.fngetSubCategory();
  }
  else if(type==="BRAND"){
    this.fngetBrand();
  }
  else if(type==="VENDOR"){
    this.fngetVendor();
  }
  else if(type==="OFFER"){
    this.fngetOffer();
  }
  else if(type==="PRODUCTGROUP"){
    this.fngetProductGroup();
  }
  else if(type==="HTML"){
    this.fngetContent();
  }
  else if(type==="PAGE"){
    this.fngetPage();
  }
}

fnSelectProduct(){
 
  const modalRef = this.productFindService.open(ProductfindComponent ,{ size: <any>'xl' });
  modalRef.componentInstance.doctype ="SELECT PRODUCT" ;

  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

    for(let wbo of receivedEntry){

      this.bannerform.patchValue({ 
        productid:wbo.skuid,        
        productname:wbo.productname,
      });

    }
  });

}

//Get 
fngetBanner() {        
       var data = {
         'bannerid':this.bannerid,            
         'bannertypeid':0,            
       }
       this.appService.getBanner(data)
       .subscribe(
         (res)=>{               
           
           this.bannerColl=res.json().result;           
           var editdata = {
             'bannerid':this.bannerColl[0].bannerid,
             'bannertypeid':this.bannerColl[0].bannertypeid, 
             'bannername':this.bannerColl[0].bannername, 
             'linktype':this.bannerColl[0].linktype, 
             'linktargetid':this.bannerColl[0].linktargetid, 
             'linkurl':this.bannerColl[0].linkurl, 
             'linkhtml':this.bannerColl[0].linkhtml,
             'inactive':this.bannerColl[0].inactive,
             'bannerwebsitepic':this.bannerColl[0].bannerwebsitepic,                                    
             'bannermobilepic':this.bannerColl[0].bannermobilepic,    
             'productname':this.bannerColl[0].productname,       
             'bannernameadd':this.bannerColl[0].bannernameadd,
             'orderby':this.bannerColl[0].orderby                             
           }
           this.fnEditMode(editdata);
       },
       (err)=>{          
         this.toastr.error('Error', err);
       })
}

//Save Btn Click
fnSaveBtnClick(){
     try{
       this.submitted=true;
       this.successfully=false;
       //Validate
       if(this.bannerform.valid){
         
           //Check
           var data = {
                 'bannerid': this.bannerid,       
                 'bannertypeid':this.bannerform.get('bannertypeid').value,    
                 'bannername':this.bannerform.get('bannername').value,    
                 'bannernameadd':this.bannerform.get('bannernameadd').value,    
                 'orderby':this.bannerform.get('orderby').value,    
                 'linktype':this.bannerform.get('linktype').value,    
                 'linktargetid':this.fnGetLinkTypeId(this.bannerform.get('linktype').value),            
                 'linkurl':this.bannerform.get('linkurl').value,            
                 'linkhtml':this.bannerform.get('linkhtml').value,   
                 'inactive': 0,  
                 'createdby': this.loginService.fnGetModUserId(),    
                 'modifiedby':this.loginService.fnGetModUserId(),    
                 'websiteimage': this.strImage,
                 'bannerwebsitepic': this.picurl,      
                 'mobileimage': this.mobilestrImage,
                 'bannermobilepic': this.mobilepicurl,                 
           }
        
           console.log(data)
           this.appService.fnCheckBanner(data).subscribe(
             (res)=>{
                 var _checkresult=res.json().result[0].checkfield;
               
                 if(_checkresult==="done"){
                       if (this.bannerform.get('bannerid').value>0){
                         //Update Department
                         this.fnSetBanner(data);
                       }
                       else{
                         //Insert Department
                         this.fnInsertBanner(data);
                       }
                 }
                 else{        
                       if(_checkresult==="name"){
                         this.bannerform.controls['bannername'].setErrors({'notfound': true});
                       }
                 }
           },
           (err)=>{
               this.toastr.error('Error', err);           
           });
        }
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
}

//Insert
fnInsertBanner(data){    
    this.fnShowProgress();
     this.appService.fnInsertBanner(data).subscribe(
       (res)=>{
        this.fnHideProgress();
       if(res.json().result!=""){         
         this.toastr.success('', "The new banner has been added successfully.Insert Successfully");

         this.fnInsertActivityLog('INSERT',0,data.bannername);

         this.fnCloseBanner();
       }},
       (err)=>{
        this.fnHideProgress();
         this.toastr.error('Error', err);
       });

}

//Set
fnSetBanner(data){    
this.fnShowProgress();
 this.appService.fnSetBanner(data).subscribe(
   (res)=>{
    this.fnHideProgress();
   if(res.json().result!=""){
       this.toastr.success('', "The banner has been updated successfully.");

       this.fnInsertActivityLog('EDIT',this.bannerid,data.bannername);

       this.fnCloseBanner();        
   }},
   (err)=>{
    this.fnHideProgress();
     this.toastr.error('Error', err);
   });

}

//Close
fnCloseBanner(){
 try{
 
    if(this.sectionname===undefined){
      let navigationExtras: NavigationExtras = {
        queryParams: {    
          'bannertypeid':this.parabannertypeid        
        }
      }
      
      this.router.navigate(['/Banner'], navigationExtras);
    }
    else{
      let navigationExtras: NavigationExtras = {
        queryParams: {    
          'sectionname':this.sectionname        
        }
      }
    
      this.router.navigate(['/ProductSection'], navigationExtras);
    }


 }catch(ex){
     this.fnShowErrorMsg(ex);
 }
}

private fnEditMode(deptBO){
  

   this.bannerform.setValue({
    bannerid:deptBO.bannerid,
    bannertypeid:deptBO.bannertypeid,
    bannername:deptBO.bannername,         
    bannerwebsitepic:deptBO.bannerwebsitepic,
    bannermobilepic:deptBO.bannermobilepic,
    linktype:deptBO.linktype, 
    linktargetid:deptBO.linktargetid, 
    linkurl:deptBO.linkurl,       
    linkhtml:deptBO.linkhtml,         
    websiteimage:null,        
    mobileimage:null,        
    inactive:false,   
    deptid:null, 
    categoryid:null, 
    subcategoryid:null, 
    brandid:null,  
    vendorid:null, 
    pageid:null, 
    contentid:null, 
    offerid:null, 
     productid: null,  
     productgroupid:null,
    productname:deptBO.productname,   
    bannernameadd:deptBO.bannernameadd,
    orderby:deptBO.orderby
  });
   this.picurl =deptBO.bannerwebsitepic;   
   this.mobilepicurl =deptBO.bannermobilepic;

 this.fnLinkTypeChange();

 let type=this.bannerform.get('linktype').value;
  if(type==="DEPARTMENT"){
    this.bannerform.patchValue({deptid:deptBO.linktargetid});
  }
  else if(type==="CATEGORY"){
    this.bannerform.patchValue({categoryid:deptBO.linktargetid});
  }
  else if(type==="SUBCATEGORY"){
    this.bannerform.patchValue({subcategoryid:deptBO.linktargetid});
  } 
  else if(type==="BRAND"){
    this.bannerform.patchValue({brandid:deptBO.linktargetid});
  } 
  else if(type==="VENDOR"){
    this.bannerform.patchValue({vendorid:deptBO.linktargetid});
  } 
  else if(type==="PAGE"){
    this.bannerform.patchValue({pageid:deptBO.linktargetid});
  } 
  else if(type==="PRODUCTGROUP"){
    this.bannerform.patchValue({productgroupid:deptBO.linktargetid});
  } 
  else if(type==="HTML"){
    this.bannerform.patchValue({contentid:deptBO.linktargetid});
  } 
  else if(type==="OFFER"){
    this.bannerform.patchValue({offerid:deptBO.linktargetid});
  } 
  else if(type==="PRODUCT"){
    this.bannerform.patchValue({productid:deptBO.linktargetid});
  } 

 this.isaddmode=false;
 this.iseditmode=true;
 this.isdeletemode=false;
 this.isviewmode=false;
}

fnGetLinkTypeId(type){
  if(type==="DEPARTMENT"){
    return this.bannerform.get('deptid').value;
  }
  else if(type==="CATEGORY"){
    return this.bannerform.get('categoryid').value;
  }
  else if(type==="SUBCATEGORY"){
    return this.bannerform.get('subcategoryid').value;
  }
  else if(type==="BRAND"){
    return this.bannerform.get('brandid').value;
  }
  else if(type==="PRODUCT"){
    return this.bannerform.get('productid').value;
  } 
  else if(type==="PRODUCTGROUP"){
    return this.bannerform.get('productgroupid').value;
  } 
  else if(type==="VENDOR"){
    return this.bannerform.get('vendorid').value;
  } 
  else if(type==="PAGE"){
    return this.bannerform.get('pageid').value;
  } 
  else if(type==="OFFER"){
    return this.bannerform.get('offerid').value;
  } 
  else if(type==="HTML"){
    return this.bannerform.get('contentid').value;
  } 
  else {
    return null;
  }
}

fnGetLinkTypeUrl(type){
  let para="";

  if(type==="DEPARTMENT"){
    for(let wbo of this.deptColl){
      if(this.bannerform.get('deptid').value===wbo.deptid){
        para=wbo.deptid+"-"+this.fnRemoveSpecialCharater(wbo.deptname);        
        return para        
      }
    }
  }
  else if(type==="CATEGORY"){
    for(let wbo of this.categoryColl){
      if(this.bannerform.get('categoryid').value===wbo.categoryid){
        para=wbo.deptid+"-"+this.fnRemoveSpecialCharater(wbo.deptname);        
        para=para+"/"+wbo.categoryid+"-"+this.fnRemoveSpecialCharater(wbo.categoryname);        
        return para        
      }
    }
  }
  else if(type==="SUBCATEGORY"){
    for(let wbo of this.subcategoryColl){
      if(this.bannerform.get('subcategoryid').value===wbo.subcategoryid){
        para=wbo.deptid+"-"+this.fnRemoveSpecialCharater(wbo.deptname);        
        para=para+"/"+wbo.categoryid+"-"+this.fnRemoveSpecialCharater(wbo.categoryname);        
        para=para+"/"+wbo.subcategoryid+"-"+this.fnRemoveSpecialCharater(wbo.subcategoryname);        
        return para        
      }
    }
  } 
  else if(type==="BRAND"){
    for(let wbo of this.brandColl){
      if(this.bannerform.get('brandid').value===wbo.brandid){
        para=wbo.brandid+"-"+this.fnRemoveSpecialCharater(wbo.brandname);        
        return para        
      }
    }
  }
  else if(type==="PRODUCT"){

    para=this.bannerform.get('productid').value+"-"+this.fnRemoveSpecialCharater(this.bannerform.get('productname').value);        
    return para   
  } 
  else {
    return para;
  }
}

//Image Import
changeListener($event): void {
    try{
      this.readThis($event.target);
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}

readThis(inputValue: any): void {

    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
        this.image = myReader.result;
        this.strImage = this.image.split(',')[1];
    }
    myReader.readAsDataURL(file);
}

removeimage(){
      this.image =null;
      this.strImage =null;
      this.picurl="";
}



//Mobile Image Import
mobilechangeListener($event): void {
  try{
    this.mobilereadThis($event.target);
  }catch(ex){
    this.fnShowErrorMsg(ex);
  }
}

mobilereadThis(inputValue: any): void {

  var file: File = inputValue.files[0];
  var myReader: FileReader = new FileReader();

  myReader.onloadend = (e) => {
      this.mobileimage = myReader.result;
      this.mobilestrImage = this.mobileimage.split(',')[1];
  }
  myReader.readAsDataURL(file);
}

mobileremoveimage(){
    this.mobileimage =null;
    this.mobilestrImage =null;
    this.mobilepicurl="";
}


private fnRemoveSpecialCharater(str){
  str = str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
  str = str.toString().replace(/ /g, "");
  return str;
}


fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}

private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


private  fnShowErrorMsg(ex){
    this.toastr.warning('Internal Error', ex);       
}
}

