import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router } from '@angular/router';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import * as moment from 'moment';

@Component({
  selector: 'app-activitylog',
  templateUrl: './activitylog.component.html',
  styleUrls: ['./activitylog.component.css']
})
export class ActivitylogComponent implements OnInit {

  reportColl:any=[];
  

  isadd:boolean=false;
  isedit:boolean=false;
  isdelete:boolean=false;
  isview:boolean=false;
  isprint:boolean=false;
   isexport:boolean=false; 
  
  isloading:boolean=false;
  isprivilage:boolean=false;  
showProgress:boolean=false;
  formname:String="ActivityLog";

  fromdate:any=null;
  todate: any = null;
  

  
  private gridApi;
  private gridColumnApi;

  columnDefs = [
    { headerName: 'Form', field: 'formname', sortable: true, filter: true},
    { headerName: 'Action', field: 'formaction', sortable: true, filter: true },
    { headerName: 'User', field: 'logbyname', sortable: true, filter: true },
    { headerName: 'Logon', field: 'logon', sortable: true,  filter:true, valueFormatter: this.fnMomentDateTimeFormatter },
    { headerName: 'Reference', field: 'refnum', sortable: true, filter: true },
    { headerName: 'Desc', field: 'logdescr', sortable: true, filter: true },
    { headerName: 'IpAddress', field: 'ipaddress', sortable: true, filter: true },
          
  ]

  gridOptions = {
    rowSelection: 'single',
    rowHeight: 40,
    columnDefs: this.columnDefs,
    defaultColDef: {
      resizable: true,
      flex: 1,
      width: 100,
      minWidth: 100,
      enableValue: false,
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: true,
    },
  };

  _routerSub = Subscription.EMPTY;
  _routerLoginSub = Subscription.EMPTY

  constructor( private appService: DhukanDataService,public router: Router,
    private confirmationDialogService: ConfirmationDialogService,private datePipe: DatePipe,
    private toastr: ToastrService,private loginService:LoginDataService)
     {     this.fnServiceChanges(); }

 //User Check
 fnServiceChanges(){
  this._routerLoginSub =  this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){                
            this.fnCheckPrivilage();
            if(this.isview){
              this.isloading=true;
              this.fnOnInitForm();
            }
            else{
              this.fnUserFailedForm();
            }    
        }
        else{
          this.fnUserFailedForm();
        }
  });
  }  


  ngOnInit() {
    try{
      
      this.fromdate=new Date();
      this.todate=new Date();
      
      
      if(this.loginService.fnCheckUser()){         
        this.fnCheckPrivilage();
        if(this.isview){
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }          
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
      
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm(){
    this.fngetReportListView();     
  }

  fnCheckPrivilage(){
    this.isadd=this.loginService.fnCheckUserRoleAction(this.formname,'CREATE');
    this.isedit=this.loginService.fnCheckUserRoleAction(this.formname,'ALTER');
    this.isdelete=this.loginService.fnCheckUserRoleAction(this.formname,'DELETE');
    this.isview=this.loginService.fnCheckUserRoleAction(this.formname,'VIEW');
    this.isprint=this.loginService.fnCheckUserRoleAction(this.formname,'PRINT');
    this.isexport=this.loginService.fnCheckUserRoleAction(this.formname,'EXPORT');
  }

  fnUserFailedForm(){
    this.isprivilage=true;
  }


  ngAfterViewInit(): void {
    
  }

  ngOnDestroy(): void {
   
    if (this._routerSub !== undefined) {
      this._routerSub.unsubscribe();
    }
  
    if (this._routerLoginSub !== undefined) {
      this._routerLoginSub.unsubscribe();
    }
  }
  
  
  
  
  //Grid
  fnGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;   
  }

  

  fnActionExportClick()  {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'activitylist',      
    };
    this.gridApi.exportDataAsCsv(params);
  }


  fngetReportListView() {   
    if(this.showProgress){
      return;
    }

    this.fnShowProgress();
      var data = {
            'fromdate':this.fnFormatDatetime(this.fromdate), 
            'todate':this.fnFormatDatetime(this.todate), 
            'formname': null,
            'userid': null,
            'modifiedby': this.loginService.fnGetModUserId(),
      }
      this.appService.getReportActivityLog(data)
          .subscribe(
            (res)=>{      
              this.fnHideProgress();                   
              if(res.json().status==200){                          
                this.reportColl=res.json().result;
                this.fnSetDataTable();
             
              }
              else if(res.json().status==400){                          
                this.toastr.error('Error', res.json().err_field);
              }                          
          },
            (err)=>{          
              this.fnHideProgress();       
              this.toastr.error('Error', err);
            })
  }

 

  private fnSetDataTable(){
    

    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  
  }

  
fnFormatDatetime(dt){
  if(dt){
    if(dt===""){
      return null
    }
    else{
      return this.datePipe.transform(dt,"yyyy-MM-dd")  
    }
    
    }else {
    return null
  }
  
}

fnConvertDatetime(dt){
  if(dt){
    if(dt===""){
      return null
    }
    else{
      

      var dateParts = dt.split("-"); 

       var sdate =new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]+1); 

       sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());

       
       
       return sdate;
    }
    
    }else {
    return null
  }
 
}


  
fnMomentDateFormatter(params) {
  return moment(params.value).utcOffset('+02:30',false).format('DD-MM-YYYY');//hh:mm a  
}

fnMomentDateTimeFormatter(params) {
  return moment(params.value).utcOffset('+02:30',false).format('DD-MM-YYYY hh:mm a');//
}
  
private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


  private  fnShowErrorMsg(ex){
    this.toastr.warning('Internal Error', ex);       
  }




}
