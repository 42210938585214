import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-addbannertype',
  templateUrl: './addbannertype.component.html',
  styleUrls: ['./addbannertype.component.css']
})
export class AddbannertypeComponent implements OnInit {


  //Mode Values
  isaddmode:boolean=false;
  iseditmode:boolean=false;
  isdeletemode:boolean=false;
  isviewmode:boolean=false;

  //Save Validation
  submitted:boolean;
  successfully:boolean;

  //set image
  image;
  strImage: any;

  //Edit Get Values
  bannertypeColl:any=[];
  bannertypeid:number=0
  picurl:string="";
  picname:string="";

  bannertypeform=new FormGroup({
  bannertype:new FormControl('',Validators.required),         
  bannertypehead:new FormControl('',Validators.required),
  websitelayout:new FormControl(0), 
  mobilelayout:new FormControl(0), 
  bannertypeid:new FormControl(0),
  bannertypeheadpic:new FormControl(''), 
  headimage:new FormControl(null),        
   inactive:new FormControl(false),     
  });

  _routerSub = Subscription.EMPTY;
  _routerLoginSub = Subscription.EMPTY
  
constructor(private appService: DhukanDataService,private route: ActivatedRoute,
     public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
      this._routerSub = this.route.queryParams.subscribe(params => {
        if(params.id!==undefined){
          this.bannertypeid = params.id
        }  

       });
}
 
ngOnInit() {
     try{
         this.isaddmode=true;
         this.bannertypeform.reset();
         this.fnInitializeBannerTypeFormGroup(); 

         if(this.bannertypeid>0){
           this.fngetBannerType();
         }
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
}

fnInitializeBannerTypeFormGroup(){
     this.bannertypeform.setValue({
      bannertype:'',
      bannertypehead:'',
      websitelayout:0,
      mobilelayout:0,
      bannertypeid:0,
      bannertypeheadpic:'',
      headimage:null,
       inactive:false,      
     })
}

//Get 
fngetBannerType() {        
       var data = {
         'bannertypeid':this.bannertypeid,            
       }
       this.appService.getBannerType(data)
       .subscribe(
         (res)=>{                  
           this.bannertypeColl=res.json().result;
           var editdata = {
             'bannertypeid':this.bannertypeColl[0].bannertypeid,
             'bannertype':this.bannertypeColl[0].bannertype, 
             'bannertypehead':this.bannertypeColl[0].bannertypehead, 
             'bannertypeheadpic':this.bannertypeColl[0].bannertypeheadpic, 
             'websitelayout':this.bannertypeColl[0].websitelayout, 
             'mobilelayout':this.bannertypeColl[0].mobilelayout, 
             'inactive':this.bannertypeColl[0].inactive,
             'picurl':this.bannertypeColl[0].picurl,                                    
           }
           this.fnEditMode(editdata);
       },
       (err)=>{          
         this.toastr.error('Error', err);
       })
}

//Save Btn Click
fnSaveBtnClick(){
     try{
       this.submitted=true;
       this.successfully=false;
       //Validate
       if(this.bannertypeform.valid){
           //Check
           var data = {
                 'bannertypeid': this.bannertypeid,       
                 'bannertype':this.bannertypeform.get('bannertype').value,    
                 'bannertypehead':this.bannertypeform.get('bannertypehead').value,    
                 'websitelayout':this.bannertypeform.get('websitelayout').value,    
                 'mobilelayout':this.bannertypeform.get('mobilelayout').value,            
                 'inactive': 0,  
                 'createdby': this.loginService.fnGetModUserId(),    
                 'modifiedby':this.loginService.fnGetModUserId(),    
                 'headimage': this.strImage,
                 'bannertypeheadpic': this.picurl,                 
           }
           this.appService.fnCheckBannerType(data).subscribe(
             (res)=>{
                 var _checkresult=res.json().result[0].checkfield;
               
                 if(_checkresult==="done"){
                       if (this.bannertypeform.get('bannertypeid').value>0){
                         //Update Department
                         this.fnSetBannerType(data);
                       }
                       else{
                         //Insert Department
                         this.fnInsertBannerType(data);
                       }
                 }
                 else{        
                       if(_checkresult==="name"){
                         this.bannertypeform.controls['bannertypehead'].setErrors({'notfound': true});
                       }
                 }
           },
           (err)=>{
               this.toastr.error('Error', err);           
           });
        }
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
}

//Insert
fnInsertBannerType(data){    
   
     this.appService.fnInsertBannerType(data).subscribe(
       (res)=>{
       if(res.json().result!=""){
         this.toastr.success('', "The new bannertype has been added successfully.Insert Successfully");
         this.fnCloseBannerType();
       }},
       (err)=>{
         this.toastr.error('Error', err);
       });

}

//Set
fnSetBannerType(data){    

 this.appService.fnSetBannerType(data).subscribe(
   (res)=>{
   if(res.json().result!=""){
       this.toastr.success('', "The bannertype has been updated successfully.");
       this.fnCloseBannerType();        
   }},
   (err)=>{
     this.toastr.error('Error', err);
   });

}

//Close
fnCloseBannerType(){
 try{
    this.router.navigate(['/BannerType']);
 }catch(ex){
     this.fnShowErrorMsg(ex);
 }
}

private fnEditMode(deptBO){
   this.bannertypeform.setValue({
    bannertype:deptBO.bannertype,
    bannertypehead:deptBO.bannertypehead,
    websitelayout:deptBO.websitelayout,
    mobilelayout:deptBO.mobilelayout,
    bannertypeid:deptBO.bannertypeid,
    bannertypeheadpic:deptBO.bannertypeheadpic,
    inactive:deptBO.inactive, 
    headimage:null,     
  });
   this.picurl =deptBO.bannertypeheadpic;   

 this.isaddmode=false;
 this.iseditmode=true;
 this.isdeletemode=false;
 this.isviewmode=false;
}

//Image Import
changeListener($event): void {
try{
   this.readThis($event.target);
 }catch(ex){
   this.fnShowErrorMsg(ex);
 }
}

readThis(inputValue: any): void {

 var file: File = inputValue.files[0];
 var myReader: FileReader = new FileReader();

 myReader.onloadend = (e) => {
     this.image = myReader.result;
     this.strImage = this.image.split(',')[1];
 }
 myReader.readAsDataURL(file);
}

removeimage(){
 this.image =null;
 this.strImage =null;
 this.picurl="";
}

private  fnShowErrorMsg(ex){
 this.toastr.warning('Internal Error', ex);       
}

}
