import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { DatePipe } from '@angular/common';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ProductfindComponent } from '../../productfind/productfind.component';
import * as XLSX from 'xlsx';
import { Subscription } from 'rxjs';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-addpricechange',
  templateUrl: './addpricechange.component.html',
  styleUrls: ['./addpricechange.component.css']
})
export class AddpricechangeComponent implements OnInit {


  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;

  pricechangedetailsColl: any = [];

  pricechangedetailsnotmatchColl: any = [];

  warehouseColl: any = [];

 changetypeColl: any = [];

  //Edit Get Values
  pricechangeBO: any = {};
  pricechangeColl: any = [];
  pricechangeid: number = 0




  pricechangeform = new FormGroup({
    pricechangeno: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    pricechangedate: new FormControl(null, Validators.required),
    syncdate: new FormControl(null, Validators.required),
    warehouseid: new FormControl(null, Validators.required),
    changetype: new FormControl(null, Validators.required),
    pricechangeid: new FormControl(0),
    inactive: new FormControl(false),
  });
  dtOptions: { searching: boolean; pageLength: number; serverSide: boolean; processing: boolean; dom: string; columns: { title: string; }[]; order: (string | number)[]; scrollX: boolean; scrollCollapse: boolean; };

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "PriceChange";


  filterby: String = "MATCH";
  isimport: boolean = false;


  private gridApi;
  private gridColumnApi;

  columnDefs = [
    { headerName: 'ProductName', field: 'productname', editable: false, sortable: true, filter: true },
    { headerName: 'Sku', field: 'sku', sortable: true, editable: false, filter: true },
    { headerName: 'Old Price', field: 'oldprice', editable: false, sortable: true, filter: true },
    { headerName: 'New Price', field: 'newprice', editable: true, sortable: true, filter: true },
    { headerName: 'Stock', field: 'stock', editable: false, sortable: true, filter: true },
  ]

  gridOptions = {
    rowSelection: 'single',
    rowHeight: 40,
    columnDefs: this.columnDefs,
    defaultColDef: {
      resizable: true,
      flex: 1,
      width: 100,
      minWidth: 100,
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      filter: true,
    },

  };

  _routerSub = Subscription.EMPTY;
  _routerparamsSub = Subscription.EMPTY;
  _routerLoginSub = Subscription.EMPTY

  doc = 'https://bigmart.ae/assets/PriceChangeTemplate.xlsx';

  constructor(private appService: DhukanDataService, private route: ActivatedRoute,
    public router: Router, private toastr: ToastrService, private productFindService: NgbModal,
    private confirmationDialogService: ConfirmationDialogService, private datePipe: DatePipe, private loginService: LoginDataService) {
    this._routerSub = this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.pricechangeid = params.id
      }
    });


    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }
  ngOnDestroy(): void {
    if (this._routerSub !== undefined) {
      this._routerSub.unsubscribe();
    }
    if (this._routerparamsSub !== undefined) {
      this._routerparamsSub.unsubscribe();
    }

    if (this._routerLoginSub !== undefined) {
      this._routerLoginSub.unsubscribe();
    }
  }
  fnOnInitForm() {
    try {
      this.isaddmode = true;
      this.pricechangeform.reset();
      this.fnInitializepricechangeformGroup();
      this.dtOptions = {
        searching: true,
        pageLength: 25,
        serverSide: false,
        processing: true,
        dom: '<"top"f>rt<"bottom"ilp><"clear">',
        columns: [
          { title: 'Action' },
          { title: 'Product Name' },
          { title: 'Sku' },
          { title: 'Regular Price' },
          { title: 'Offer Price' },


        ],
        order: [1, 'desc'],
        scrollX: true,
        scrollCollapse: true,

      };

      if (this.pricechangeid > 0) {
        this.fngetPriceChange();
      }
      else {
        this.iseditmode = true;
      }
      this.fngetWarehouse();
      this.fngetChangeType()


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.pricechangeid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializepricechangeformGroup() {
    this.pricechangeform.setValue({
      pricechangeno: 'New',
      description: '',
      pricechangedate: null,
      syncdate: null,
      warehouseid: null,
      pricechangeid: 0,
      changetype:'PRICE',
      inactive: false,
    })
  }


  fngetChangeType() {
    this.changetypeColl = [] 
    this.changetypeColl.push({'displayname':'Price','value':'PRICE'})
    this.changetypeColl.push({ 'displayname': 'Stock', 'value': 'STOCK' })
  }
  
  fngetWarehouse() {
    var data = {
      'warehouseid': 0,
    }
    this.appService.getWarehouse(data)
      .subscribe(
        (res) => {
          this.warehouseColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }


  //Get 
  fngetPriceChange() {
    var data = {
      'pricechangeid': this.pricechangeid,
    }
    this.appService.getPriceChangebyPriceChangeid(data)
      .subscribe(
        (res) => {

          this.pricechangeColl = res.json().result[0];

          this.pricechangeBO = this.pricechangeColl[0];

          var editdata = {
            'pricechangeid': this.pricechangeColl[0].pricechangeid,
            'pricechangeno': this.pricechangeColl[0].pricechangeno,
            'description': this.pricechangeColl[0].description,
            'pricechangedate': this.pricechangeColl[0].pricechangedate,
            'syncdate': this.pricechangeColl[0].syncdate,
            'warehouseid': this.pricechangeColl[0].warehouseid,
            'changetype': this.pricechangeColl[0].changetype,
            'inactive': this.pricechangeColl[0].inactive,
          }
          this.fnEditMode(editdata);
          this.pricechangedetailsColl = res.json().result[1];


        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.pricechangeform.valid) {
        //Check
        var data = {
          'pricechangeid': this.pricechangeid,
          'pricechangeno': this.pricechangeform.get('pricechangeno').value,
          'description': this.pricechangeform.get('description').value,
          'pricechangedate': this.fnFormatDatetime(this.pricechangeform.get('pricechangedate').value),
          'syncdate': this.fnFormatDatetime(this.pricechangeform.get('syncdate').value),
          'warehouseid': this.pricechangeform.get('warehouseid').value,
          'changetype': this.pricechangeform.get('changetype').value,
          'inactive': 0,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
          'pricechangedetailscoll': this.pricechangedetailsColl
        }

        if (this.pricechangeform.get('pricechangeid').value > 0) {
          //Update Department
          this.fnSetPriceChange(data);
        }
        else {
          //Insert Department
          this.fnInsertPriceChange(data);
        }

        //  this.appService.fnCheckOffer(data).subscribe(
        //    (res)=>{
        //        var _checkresult=res.json().result[0].checkfield;

        //        if(_checkresult==="done"){

        //        }
        //        else{        
        //              if(_checkresult==="name"){
        //                this.pricechangeform.controls['offertype'].setErrors({'notfound': true});
        //              }
        //        }
        //  },
        //  (err)=>{
        //      this.toastr.error('Error', err);           
        //  });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Insert
  fnInsertPriceChange(data) {
    this.fnShowProgress();
    this.appService.fnInsertPriceChange(data).subscribe(
      (res) => {
        this.fnHideProgress();

        if (res.json().result != "") {
          this.toastr.success('', "The new PriceChange has been added successfully.Insert Successfully");

          this.fnInsertActivityLog('INSERT', 0, data.desc);

          this.fnClosePriceChange();
        }
      },
      (err) => {
        this.fnHideProgress();
        console.log(err)
        this.toastr.error('Error', err);
      });

  }

  //Set
  fnSetPriceChange(data) {
    this.fnShowProgress();
    this.appService.fnSetPriceChange(data).subscribe(
      (res) => {
        this.fnHideProgress();

        if (res.json().result != "") {
          this.toastr.success('', "The PriceChange has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.pricechangeid, data.pricechangeno);

          this.fnClosePriceChange();
        }
      },
      (err) => {
        this.fnHideProgress();
        this.toastr.error('Error', err);
      });

  }

  //Post
  public fnPostConfirm() {
    try {
      this.confirmationDialogService.confirm('Confirm..', 'Do you  want to Post PriceChange?')
        .then((confirmed) => {
          if (confirmed) {
            this.fnPostPriceChange()
          }
        }
        );
      //.catch(() => );
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnPostPriceChange() {
    var data = {
      'pricechangeid': this.pricechangeid,
    }
    this.appService.fnSetPriceChangePost(data)
      .subscribe(
        (res) => {
          if (res.json().status === 200) {
            this.toastr.success('', " The PriceChange has been Post successfully.");

            var _no = ""
            this.pricechangeform.get('pricechangeno').value;
            this.fnInsertActivityLog('POST', this.pricechangeid, _no);

            this.fnClosePriceChange();
          } else {
            this.toastr.error('', res.json().err_field);
          }
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  //Close
  fnClosePriceChange() {
    try {
      this.router.navigate(['/PriceChange']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  //PriceChange Product
  fnAddPriceChangeProduct() {


    const modalRef = this.productFindService.open(ProductfindComponent, { size: <any>'xl' });
    modalRef.componentInstance.doctype = "PRODUCT";

    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {



      for (let wbo of receivedEntry) {

        if (this.pricechangedetailsColl.length === 0) {
          wbo.oldprice = wbo.sellingprice;

          this.pricechangedetailsColl.push(wbo);
        }
        else {

          const exitindex = this.pricechangedetailsColl.findIndex(sku => sku.productid === wbo.productid && sku.skuid === wbo.skuid);

          if (exitindex < 0) {
            wbo.oldprice = wbo.sellingprice;

            this.pricechangedetailsColl.push(wbo);
          }

        }


      }

      this.pricechangedetailsColl = this.pricechangedetailsColl;

      this.fnSetDataTable();
    });


  }

  fnDeletePriceChangeProduct(prodindex) {
    this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete PriceChange Product')
      .then((confirmed) => {
        if (confirmed) {

          this.pricechangedetailsColl.splice(prodindex, 1);

        }
      });
  }

  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }

  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {


        var dateParts = dt.split("-");

        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);

        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());



        return sdate;
      }

    } else {
      return null
    }

  }


  private fnEditMode(deptBO) {

    deptBO.pricechangedate = this.fnConvertDatetime(this.fnFormatDatetime(deptBO.pricechangedate));
    deptBO.syncdate = this.fnConvertDatetime(this.fnFormatDatetime(deptBO.syncdate));

    this.pricechangeform.setValue({
      pricechangeno: deptBO.pricechangeno,
      description: deptBO.description,
      pricechangedate: deptBO.pricechangedate,
      syncdate: deptBO.syncdate,
      warehouseid: deptBO.warehouseid,
      changetype:deptBO.changetype,
      pricechangeid: deptBO.pricechangeid,
      inactive: deptBO.inactive,
    });


    this.isaddmode = false;
    this.iseditmode = false;
    this.isdeletemode = false;
    this.isviewmode = true;
  }

  fnChangeEditMode() {
    this.isviewmode = false;
    this.iseditmode = true;
  }


  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });

    } catch {

    }
  }


  fnGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }



  onFileChange(event: any) {



    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      console.log(data); // Data will be logged in array format containing objects


      this.fnInsertImport(data)

    };
  }


  //Insert
  fnInsertImport(detailsdata) {

    var ischeck :boolean= false;
    var errorfeild:any="";

    // for (let abo in data) {
      
    // }

    if(detailsdata.length){
      for (let abo of detailsdata) {
        if (abo.sku ==undefined) {
          errorfeild = "SKU Column Incorrect"
          break
        }

        if (abo.itemname ==undefined) {
          errorfeild = "Product Name Column Incorrect"
          break
        }
        if (abo.mrp ==undefined) {
          errorfeild = "MRP Column Incorrect"
          break
        }
        if (abo.price ==undefined) {
          errorfeild = "Price Column Incorrect"
          break
        }
        if (abo.stock ==undefined) {
          errorfeild = "Stock Column Incorrect"
          break
        }
        
        ischeck=true
        

        break
      }
    }

    if (!ischeck) {
      this.toastr.error('', "Excel "+errorfeild);
      return
    }


    this.fnShowProgress();
    var data = {
      'doctype': "PRICECHANGE",
      'date': this.fnFormatDatetime(this.pricechangeform.get('pricechangedate').value),
      'warehouseid': this.pricechangeform.get('warehouseid').value,
      'inactive': 0,
      'createdby': this.loginService.fnGetModUserId(),
      'modifiedby': this.loginService.fnGetModUserId(),
      'importdetailscoll': detailsdata
    }


    this.appService.fnInsertImport(data).subscribe(
      (res) => {
        this.fnHideProgress();

        console.log(res.json())
        if (res.json().status == 200) {
          this.toastr.success('', "The new Import has been added successfully.Insert Successfully");

          this.isimport = true;

          this.pricechangedetailsColl = res.json().result[0];
          this.pricechangedetailsnotmatchColl = res.json().result[1];

        }
      },
      (err) => {
        this.fnHideProgress();
        console.log(err)
        this.toastr.error('Error', err);
      });

  }



  
  fnDownload() {
    var filename = this.doc.split('/').pop();
    try {
      fileSaver.saveAs(this.doc, filename);
    }
    catch (e) {
        console.log(e)
    }

  
  }

  private fnSetDataTable() {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }


  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }






}
