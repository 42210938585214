import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-pagelayoutorder',
  templateUrl: './pagelayoutorder.component.html',
  styleUrls: ['./pagelayoutorder.component.css']
})
export class PagelayoutorderComponent implements OnInit {
  movies = [
    'Episode I - The Phantom Menace',
    'Episode II - Attack of the Clones',
    'Episode III - Revenge of the Sith',
    'Episode IV - A New Hope',
    'Episode V - The Empire Strikes Back',
    'Episode VI - Return of the Jedi',
    'Episode VII - The Force Awakens',
    'Episode VIII - The Last Jedi',
    'Episode IX – The Rise of Skywalker'
  ];

 

  @Input() doctype: string;
  @Input() mode: string;
  @Input() req:{};
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() msgboxtype: string;
  @Output() emitData = new EventEmitter();
  @Input() displaytxt: string="";

  @Input() pagedetailsBO: any = {};
     
  @Input() editorValue: string = '';
 
  pglayoutColl:any=[];


  orderbyid:number=null;

  ordersubmitted:boolean;
  ordersuccessfully:boolean;

  constructor(private activeModal: NgbActiveModal, private appService: DhukanDataService, private loginService: LoginDataService) { }

  ngOnInit() {
   
  }

  public decline() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }



  
  fnSetPageOrder() {
    
    this.fnApplyPageLayout();

    var loopindex=0;      

    for(let i=0; i<this.pglayoutColl.length;i++){
                       
        this.fnUpdateLayoutOrder(this.pglayoutColl[i].pagelayoutid,this.pglayoutColl[i].orderno,loopindex,this.pglayoutColl.length-1)
        loopindex=loopindex+1;
           
    }
  }

  fnSetPageLayout(pColl) {
    this.pglayoutColl = [];
    for (let pBO of pColl) {
      this.pglayoutColl.push(pBO);
    }
  }

  fnUpdateLayoutOrder(layoutid,orderno,i,totallength) {   
  

    console.log(i);
    console.log(totallength);


    var data = {
      'pagelayoutid': layoutid,
      'orderno':orderno
    }
    this.appService.fnSetPageLayoutOrder(data)
        .subscribe(
          (res)=>{                        
            if(res.json().status==200){                          
              
            
              if(i===totallength){
                //this.fngetProduct();

                this.emitData.emit("");  
                this.activeModal.close(true);  
              }

            }
            else if(res.json().status==400){                          
             console.log('Error', res.json().err_field);
            }
           
           
        },
          (err)=>{          
          
            console.log('Error', err);
          })
}


  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.pglayoutColl, event.previousIndex, event.currentIndex-4);
  }


  

  fnApplyPageLayout() {
    
    for (let i=0; i<this.pglayoutColl.length;i++) {
      this.pglayoutColl[i].orderno = i;
    }

    
  }



}
