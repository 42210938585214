import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-adduom',
  templateUrl: './adduom.component.html',
  styleUrls: ['./adduom.component.css']
})
export class AdduomComponent implements OnInit {
  //Mode Values
  isaddmode:boolean=false;
  iseditmode:boolean=false;
  isdeletemode:boolean=false;
  isviewmode:boolean=false;

  //Save Validation
  submitted:boolean;
  successfully:boolean;


  //Edit Get Values
  uomColl:any=[];
  uomid:number=0

  uomform=new FormGroup({
    uom:new FormControl('',Validators.required),         
    uomid:new FormControl(0),      
   inactive:new FormControl(false),     
  });


isloading:boolean=false;
isprivilage:boolean=false;  
showProgress:boolean=false;
formname:String="UOM";

_routerSub = Subscription.EMPTY;
_routerparamsSub = Subscription.EMPTY;
_routerLoginSub = Subscription.EMPTY

constructor(private appService: DhukanDataService,private route: ActivatedRoute,
     public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
      this._routerSub = this.route.queryParams.subscribe(params => {
        if(params.id!==undefined){
          this.uomid = params.id
        }  
       });
       this.fnServiceChanges();
}
 

fnServiceChanges(){
  this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}

ngOnInit() {
    try{
      if(this.loginService.fnCheckUser()){ 
        if(this.fnCheckUserPririvilage()){       
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
              
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}
ngOnDestroy(): void {
  if (this._routerSub !== undefined) {
    this._routerSub.unsubscribe();
  }
  if (this._routerparamsSub !== undefined) {
    this._routerparamsSub.unsubscribe();
  }

  if (this._routerLoginSub !== undefined) {
    this._routerLoginSub.unsubscribe();
  }
}
fnOnInitForm(){
     try{
         this.isaddmode=true;
         this.uomform.reset();
         this.fnInitializeUomFormGroup(); 

         if(this.uomid>0){
           this.fngetUom();
         }
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
}




fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.uomid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}


fnInitializeUomFormGroup(){
     this.uomform.setValue({
        uom:'',
        uomid:0,      
       inactive:false,      
     })
}

//Get 
fngetUom() {        
       var data = {
         'uomid':this.uomid,            
       }
       this.appService.getUom(data)
       .subscribe(
         (res)=>{                  
           this.uomColl=res.json().result;
           var editdata = {
             'uomid':this.uomColl[0].uomid,
             'uom':this.uomColl[0].uom, 
             'inactive':this.uomColl[0].inactive,          
           }
           this.fnEditMode(editdata);
       },
       (err)=>{          
         this.toastr.error('Error', err);
       })
}

//Save Btn Click
fnSaveBtnClick(){
     try{
       this.submitted=true;
       this.successfully=false;
       //Validate
       if(this.uomform.valid){
           //Check
           var data = {
                 'uomid': this.uomid,       
                 'uom':this.uomform.get('uom').value,            
                 'inactive': 0,  
                 'createdby': this.loginService.fnGetModUserId(),    
                 'modifiedby':this.loginService.fnGetModUserId(), 
           }
           this.appService.fnCheckUom(data).subscribe(
             (res)=>{
                 var _checkresult=res.json().result[0].checkfield;
               
                 if(_checkresult==="done"){
                       if (this.uomform.get('uomid').value>0){
                         //Update Department
                         this.fnSetUom(data);
                       }
                       else{
                         //Insert Department
                         this.fnInsertUom(data);
                       }
                 }
                 else{        
                       if(_checkresult==="name"){
                         this.uomform.controls['uom'].setErrors({'notfound': true});
                       }
                 }
           },
           (err)=>{
               this.toastr.error('Error', err);           
           });
        }
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
}

//Insert
fnInsertUom(data){    
   
     this.appService.fnInsertUom(data).subscribe(
       (res)=>{
       if(res.json().result!=""){
         this.toastr.success('', "The new uom has been added successfully.Insert Successfully");

         this.fnInsertActivityLog('INSERT',0,data.uom);

         this.fnCloseUom();
       }},
       (err)=>{
         this.toastr.error('Error', err);
       });

}

//Set
fnSetUom(data){    

 this.appService.fnSetUom(data).subscribe(
   (res)=>{
   if(res.json().result!=""){
       this.toastr.success('', "The uom has been updated successfully.");

       this.fnInsertActivityLog('EDIT',this.uomid,data.uom);

       this.fnCloseUom();        
   }},
   (err)=>{
     this.toastr.error('Error', err);
   });

}

//Close
fnCloseUom(){
 try{
    this.router.navigate(['/Uom']);
 }catch(ex){
     this.fnShowErrorMsg(ex);
 }
}

private fnEditMode(deptBO){
   this.uomform.setValue({
     uom:deptBO.uom,
     uomid:deptBO.uomid,
     inactive:deptBO.inactive,   
   });
  
 this.isaddmode=false;
 this.iseditmode=true;
 this.isdeletemode=false;
 this.isviewmode=false;
}


fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}


private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


private  fnShowErrorMsg(ex){
 this.toastr.warning('Internal Error', ex);       
}



}
