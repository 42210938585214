import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductspecificationComponent } from '../../productspecification/productspecification.component';
import { PagelayoutComponent } from '../../pagelayout/pagelayout.component';
import { PagelayoutimageComponent } from '../../pagelayoutimage/pagelayoutimage.component';
import { ProductfindComponent } from '../../productfind/productfind.component';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { PageorderComponent } from '../pageorder/pageorder.component';
import { ZoomproductComponent } from '../../orders/zoomproduct/zoomproduct.component';
import { PagelayoutorderComponent } from '../pagelayoutorder/pagelayoutorder.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-addpage',
  templateUrl: './addpage.component.html',
  styleUrls: ['./addpage.component.css']
})
export class AddpageComponent implements OnInit {
//Mode Values
isaddmode:boolean=false;
iseditmode:boolean=false;
isdeletemode:boolean=false;
isviewmode:boolean=false;

//Save Validation
submitted:boolean;
successfully:boolean;


//Edit Get Values
pageColl:any=[];
pagelayoutColl:any=[];
pageid:number=0

pageform=new FormGroup({
  pagename:new FormControl('',Validators.required),         
  pagedesc:new FormControl(''),         
  pageid:new FormControl(0),      
  iscompletepage:new FormControl(false),     
 inactive:new FormControl(false),     
});

isloading:boolean=false;
isprivilage:boolean=false;  
showProgress:boolean=false;
formname:String="Page";

_routerSub = Subscription.EMPTY;
_routerparamsSub = Subscription.EMPTY;
_routerLoginSub = Subscription.EMPTY

constructor(private appService: DhukanDataService,private route: ActivatedRoute,
   public router: Router,private toastr: ToastrService, 
   private productFindService: NgbModal,private confirmationDialogService: ConfirmationDialogService,private loginService:LoginDataService) { 
    this._routerSub = this.route.queryParams.subscribe(params => {
      if(params.id!==undefined){
        this.pageid = params.id
      }  

     });

     this.fnServiceChanges();
}


fnServiceChanges(){
  this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}

ngOnInit() {
    try{
      if(this.loginService.fnCheckUser()){ 
        if(this.fnCheckUserPririvilage()){       
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
              
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}

ngOnDestroy(): void {
  if (this._routerSub !== undefined) {
    this._routerSub.unsubscribe();
  }
  if (this._routerparamsSub !== undefined) {
    this._routerparamsSub.unsubscribe();
  }

  if (this._routerLoginSub !== undefined) {
    this._routerLoginSub.unsubscribe();
  }
}

fnOnInitForm(){
   try{
       this.isaddmode=true;
       this.pageform.reset();
       this.fnInitializePageFormGroup(); 

       if(this.pageid>0){
         this.fngetPage();
       }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}




fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.pageid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}


fnInitializePageFormGroup(){
   this.pageform.setValue({
      pagename:'',
      pagedesc:'',
      pageid:0,    
      iscompletepage:false,  
     inactive:false,      
   })
}

//Get 
fngetPage() {        
    this.pagelayoutColl=[];
    
     var data = {
       'pageid':this.pageid,            
     }
     this.appService.getPage(data)
     .subscribe(
       (res)=>{             

        console.log(res.json().resul);

        this.pagelayoutColl=[];

         this.pageColl=res.json().result[0];         
         let _pagelayout=res.json().result[1]; 
         let _pagedetails=res.json().result[2]; 
         var editdata = {
           'pageid':this.pageColl[0].pageid,           
           'pagename':this.pageColl[0].pagename, 
           'pagedesc':this.pageColl[0].pagedesc,
           'iscompletepage':this.pageColl[0].iscompletepage,
           'inactive':this.pageColl[0].inactive,          
         }
         this.fnEditMode(editdata);

         for(let pbo of _pagelayout){

            var pagedetails = []
            for(let mbo of _pagedetails){
              if(mbo.pagelayoutid===pbo.pagelayoutid){
                pagedetails.push(mbo)
              }
            }            
            pbo.pagedetails=pagedetails;

            this.pagelayoutColl.push(pbo);
         }






     },
     (err)=>{          
       this.toastr.error('Error', err);
     })
}

//Save Btn Click
fnSaveBtnClick(){
   try{
     this.submitted=true;
     this.successfully=false;
     //Validate
     if(this.pageform.valid){
        
         //Check
         var data = {
               'pageid': this.pageid,       
               'pagename':this.pageform.get('pagename').value,            
               'pagedesc':this.pageform.get('pagedesc').value,  
               'iscompletepage':this.pageform.get('iscompletepage').value, 
               'inactive': this.pageform.get('inactive').value, 
               'createdby': this.loginService.fnGetModUserId(),    
               'modifiedby':this.loginService.fnGetModUserId(),  
               'pagelayout':this.pagelayoutColl
         }
  
                     if (this.pageform.get('pageid').value>0){
                       //Update Department
                       this.fnSetPage(data);
                     }
                     else{
                       //Insert Department
                       this.fnInsertPage(data);
                     }
               
            
      }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}

//Insert
fnInsertPage(data){    
 this.fnShowProgress();
   this.appService.fnInsertPage(data).subscribe(
     (res)=>{
      this.fnHideProgress();
     if(res.json().result!=""){       
       this.toastr.success('', "The new Page has been added successfully.Insert Successfully");

       this.fnInsertActivityLog('INSERT',0,data.pagename);
       
       this.fnClosePage();
     }},
     (err)=>{
      this.fnHideProgress();
       console.log(err)
       this.toastr.error('Error', err);
     });

}

//Set
fnSetPage(data){    
  this.fnShowProgress();
this.appService.fnSetPage(data).subscribe(
 (res)=>{
  this.fnHideProgress();
 if(res.json().result!=""){
     this.toastr.success('', "The Page has been updated successfully.");

     this.fnInsertActivityLog('EDIT',this.pageid,data.pagename);

     this.fnClosePage();        
 }},
 (err)=>{
  this.fnHideProgress();
   this.toastr.error('Error', err);
 });

}

//Close
fnClosePage(){
try{
  this.router.navigate(['/Page']);
}catch(ex){
   this.fnShowErrorMsg(ex);
}
}


//Page Layout
fnAddPageLayoutBtnClick(){
  const modalRef = this.productFindService.open(PagelayoutComponent ,{ size:  <any>'xl' });
  modalRef.componentInstance.doctype ="Page Layout" ;
  modalRef.componentInstance.mode ="EDIT" ;

  modalRef.componentInstance.orderno=this.pagelayoutColl.length+1;

  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

    this.fnAddPageLayout(receivedEntry)
  });
      
}

fnEditPageLayoutBtnClick(index1){

  const modalRef = this.productFindService.open(PagelayoutComponent ,{ size:  <any>'xl' });
  modalRef.componentInstance.doctype ="Page Layout" ;
  modalRef.componentInstance.mode ="ADD" ;

  modalRef.componentInstance.websiteimagepath=this.pagelayoutColl[index1].backwebimgpath;
  modalRef.componentInstance.image=this.pagelayoutColl[index1].backwebsiteimageload;
  modalRef.componentInstance.strImage=this.pagelayoutColl[index1].backwebsiteimage;

  modalRef.componentInstance.mobileimagepath=this.pagelayoutColl[index1].backmobileimgpath;
  modalRef.componentInstance.mobileimage=this.pagelayoutColl[index1].backmobileimageload;
  modalRef.componentInstance.mobilestrImage=this.pagelayoutColl[index1].backmobileimage;

  modalRef.componentInstance.layouttypeid=this.pagelayoutColl[index1].layouttypeid;
  modalRef.componentInstance.orderno=this.pagelayoutColl[index1].orderno;
  modalRef.componentInstance.headername=this.pagelayoutColl[index1].headername;
  modalRef.componentInstance.subheadername=this.pagelayoutColl[index1].subheadername;

  modalRef.componentInstance.linktype=this.pagelayoutColl[index1].linktype;    
  modalRef.componentInstance.linktargetid=this.pagelayoutColl[index1].linktargetid;
  modalRef.componentInstance.linktargetfilter=this.pagelayoutColl[index1].linktargetfilter;  

  modalRef.componentInstance.layouttypename=this.pagelayoutColl[index1].layouttype;
  modalRef.componentInstance.perrowweb=this.pagelayoutColl[index1].perrowweb;    
  modalRef.componentInstance.perrowmobile=this.pagelayoutColl[index1].perrowmobile;  

  modalRef.componentInstance.skippaddingleft=this.pagelayoutColl[index1].skippaddingleft;  
  modalRef.componentInstance.skippaddingright=this.pagelayoutColl[index1].skippaddingright;  
  modalRef.componentInstance.skippaddingtop=this.pagelayoutColl[index1].skippaddingtop;  
  modalRef.componentInstance.skippaddingbottom=this.pagelayoutColl[index1].skippaddingbottom;  

  modalRef.componentInstance.isweb=this.pagelayoutColl[index1].isweb;  
  modalRef.componentInstance.ismobile=this.pagelayoutColl[index1].ismobile; 
  modalRef.componentInstance.inactive=this.pagelayoutColl[index1].inactive; 
   

  modalRef.componentInstance.bannerheight=this.pagelayoutColl[index1].bannerheight;  

  modalRef.componentInstance.paddingleft=this.pagelayoutColl[index1].paddingleft;  
  modalRef.componentInstance.paddingright=this.pagelayoutColl[index1].paddingright;  
  modalRef.componentInstance.paddingtop=this.pagelayoutColl[index1].paddingtop;  
  modalRef.componentInstance.paddingbottom=this.pagelayoutColl[index1].paddingbottom;  
  modalRef.componentInstance.backcolorcode=this.pagelayoutColl[index1].backcolorcode;  

  modalRef.componentInstance.fnLinkTypeChange();

  let type= this.pagelayoutColl[index1].linktype
  if(type==="DEPARTMENT"){
    modalRef.componentInstance.deptid=this.pagelayoutColl[index1].linktargetid;
  }
  else if(type==="CATEGORY"){
    modalRef.componentInstance.categoryid=this.pagelayoutColl[index1].linktargetid;
  }
  else if(type==="SUBCATEGORY"){
    modalRef.componentInstance.subcategoryid=this.pagelayoutColl[index1].linktargetid;
  } 
  else if(type==="BRAND"){
    modalRef.componentInstance.brandid=this.pagelayoutColl[index1].linktargetid;
  } 
  else if(type==="VENDOR"){
    modalRef.componentInstance.vendorid=this.pagelayoutColl[index1].linktargetid;
  } 
  else if(type==="OFFER"){
    modalRef.componentInstance.offerid=this.pagelayoutColl[index1].linktargetid;
  } 
  else if(type==="PRODUCTGROUP"){
    modalRef.componentInstance.productgroupid=this.pagelayoutColl[index1].linktargetid;
  } 
  else if(type==="PAGE"){
    modalRef.componentInstance.pageid=this.pagelayoutColl[index1].linktargetid;
  } 
  else if(type==="PRODUCT"){
    modalRef.componentInstance.productid=this.pagelayoutColl[index1].linktargetid;
    modalRef.componentInstance.productname=this.pagelayoutColl[index1].targetproductname;   
  }
  else if(type==="HTML"){
    modalRef.componentInstance.contentid=this.pagelayoutColl[index1].linktargetid;
  } 

  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

    this.pagelayoutColl[index1].isedit=1;
    this.pagelayoutColl[index1].backwebimgath=receivedEntry.websiteimagepath;
    this.pagelayoutColl[index1].backwebsiteimage=receivedEntry.websiteimage;
    this.pagelayoutColl[index1].backwebsiteimageload=receivedEntry.websiteimageload;
    
    this.pagelayoutColl[index1].backmobileimgpath=receivedEntry.mobileimagepath;
    this.pagelayoutColl[index1].backmobileimage=receivedEntry.mobileimage;
    this.pagelayoutColl[index1].backmobileimageload=receivedEntry.mobileimageload;

    this.pagelayoutColl[index1].orderno=receivedEntry.orderno;
    this.pagelayoutColl[index1].headername=receivedEntry.headername;
    this.pagelayoutColl[index1].subheadername=receivedEntry.subheadername;

    this.pagelayoutColl[index1].linktype=receivedEntry.linktype;
    this.pagelayoutColl[index1].linktargetid=receivedEntry.linktargetid;
    this.pagelayoutColl[index1].linktargetfilter=receivedEntry.linktargetfilter;
    this.pagelayoutColl[index1].targetproductname=receivedEntry.targetproductname;

    this.pagelayoutColl[index1].perrowweb=receivedEntry.perrowweb;
    this.pagelayoutColl[index1].perrowmobile=receivedEntry.perrowmobile;

    this.pagelayoutColl[index1].skippaddingleft=receivedEntry.skippaddingleft;
    this.pagelayoutColl[index1].skippaddingright=receivedEntry.skippaddingright;
    this.pagelayoutColl[index1].skippaddingtop=receivedEntry.skippaddingtop;
    this.pagelayoutColl[index1].skippaddingbottom=receivedEntry.skippaddingbottom;

    this.pagelayoutColl[index1].isweb=receivedEntry.isweb;
    this.pagelayoutColl[index1].ismobile=receivedEntry.ismobile;
    this.pagelayoutColl[index1].inactive=receivedEntry.inactive;

    this.pagelayoutColl[index1].bannerheight=receivedEntry.bannerheight;

    this.pagelayoutColl[index1].paddingleft=receivedEntry.paddingleft;
    this.pagelayoutColl[index1].paddingright=receivedEntry.paddingright;
    this.pagelayoutColl[index1].paddingtop=receivedEntry.paddingtop;
    this.pagelayoutColl[index1].paddingbottom=receivedEntry.paddingbottom;
    this.pagelayoutColl[index1].backcolorcode=receivedEntry.backcolorcode;

  });
      
}

fnDeletePageLayoutBtnClick(pagelayoutid,sno){
  this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Layout')
  .then((confirmed) => 
        {
          if(confirmed){
            if(pagelayoutid>0){
                const index = this.pagelayoutColl.findIndex(sku => sku.pagelayoutid === pagelayoutid  && sku.sno === sno ); 
                if(index>-1){    
                  this.pagelayoutColl[index].isdelete=1;   
                }   

             }else{

              const index = this.pagelayoutColl.findIndex(sku => sku.pagelayoutid === pagelayoutid  && sku.sno === sno ); 
              if(index>-1){                    
                this.pagelayoutColl.splice(index, 1);                   
              }   

              
             }
            
      }
    });

}

private fnAddPageLayout(laytypeBO){
  this.pagelayoutColl.push(this.fnAddPageLayoutBO(laytypeBO)); 

}

private fnAddPageLayoutBO(laytypeBO){
  var pagelayout = { 
    sno:this.pagelayoutColl.length+1,
    pageid:0,
    pagelayoutid:0,
    layouttypeid:laytypeBO.layouttypeid,
    layouttype:laytypeBO.layouttype,
    noofimages:laytypeBO.noofimages,
    orderno:laytypeBO.orderno,
    headername:laytypeBO.headername,
    subheadername:laytypeBO.subheadername,
    linkurl:'',
    linktype:'',
    linktargetid:0,
    linktargetfilter:laytypeBO.linktargetfilter,
    backwebimgpath:laytypeBO.websiteimagepath,
    backwebsiteimage:laytypeBO.websiteimage,
    backwebsiteimageload:laytypeBO.websiteimageload,
    backmobileimgpath:laytypeBO.mobileimagepath,
    backmobileimage:laytypeBO.mobileimage,
    backmobileimageload:laytypeBO.mobileimageload,
    perrowweb:0,
    perrowmobile:0,
    skippaddingleft:laytypeBO.skippaddingleft,
    skippaddingright:laytypeBO.skippaddingright,
    skippaddingtop:laytypeBO.skippaddingtop,
    skippaddingbottom:laytypeBO.skippaddingbottom,
    isweb:laytypeBO.isweb,
    ismobile:laytypeBO.ismobile,
    inactive:laytypeBO.inactive,
    bannerheight:laytypeBO.bannerheight,
    isedit:0,
    isdelete:0,
    pagedetails:this.fnAddPageDetailsBO(laytypeBO),
  }


  return pagelayout;
}

private fnAddPageDetailsBO(laytypeBO){
  var pagedetailsColl = []
  
  if(laytypeBO.layouttype !=='ITEMCAROSAL' && laytypeBO.layouttype !=='ITEMLIST'){
    
      for(var i = 0; i < laytypeBO.noofimages; i++){
        var detailsBO = {
          pagedetailsid:0,
          pagelayoutid:0,
          webimgpath:'',
          websiteimage:null,
          websiteimageload:null,
          mobileimgpath:'',
          mobileimage:null,
          mobileimageload:null,
          arwebimgpath:'',
          arwebsiteimage:null,
          arwebsiteimageload:null,
          armobileimgpath:'',
          armobileimage:null,
          armobileimageload:null,          
          linkurl:'',
          linktype:'',          
          linktargetid:0,
          linktargetfilter:'',
          productid:0,
          skuid:0,
          orderno:0, 
          isedit:0,
          isdelete:0,   
        }
        pagedetailsColl.push(detailsBO)

      }
  }


  return pagedetailsColl;
}

private fnAddPageDetailsBtnClick(index){
  this.pagelayoutColl[index].pagedetails.push(this.fnSetPageDetailsBO());
}

private fnSetPageDetailsBO(){

        var detailsBO = {
          pagedetailsid:0,
          pagelayoutid:0,
          webimgpath:'',
          websiteimage:null,
          websiteimageload:null,
          mobileimgpath:'',
          mobileimage:null,
          mobileimageload:null,
          arwebimgpath:'',
          arwebsiteimage:null,
          arwebsiteimageload:null,
          armobileimgpath:'',
          armobileimage:null,
          armobileimageload:null,
          linkurl:'',
          linktype:'',
          linktargetid:0,
          productid:0,
          skuid:0,
          isedit:0,
          isdelete:0,   
        }
      

  return detailsBO;
}

  
fnAddPageLayoutOrderByBtnClick(){
  const modalRef = this.productFindService.open(PagelayoutorderComponent ,{ size:  <any>'l' });
  modalRef.componentInstance.doctype ="Page Layout" ;
  modalRef.componentInstance.mode ="EDIT" ;

  modalRef.componentInstance.fnSetPageLayout(this.pagelayoutColl);


  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
    window.location.reload()      

  });
      
}

//Page Details
fnEditPageDetailsBtnClick(pagelayoutid,sno,detailsindex){

  const modalRef = this.productFindService.open(PagelayoutimageComponent ,{ size: 'lg' });
  modalRef.componentInstance.doctype ="Page Details" ;
  modalRef.componentInstance.mode ="ADD" ;

  const index1 = this.pagelayoutColl.findIndex(sku => sku.pagelayoutid === pagelayoutid  && sku.sno === sno ); 
  
  if(index1>-1){
    modalRef.componentInstance.websiteimagepath=this.pagelayoutColl[index1].pagedetails[detailsindex].webimgpath;
    modalRef.componentInstance.image=this.pagelayoutColl[index1].pagedetails[detailsindex].websiteimageload;
    modalRef.componentInstance.strImage=this.pagelayoutColl[index1].pagedetails[detailsindex].websiteimage;

    modalRef.componentInstance.mobileimagepath=this.pagelayoutColl[index1].pagedetails[detailsindex].mobileimgpath;
    modalRef.componentInstance.mobileimage=this.pagelayoutColl[index1].pagedetails[detailsindex].mobileimageload;
    modalRef.componentInstance.mobilestrImage=this.pagelayoutColl[index1].pagedetails[detailsindex].mobileimage;    
    
    modalRef.componentInstance.arwebsiteimagepath=this.pagelayoutColl[index1].pagedetails[detailsindex].arwebimgpath;
    modalRef.componentInstance.arimage=this.pagelayoutColl[index1].pagedetails[detailsindex].arwebsiteimageload;
    modalRef.componentInstance.arstrImage=this.pagelayoutColl[index1].pagedetails[detailsindex].arwebsiteimage;

    modalRef.componentInstance.armobileimagepath=this.pagelayoutColl[index1].pagedetails[detailsindex].armobileimgpath;
    modalRef.componentInstance.armobileimage=this.pagelayoutColl[index1].pagedetails[detailsindex].armobileimageload;
    modalRef.componentInstance.armobilestrImage=this.pagelayoutColl[index1].pagedetails[detailsindex].armobileimage;    

    modalRef.componentInstance.linktype=this.pagelayoutColl[index1].pagedetails[detailsindex].linktype;    
    modalRef.componentInstance.linktargetid=this.pagelayoutColl[index1].pagedetails[detailsindex].linktargetid;  
    modalRef.componentInstance.linktargetfilter=this.pagelayoutColl[index1].pagedetails[detailsindex].linktargetfilter;  
    
    modalRef.componentInstance.orderbyid = this.pagelayoutColl[index1].pagedetails[detailsindex].orderno;

    modalRef.componentInstance.paddingleft = this.pagelayoutColl[index1].pagedetails[detailsindex].paddingleft;
    modalRef.componentInstance.paddingright = this.pagelayoutColl[index1].pagedetails[detailsindex].paddingright;
    modalRef.componentInstance.paddingtop = this.pagelayoutColl[index1].pagedetails[detailsindex].paddingtop;
    modalRef.componentInstance.paddingbottom = this.pagelayoutColl[index1].pagedetails[detailsindex].paddingbottom;


    modalRef.componentInstance.fnLinkTypeChange();

    let type= this.pagelayoutColl[index1].pagedetails[detailsindex].linktype
    if(type==="DEPARTMENT"){
      modalRef.componentInstance.deptid=this.pagelayoutColl[index1].pagedetails[detailsindex].linktargetid;
    }
    else if(type==="CATEGORY"){
      modalRef.componentInstance.categoryid=this.pagelayoutColl[index1].pagedetails[detailsindex].linktargetid;
    }
    else if(type==="SUBCATEGORY"){
      modalRef.componentInstance.subcategoryid=this.pagelayoutColl[index1].pagedetails[detailsindex].linktargetid;
    } 
    else if(type==="BRAND"){
      modalRef.componentInstance.brandid=this.pagelayoutColl[index1].pagedetails[detailsindex].linktargetid;
    }    
    else if(type==="PRODUCTGROUP"){
      modalRef.componentInstance.productgroupid=this.pagelayoutColl[index1].pagedetails[detailsindex].linktargetid;
    }
    else if(type==="VENDOR"){
      modalRef.componentInstance.vendorid=this.pagelayoutColl[index1].pagedetails[detailsindex].linktargetid;
    } 
    else if(type==="PAGE"){
      modalRef.componentInstance.pageid=this.pagelayoutColl[index1].pagedetails[detailsindex].linktargetid;
    } 
    else if(type==="PRODUCT"){
      modalRef.componentInstance.productid=this.pagelayoutColl[index1].pagedetails[detailsindex].linktargetid;
      modalRef.componentInstance.productname=this.pagelayoutColl[index1].pagedetails[detailsindex].targetproductname;
    } 
    else if(type==="OFFER"){
      modalRef.componentInstance.offerid=this.pagelayoutColl[index1].pagedetails[detailsindex].linktargetid;
    } 
    else if(type==="HTML"){
      modalRef.componentInstance.contentid=this.pagelayoutColl[index1].pagedetails[detailsindex].linktargetid;
    } 
  
  }



  
  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

    const index = this.pagelayoutColl.findIndex(sku => sku.pagelayoutid === pagelayoutid  && sku.sno === sno ); 
   
    if(index>-1){
    
      this.pagelayoutColl[index].pagedetails[detailsindex].webimgath=receivedEntry.websiteimagepath;
      this.pagelayoutColl[index].pagedetails[detailsindex].websiteimage=receivedEntry.websiteimage;
      this.pagelayoutColl[index].pagedetails[detailsindex].websiteimageload=receivedEntry.websiteimageload;
      
      this.pagelayoutColl[index].pagedetails[detailsindex].mobileimgpath=receivedEntry.mobileimagepath;
      this.pagelayoutColl[index].pagedetails[detailsindex].mobileimage=receivedEntry.mobileimage;
      this.pagelayoutColl[index].pagedetails[detailsindex].mobileimageload=receivedEntry.mobileimageload;

      this.pagelayoutColl[index].pagedetails[detailsindex].arwebimgath=receivedEntry.arwebsiteimagepath;
      this.pagelayoutColl[index].pagedetails[detailsindex].arwebsiteimage=receivedEntry.arwebsiteimage;
      this.pagelayoutColl[index].pagedetails[detailsindex].arwebsiteimageload=receivedEntry.arwebsiteimageload;
      
      this.pagelayoutColl[index].pagedetails[detailsindex].armobileimgpath=receivedEntry.armobileimagepath;
      this.pagelayoutColl[index].pagedetails[detailsindex].armobileimage=receivedEntry.armobileimage;
      this.pagelayoutColl[index].pagedetails[detailsindex].armobileimageload=receivedEntry.armobileimageload;

      this.pagelayoutColl[index].pagedetails[detailsindex].linktype=receivedEntry.linktype;
      this.pagelayoutColl[index].pagedetails[detailsindex].linktargetid=receivedEntry.linktargetid;
      this.pagelayoutColl[index].pagedetails[detailsindex].linktargetfilter=receivedEntry.linktargetfilter;
      this.pagelayoutColl[index].pagedetails[detailsindex].targetproductname=receivedEntry.targetproductname;

      this.pagelayoutColl[index].pagedetails[detailsindex].orderno=receivedEntry.orderbyid;

      this.pagelayoutColl[index].pagedetails[detailsindex].paddingleft=receivedEntry.paddingleft;
      this.pagelayoutColl[index].pagedetails[detailsindex].paddingright=receivedEntry.paddingright;
      this.pagelayoutColl[index].pagedetails[detailsindex].paddingtop=receivedEntry.paddingtop;
      this.pagelayoutColl[index].pagedetails[detailsindex].paddingbottom=receivedEntry.paddingbottom;

      this.pagelayoutColl[index].isedit=1;
      this.pagelayoutColl[index].pagedetails[detailsindex].isedit=1;


      console.log(receivedEntry)
      
    }
    

  });


}

//Product
fnAddProduct(pagelayoutid,sno,detailsindex){
  
  
  const modalRef = this.productFindService.open(ProductfindComponent ,{ size: <any>'xl'  });
  modalRef.componentInstance.doctype ="RELATED PRODUCT" ;

  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

    const index = this.pagelayoutColl.findIndex(sku => sku.pagelayoutid === pagelayoutid  && sku.sno === sno ); 
   
    if(index>-1){

      for(let wbo of receivedEntry){

        if(this.pagelayoutColl[index].pagedetails.length===0){
          wbo.productid=wbo.productid;
          wbo.skuid=wbo.skuid;
          wbo.pagedetailsid=0;
          wbo.pagelayoutid=0;
          wbo.webimgpath='';
          wbo.websiteimage=null;
          wbo.websiteimageload=null;
          wbo.mobileimgpath='';
          wbo.mobileimage=null;
          wbo.mobileimageload=null;
          wbo.linkurl='';
          wbo.linktype='';
          wbo.linktargetid=''; 
          wbo.linktargetfilter=''; 
          wbo.isedit=0;
          wbo.isdelete=0;
          wbo.orderno=this.pagelayoutColl[index].pagedetails.length+1;

          this.pagelayoutColl[index].pagedetails.push(wbo);
        }
        else{
  
         const exitindex = this.pagelayoutColl[index].pagedetails.findIndex(sku => sku.productid === wbo.productid  && sku.skuid===wbo.skuid ); 
  
          if(exitindex<0){
            wbo.relatedproductid=wbo.productid;
            wbo.relatedskuid=wbo.skuid;
            wbo.pagedetailsid=0;
            wbo.pagelayoutid=0;
            wbo.webimgpath='';
            wbo.websiteimage=null;
            wbo.websiteimageload=null;
            wbo.mobileimgpath='';
            wbo.mobileimage=null;
            wbo.mobileimageload=null;
            wbo.linkurl=''; 
            wbo.linktype='';
            wbo.linktargetid=''; 
            wbo.linktargetfilter=''; 
            wbo.isedit=0;
            wbo.isdelete=0;
            wbo.orderno=this.pagelayoutColl[index].pagedetails.length+1;

            this.pagelayoutColl[index].pagedetails.push(wbo);
          }
  
        }
       
        this.pagelayoutColl[index].isedit=1;

      }

    }
  


  });


}

fnEditProduct(pagelayoutid,sno,detailsindex){
    
  const modalRef = this.productFindService.open(PageorderComponent ,{ size: <any>'xl'  });
  modalRef.componentInstance.doctype ="Product List Ordering" ;
  const index = this.pagelayoutColl.findIndex(sku => sku.pagelayoutid === pagelayoutid  && sku.sno === sno ); 
   
  if(index>-1 && detailsindex>-1){
    modalRef.componentInstance.pagedetailsBO = this.pagelayoutColl[index].pagedetails[detailsindex];

    modalRef.componentInstance.orderbyid = this.pagelayoutColl[index].pagedetails[detailsindex].orderno;
  }

  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {


    if(index>-1 && detailsindex>-1){
      this.pagelayoutColl[index].pagedetails[detailsindex].orderno=receivedEntry.orderbyid;


      this.pagelayoutColl[index].pagedetails[detailsindex].isedit=1;
      this.pagelayoutColl[index].isedit=1;
    }




  })


}


fnDeleteProduct(pagelayoutid,sno,detailsindex){
  this.confirmationDialogService.confirm('Confirm..', 'Do you  want to delete Related Product')
  .then((confirmed) => 
        {
          if(confirmed){
                const index = this.pagelayoutColl.findIndex(sku => sku.pagelayoutid === pagelayoutid  && sku.sno === sno ); 
                if(index>-1){
                  //this.pagelayoutColl[index].pagedetails.splice(detailsindex, 1);                   
                  this.pagelayoutColl[index].isedit=1;
                  this.pagelayoutColl[index].pagedetails[detailsindex].isdelete=1;                   
                }
                
                
      }
    });
}



private fnEditMode(deptBO){
if(deptBO.iscompletepage===null){

  deptBO.iscompletepage=false;
}
  

 this.pageform.setValue({
    pagename:deptBO.pagename,
    pageid:deptBO.pageid,
    pagedesc:deptBO.pagedesc,
    iscompletepage:deptBO.iscompletepage,   
   inactive:deptBO.inactive,   
 });

this.isaddmode=false;
this.iseditmode=true;
this.isdeletemode=false;
this.isviewmode=false;
}

   
fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}

//Zoom Pic
fnShowZoomPic(picurl){
  const modalRef = this.productFindService.open(ZoomproductComponent ,{ size: <any>'l' },);
  modalRef.componentInstance.doctype ="IMAGE" ;
  modalRef.componentInstance.picurl=picurl;  
  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
 
   
  });
      
}


//Zoom Product
fnShowZoomProduct(skuid){
  const modalRef = this.productFindService.open(ZoomproductComponent ,{ size: <any>'l' },);
  modalRef.componentInstance.doctype ="" ;
  modalRef.componentInstance.skuid=skuid; 
  modalRef.componentInstance.fnGetProductInfo(); 
  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
 
   
  });
      
}

private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


private  fnShowErrorMsg(ex){
this.toastr.warning('Internal Error', ex);       
}



}
