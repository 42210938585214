import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { LoginDataService } from './services/dhukan/login-data.service';
import { BnNgIdleService } from 'bn-ng-idle';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewChecked {

  userBO: any = {};
  userrolerightsColl: any = [];  
  userView: any = {};



  title = 'Bigmart';

  islogin: boolean = false;


  // private curSubscription: Subscription | undefined;
  // connection = {
  //   hostname: 'mqtt.bigmart.ae',
  //   port: 1885,    
  //   clean: true, // Retain session
  //   connectTimeout: 4000, // Timeout period
  //   reconnectPeriod: 4000, // Reconnect period
  //   // // Authentication information
  //   // clientId: 'mqttx_597046f4',
  //   // username: 'emqx_test',
  //   // password: 'emqx_test',
  //   protocol: 'mqtt',
  // }

  // receiveNews = '';
  // qosList = [
  //   { label: 0, value: 0 },
  //   { label: 1, value: 1 },
  //   { label: 2, value: 2 },
  // ];
  // client: MqttService | undefined;
  // isConnection = false;
  // subscribeSuccess = false;


  constructor(private appService: LoginDataService,private bnIdle: BnNgIdleService) {
    
   

    this.bnIdle.startWatching(3600).subscribe((res) => {
      if (res) {       
        sessionStorage.removeItem("adtoken");
        this.appService.fnCheckLogin();
        this.appService.islogin = true;
        this.appService.serviceloginscreenChange.next(true);
      }
    })

  
    this.appService.serviceloginscreenChange.subscribe((value) => {
      this.islogin = this.appService.islogin;
    });



  }





  ngOnInit() {
    this.appService.fnCheckLogin();
  }

  ngAfterViewChecked(): void {

    //setTimeout(() =>  this.islogin=this.appService.islogin );

  }

}
