import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-addtaxgroup',
  templateUrl: './addtaxgroup.component.html',
  styleUrls: ['./addtaxgroup.component.css']
})
export class AddtaxgroupComponent implements OnInit {

//Mode Values
isaddmode:boolean=false;
iseditmode:boolean=false;
isdeletemode:boolean=false;
isviewmode:boolean=false;

//Save Validation
submitted:boolean;
successfully:boolean;


//Edit Get Values
taxgroupColl:any=[];
taxgroupid:number=0

taxgroupform=new FormGroup({
  taxgroupname:new FormControl('',Validators.required),
  taxrate:new FormControl(0),         
  taxgroupid:new FormControl(0),      
 inactive:new FormControl(false),     
});

isloading:boolean=false;
isprivilage:boolean=false;  
showProgress:boolean=false;
formname:String="TaxGroup";

_routerSub = Subscription.EMPTY;
_routerparamsSub = Subscription.EMPTY;
_routerLoginSub = Subscription.EMPTY

constructor(private appService: DhukanDataService,private route: ActivatedRoute,
   public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
    this._routerSub = this.route.queryParams.subscribe(params => {
      if(params.id!==undefined){
        this.taxgroupid = params.id
      }  

     });
     this.fnServiceChanges();
}


fnServiceChanges(){
  this._routerLoginSub =  this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}

ngOnInit() {
    try{
      if(this.loginService.fnCheckUser()){ 
        if(this.fnCheckUserPririvilage()){       
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
              
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}
ngOnDestroy(): void {
  if (this._routerSub !== undefined) {
    this._routerSub.unsubscribe();
  }
  if (this._routerparamsSub !== undefined) {
    this._routerparamsSub.unsubscribe();
  }

  if (this._routerLoginSub !== undefined) {
    this._routerLoginSub.unsubscribe();
  }
}
fnOnInitForm(){
   try{
       this.isaddmode=true;
       this.taxgroupform.reset();
       this.fnInitializeTaxGroupFormGroup(); 

       if(this.taxgroupid>0){
         this.fngetTaxGroup();
       }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}



fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.taxgroupid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}


fnInitializeTaxGroupFormGroup(){
   this.taxgroupform.setValue({
      taxgroupname:'',
      taxgroupid:0,      
      taxrate:0,
     inactive:false,      
   })
}

//Get 
fngetTaxGroup() {        
     var data = {
       'taxgroupid':this.taxgroupid,            
     }
     this.appService.getTaxGroup(data)
     .subscribe(
       (res)=>{                  
         this.taxgroupColl=res.json().result;
         var editdata = {
           'taxgroupid':this.taxgroupColl[0].taxgrpid,
           'taxgroupname':this.taxgroupColl[0].taxgrpname, 
           'taxrate':this.taxgroupColl[0].taxrate, 
           'inactive':this.taxgroupColl[0].inactive,          
         }
         this.fnEditMode(editdata);
     },
     (err)=>{          
       this.toastr.error('Error', err);
     })
}

//Save Btn Click
fnSaveBtnClick(){
   try{
     this.submitted=true;
     this.successfully=false;
     //Validate
     if(this.taxgroupform.valid){
         //Check
         var data = {
               'taxgroupid': this.taxgroupid,       
               'taxgroupname':this.taxgroupform.get('taxgroupname').value,            
               'taxrate':this.taxgroupform.get('taxrate').value,
               'inactive': 0,  
               'createdby': this.loginService.fnGetModUserId(),    
               'modifiedby':this.loginService.fnGetModUserId(), 
         }
         this.appService.fnCheckTaxGroup(data).subscribe(
           (res)=>{
               var _checkresult=res.json().result[0].checkfield;
             
               if(_checkresult==="done"){
                     if (this.taxgroupform.get('taxgroupid').value>0){
                       //Update Department
                       this.fnSetTaxGroup(data);
                     }
                     else{
                       //Insert Department
                       this.fnInsertTaxGroup(data);
                     }
               }
               else{        
                     if(_checkresult==="name"){
                       this.taxgroupform.controls['taxgroupname'].setErrors({'notfound': true});
                     }
               }
         },
         (err)=>{
             this.toastr.error('Error', err);           
         });
      }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}

//Insert
fnInsertTaxGroup(data){    
 
   this.appService.fnInsertTaxGroup(data).subscribe(
     (res)=>{
     if(res.json().result!=""){
       this.toastr.success('', "The new TaxGroup has been added successfully.Insert Successfully");

       this.fnInsertActivityLog('INSERT',0,data.taxgrpname);

       this.fnCloseTaxGroup();
     }},
     (err)=>{
       this.toastr.error('Error', err);
     });

}

//Set
fnSetTaxGroup(data){    

this.appService.fnSetTaxGroup(data).subscribe(
 (res)=>{
 if(res.json().result!=""){
     this.toastr.success('', "The TaxGroup has been updated successfully.");

     this.fnInsertActivityLog('EDIT',this.taxgroupid,data.taxgrpname);

     this.fnCloseTaxGroup();        
 }},
 (err)=>{
   this.toastr.error('Error', err);
 });

}

//Close
fnCloseTaxGroup(){
try{
  this.router.navigate(['/TaxGroup']);
}catch(ex){
   this.fnShowErrorMsg(ex);
}
}

private fnEditMode(deptBO){
 this.taxgroupform.setValue({
    taxgroupname:deptBO.taxgroupname,
    taxgroupid:deptBO.taxgroupid,
    taxrate:deptBO.taxrate,
     inactive:deptBO.inactive,   
 });

this.isaddmode=false;
this.iseditmode=true;
this.isdeletemode=false;
this.isviewmode=false;
}

 
fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}


private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


private  fnShowErrorMsg(ex){
this.toastr.warning('Internal Error', ex);       
}


}
