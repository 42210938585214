import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ProductfindComponent } from '../../productfind/productfind.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-addnotification',
  templateUrl: './addnotification.component.html',
  styleUrls: ['./addnotification.component.css']
})
export class AddnotificationComponent implements OnInit {


//Mode Values
isaddmode:boolean=false;
iseditmode:boolean=false;
isdeletemode:boolean=false;
isviewmode:boolean=false;

//Save Validation
submitted:boolean;
successfully:boolean;

//set image
image;
strImage: any;

picurl:string="";
picname:string="";
filterby:string="GROUP";

notificationtypeColl:any=[];

//Edit Get Values
notificationColl:any=[];
notificationid:number=0

notificationform=new FormGroup({         
  title:new FormControl('',Validators.required), 
  notificationname:new FormControl('',Validators.required),
  notificationmsg:new FormControl('',Validators.required),
  notificationtype:new FormControl(''),
  targettype:new FormControl(''),
  target:new FormControl(''),           
  notificationimgpath:new FormControl(''), 
  notificationimage:new FormControl(null), 
  linktype:new FormControl(''), 
  linktargetid:new FormControl(null),  
  notificationid:new FormControl(0),      
  inactive:new FormControl(false),     
  deptid:new FormControl(null), 
  categoryid:new FormControl(null), 
  subcategoryid:new FormControl(null), 
  brandid:new FormControl(null), 
  vendorid:new FormControl(null), 
  pageid:new FormControl(null), 
  offerid:new FormControl(null), 
  contentid:new FormControl(null), 
  productid:new FormControl(null),             
  productname:new FormControl(''), 
  customimgpath:new FormControl(''),   
  customerid: new FormControl(null), 
  productgroupid:new FormControl(null),         
});


linktypeColl:any=[];

deptColl:any=[];
categoryColl:any=[];
subcategoryColl:any=[];
brandColl:any=[];
vendorColl:any=[];
pageColl:any=[];
offerColl:any=[];
contentColl:any=[];
customerColl:any=[];
deviceColl:any=[];
productgroupColl: any = [];

  
targetColl: any = [];
  
isloading:boolean=false;
isprivilage:boolean=false;  
showProgress:boolean=false;
formname:String="Notification";

  toggled: boolean = false;

  _routerSub = Subscription.EMPTY;
_routerparamsSub = Subscription.EMPTY;
_routerLoginSub = Subscription.EMPTY
  
constructor(private appService: DhukanDataService,private route: ActivatedRoute,
   public router: Router,private toastr: ToastrService,private productFindService: NgbModal,private loginService:LoginDataService) { 
    this._routerSub =  this.route.queryParams.subscribe(params => {
      if(params.id!==undefined){
        this.notificationid = params.id
      }  
     });
     this.fnServiceChanges();
}


fnServiceChanges(){
  this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}

ngOnInit() {
    try{
      if(this.loginService.fnCheckUser()){ 
        if(this.fnCheckUserPririvilage()){       
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
              
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}
ngOnDestroy(): void {
  if (this._routerSub !== undefined) {
    this._routerSub.unsubscribe();
  }
  if (this._routerparamsSub !== undefined) {
    this._routerparamsSub.unsubscribe();
  }

  if (this._routerLoginSub !== undefined) {
    this._routerLoginSub.unsubscribe();
  }
}
fnOnInitForm(){
   try{
       this.isaddmode=true;
       this.notificationform.reset();
       this.fnInitializeNotificationFormGroup(); 

       if(this.notificationid>0){
         this.fngetNotification();
       }

       this.fnGetNotificationtype();
        this.fngetLinkType();
     this.fngetCustomer();
     this.fngetArea()
      
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}



fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.notificationid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}


fnInitializeNotificationFormGroup(){
   this.notificationform.setValue({    
    title:'',    
    notificationname:'',
    notificationmsg:'',
    notificationtype:'',  
    targettype:'',
    target:'',
    notificationimgpath:'',
    notificationimage:null,   
    linktype:'', 
    linktargetid:null, 
    notificationid:0, 
    inactive:false,   
    deptid:null, 
    categoryid:null, 
    subcategoryid:null, 
    brandid:null, 
    vendorid:null,
    pageid:null,
    offerid:null,
    contentid:null,
     productid: null, 
     productgroupid:null,
    productname:'', 
    customimgpath:'',
    customerid:null,  
   })
}

fnGetNotificationtype(){
  this.notificationtypeColl=[];
  this.notificationtypeColl.push({name:'INITIAL',displayname:'Initial State'})
  this.notificationtypeColl.push({name:'EXPANDED',displayname:'Expanded State'})
  
}


fngetLinkType() { 
  let _type=[
    {display:"Department",name:"DEPARTMENT"},
    {display:"Category",name:"CATEGORY"},
    {display:"SubCategory",name:"SUBCATEGORY"},
    {display:"Brand",name:"BRAND"},
    {display:"Vendor",name:"VENDOR"},
    {display:"Page",name:"PAGE"},
    {display: "Product", name: "PRODUCT" },
    {display:"ProductGroup",name:"PRODUCTGROUP"},
    {display:"Offer",name:"OFFER"},
    {display:"Html",name:"HTML"},
    {display:"WebUrl",name:"WEBURL"},
    {display:"Offers Page",name:"OFFERS"},
    {display:"Newarrivals Page",name:"NEWARRIVALS"},
    {display:"Dealoftheday Page",name:"DEALOFTHEDAY"},
  ]

  this.linktypeColl=_type;
}

fngetArea() {        
  var data = {
    'areaid':0,            
  }
  this.appService.getArea(data)
  .subscribe(
    (res) => {         
    
      var areaColl = res.json().result;  
      this.targetColl = [];
      
      this.targetColl.push({value:'/topics/all',displayname:'All'})
      for (let abo of areaColl) {        
        this.targetColl.push({value:'/topics/'+abo.areaid,displayname:abo.areaname})        
      }

      
    },
  (err)=>{          
    this.toastr.error('Error', err);
  })
}
  

fngetCustomer() {        
  var data = {
    'customerid':0,            
  }
  this.appService.getCustomerAdmin(data)
  .subscribe(
    (res)=>{                  
      this.customerColl=res.json().result;     
    },
  (err)=>{          
    this.toastr.error('Error', err);
  })
}

fngetCustomerDevice() {  
  this.deviceColl=[];      
  
  if(this.notificationform.get('customerid').value>0){
    this.fnShowProgress();
    var data = {
      'customerid':this.notificationform.get('customerid').value,            
    }
    this.appService.getCustomerAdminView(data)
    .subscribe(      
      (res)=>{ 
        this.fnHideProgress();
        this.deviceColl=res.json().result[6];
   
    },
    (err)=>{   
      this.fnHideProgress();       
      this.toastr.error('Error', err);
    })

  }
  
}



fngetDepartment() {        
  var data = {
    'deptid':0,            
  }
  this.appService.getDepartment(data)
  .subscribe(
    (res)=>{                  
      this.deptColl=res.json().result;     
    },
  (err)=>{          
    this.toastr.error('Error', err);
  })
}

fngetCategory() {        
 
  var data = {
    'categoryid':0,        
    'deptid':0,     
  }


    this.appService.getCategory(data)
    .subscribe(
      (res)=>{                  
        this.categoryColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}

fngetSubCategory() {        
 
  var data = {
    'categoryid':0,        
    'subcategoryid':0,     
  }


    this.appService.getSubCategory(data)
    .subscribe(
      (res)=>{                  
        this.subcategoryColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}

fngetBrand() {        
 
  var data = {
    'brandid':0            
  }


    this.appService.getBrand(data)
    .subscribe(
      (res)=>{                  
        this.brandColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}

fngetVendor() {        
 
  var data = {
    'vendorid':0            
  }

    this.appService.getVendor(data)
    .subscribe(
      (res)=>{                  
        this.vendorColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}

  
fngetProductGroup() {        
 
  var data = {
    'productgroupid':0            
  }
    this.appService.getProductGroup(data)
    .subscribe(
      (res)=>{                  
        this.productgroupColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}


fngetPage() {        
 
  var data = {
    'pageid':0            
  }


    this.appService.getPageByListView(data)
    .subscribe(
      (res)=>{                  
        this.pageColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}

fngetOffer() {        
 
  var data = {
    'offerid':0            
  }


    this.appService.getOfferListView(data)
    .subscribe(
      (res)=>{                  
        this.offerColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}

fngetContent() {        
 
  var data = {
    'contentid':0            
  }


    this.appService.getContentmanagement(data)
    .subscribe(
      (res)=>{                  
        this.contentColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}


fnLinkTypeChange(){
  let type=this.notificationform.get('linktype').value;
  if(type==="DEPARTMENT"){
    this.fngetDepartment();
  }
  else if(type==="CATEGORY"){
    this.fngetCategory();
  }
  else if(type==="SUBCATEGORY"){
    this.fngetSubCategory();
  }
  else if(type==="BRAND"){
    this.fngetBrand();
  }
  else if(type==="VENDOR"){
    this.fngetVendor();
  }
  else if(type==="OFFER"){
    this.fngetOffer();
  }
  else if(type==="HTML"){
    this.fngetContent();
  }
  else if(type==="PAGE"){
    this.fngetPage();
  }
  else if(type==="PRODUCTGROUP"){
    this.fngetProductGroup();
  }
}

fnSelectProduct(){
 
  const modalRef = this.productFindService.open(ProductfindComponent ,{ size: <any>'xl' });
  modalRef.componentInstance.doctype ="SELECT PRODUCT" ;

  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

    for(let wbo of receivedEntry){

      this.notificationform.patchValue({ 
        productid:wbo.skuid,        
        productname:wbo.productname,
      });

    }
  });

}


//Get 
fngetNotification() {        
     var data = {
       'notificationid':this.notificationid,            
     }
     this.appService.getNotification(data)
     .subscribe(
       (res)=>{                  
         this.notificationColl=res.json().result;
         var editdata = this.notificationColl[0];
         this.fnEditMode(editdata);
     },
     (err)=>{          
       this.toastr.error('Error', err);
     })
}

//Save Btn Click
fnSaveBtnClick(type){
   try{
     this.submitted=true;
     this.successfully=false;
     //Validate
     if(this.notificationform.valid){

         //Check
         var data = {
               'notificationid': this.notificationid,                              
               'title':this.notificationform.get('title').value,   
               'notificationname':this.notificationform.get('notificationname').value,   
               'notificationmsg':this.notificationform.get('notificationmsg').value,   
               'notificationtype':this.notificationform.get('notificationtype').value,                  
               'targettype':this.notificationform.get('targettype').value,   
               'target':this.notificationform.get('target').value,       
               'notificationimage': this.strImage,
               'notificationimgpath': this.picurl,    
               'linktype':this.notificationform.get('linktype').value,    
               'linktargetid':this.fnGetLinkTypeId(this.notificationform.get('linktype').value),  
               'customimgpath':this.notificationform.get('customimgpath').value,
               'customerid':this.notificationform.get('customerid').value,
               'inactive': 0,  
               'createdby': this.loginService.fnGetModUserId(),    
               'modifiedby':this.loginService.fnGetModUserId(), 
               'action':type,
               
         }
         this.appService.fnCheckPaymode(data).subscribe(
           (res)=>{
               var _checkresult=res.json().result[0].checkfield;
             
               if(_checkresult==="done"){
                     if (this.notificationform.get('notificationid').value>0){
                       //Update Department
                       this.fnSetNotification(data);
                     }
                     else{
                       //Insert Department
                       this.fnInsertNotification(data);
                     }
               }
               else{        
                     if(_checkresult==="name"){
                       this.notificationform.controls['title'].setErrors({'notfound': true});
                     }
               }
         },
         (err)=>{
             this.toastr.error('Error', err);           
         });
      }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}

//Insert
fnInsertNotification(data){    
  this.fnShowProgress();
   this.appService.fnInsertNotification(data).subscribe(
     (res)=>{
     if(res.json().result!=""){
        this.fnHideProgress(); 
          this.toastr.success('', "The new notification has been added successfully.Insert Successfully");

          this.fnInsertActivityLog('INSERT',0,data.title);

        this.fnCloseNotification();
  
      
     }},
     (err)=>{
       this.toastr.error('Error', err);
     });

}

//Set
fnSetNotification(data){    
  this.fnShowProgress();
this.appService.fnSetNotification(data).subscribe(
 (res)=>{
 if(res.json().result!=""){

  this.fnInsertActivityLog('EDIT',this.notificationid,data.title);
    

  if(data.action==='SAVE'){
    this.fnHideProgress(); 
    this.toastr.success('', "The new notification has been added successfully.Insert Successfully");


    this.fnCloseNotification();
  }
  else{
    this.fnSend();
  }
     
 }},
 (err)=>{
   this.fnHideProgress(); 
   this.toastr.error('Error', err);
 });

}


fnSend(){
  this.fnShowProgress();
  var senddata = {
    'notificationid':this.notificationid,            
  }
  this.appService.getNotification(senddata)
  .subscribe(
    (res)=>{     
      this.notificationColl=[];             
      this.notificationColl=res.json().result;
      var editdata = this.notificationColl[0];

      this.fnSendNotificationMessage(editdata)
      
  },
  (err)=>{     
    this.fnHideProgress();     
    this.toastr.error('Error', err);
  })
}

fnSendNotificationMessage(data){

  this.appService.fnSendNotification(data,this.deviceColl).subscribe(
    (res)=>{
      
    if(res.json().result!=""){      
      this.fnHideProgress();     
     if(data.notificationid>0){
       this.toastr.success('', "The  notification has been update successfully");   
     }
     else{
      this.toastr.success('', "The new notification has been added successfully.Insert Successfully");   
    }

    this.fnInsertActivityLog('SENDNOTIFICATION',this.notificationid,data.title);

     this.fnCloseNotification();
        
    }},
    (err)=>{
      this.fnHideProgress();     
      this.toastr.error('Error', err);
    });
}

fnGetLinkTypeId(type){
  if(type==="DEPARTMENT"){
    return this.notificationform.get('deptid').value;
  }
  else if(type==="CATEGORY"){
    return this.notificationform.get('categoryid').value;
  }
  else if(type==="SUBCATEGORY"){
    return this.notificationform.get('subcategoryid').value;
  }
  else if(type==="BRAND"){
    return this.notificationform.get('brandid').value;
  }
  else if(type==="PRODUCT"){
    return this.notificationform.get('productid').value;
  } 
  else if(type==="PRODUCTGROUP"){
    return this.notificationform.get('productgroupid').value;
  } 
  else if(type==="VENDOR"){
    return this.notificationform.get('vendorid').value;
  } 
  else if(type==="PAGE"){
    return this.notificationform.get('pageid').value;
  } 
  else if(type==="OFFER"){
    return this.notificationform.get('offerid').value;
  } 
  else if(type==="HTML"){
    return this.notificationform.get('contentid').value;
  } 
  else {
    return null;
  }
}



 


//Close
fnCloseNotification(){
try{
  this.router.navigate(['/Notification']);
}catch(ex){
   this.fnShowErrorMsg(ex);
}
}

private fnEditMode(deptBO){

  
 this.notificationform.setValue({
      title:deptBO.title,          
      notificationname:deptBO.notificationname,
      notificationmsg:deptBO.notificationmsg,
      notificationtype:deptBO.notificationtype,      
      targettype:deptBO.targettype,
      target:deptBO.target,
      notificationimgpath:deptBO.notificationimgpath,  
      notificationimage:null, 
      notificationid:deptBO.notificationid,
      linktype:deptBO.linktype, 
      linktargetid:deptBO.linktargetid, 
      customerid:deptBO.customerid, 
      customimgpath:deptBO.customimgpath, 
      inactive:deptBO.inactive,   
      deptid:null, 
      categoryid:null, 
      subcategoryid:null, 
      brandid:null,  
      vendorid:null, 
      pageid:null, 
      contentid:null, 
      offerid:null, 
      productid: null,  
      productgroupid:null,
      productname:deptBO.productname,   
 });

 this.picurl =deptBO.notificationimgpath; 

 this.fngetCustomerDevice();

 if(deptBO.customerid>0){
   this.filterby='CUSTOMER';
 }



 this.fnLinkTypeChange();

 
 let type=this.notificationform.get('linktype').value;
  if(type==="DEPARTMENT"){
    this.notificationform.patchValue({deptid:deptBO.linktargetid});
  }
  else if(type==="CATEGORY"){
    this.notificationform.patchValue({categoryid:deptBO.linktargetid});
  }
  else if(type==="SUBCATEGORY"){
    this.notificationform.patchValue({subcategoryid:deptBO.linktargetid});
  } 
  else if(type==="BRAND"){
    this.notificationform.patchValue({brandid:deptBO.linktargetid});
  } 
  else if(type==="VENDOR"){
    this.notificationform.patchValue({vendorid:deptBO.linktargetid});
  } 
  else if(type==="PAGE"){
    this.notificationform.patchValue({pageid:deptBO.linktargetid});
  } 
  else if(type==="HTML"){
    this.notificationform.patchValue({contentid:deptBO.linktargetid});
  } 
  else if(type==="OFFER"){
    this.notificationform.patchValue({offerid:deptBO.linktargetid});
  } 
  else if(type==="PRODUCT"){
    this.notificationform.patchValue({productid:deptBO.linktargetid});
  } 
  else if(type==="PRODUCTGROUP"){
    this.notificationform.patchValue({productgroupid:deptBO.linktargetid});
  } 

  this.isaddmode=false;
  this.iseditmode=true;
  this.isdeletemode=false;
  this.isviewmode=false;
}

//Image Import
  changeListener($event): void {
  try{
     this.readThis($event.target);
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
  }
  
  readThis(inputValue: any): void {
  
   var file: File = inputValue.files[0];
   var myReader: FileReader = new FileReader();
  
   myReader.onloadend = (e) => {
       this.image = myReader.result;
       this.strImage = this.image.split(',')[1];
   }
   myReader.readAsDataURL(file);
  }
  
  removeimage(){
   this.image =null;
   this.strImage =null;
   this.picurl="";
  }
 
  
  fnOpenFileBrowser(event:any){
  
    event.preventDefault();
  
    let element:HTMLElement=document.getElementById('image') as HTMLElement;
    element.click();
  
  }

  
  handleSelection(event) {
    console.log(event);
    this.notificationform.patchValue({
      'notificationmsg':this.notificationform.get('notificationmsg').value+event.char
    })


    console.log(JSON.stringify(this.emojiToUnicode(this.notificationform.get('notificationmsg').value)));
  }


   emojiToUnicode(s) {
    return s.match(/\ud83c[\udf00-\udfff]|\ud83d[\udc00-\ude4f]|\ud83d[\ude80-\udeff]/g)
            .map( e => "\\u" + e.charCodeAt(0).toString(16) + "\\u" + e.charCodeAt(1).toString(16))
    }

    

 fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}



private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}

private  fnShowErrorMsg(ex){
this.toastr.warning('Internal Error', ex);       
}





}
