import { Component, OnInit, NgZone } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { MapsAPILoader } from '@agm/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-addorderaddress',
  templateUrl: './addorderaddress.component.html',
  styleUrls: ['./addorderaddress.component.css']
})
export class AddorderaddressComponent implements OnInit {


//Mode Values
isaddmode:boolean=false;
iseditmode:boolean=false;
isdeletemode:boolean=false;
isviewmode:boolean=false;

//Save Validation
submitted:boolean;
successfully:boolean;

areaColl:any=[]; 

//Edit Get Values
orderaddressColl:any=[];
orderaddressBO:any={};
orderaddressid:number=0
orderid:number=0

private geoCoder;

orderaddressform=new FormGroup({         
  firstname:new FormControl('',Validators.required),           
  mobile:new FormControl('',Validators.required), 
  houseno:new FormControl('',Validators.required), 
  street:new FormControl('',Validators.required), 
  areaid:new FormControl(0,Validators.required), 
  landmark:new FormControl('',Validators.required), 
  latitude:new FormControl(null,Validators.required), 
  longitude:new FormControl(null,Validators.required), 
  maplocationname:new FormControl('',Validators.required),
  orderaddressid:new FormControl(0),        
});

mapType = 'roadmap';

_routerSub = Subscription.EMPTY;
_routerparamsSub = Subscription.EMPTY;
_routerLoginSub = Subscription.EMPTY

constructor(private appService: DhukanDataService,private route: ActivatedRoute,
   public router: Router,private toastr: ToastrService,private loginService:LoginDataService,private mapsAPILoader: MapsAPILoader,  private ngZone: NgZone) { 
    this._routerSub = this.route.queryParams.subscribe(params => {
      if(params.id!==undefined){
        this.orderaddressid = params.id
      }  
      if(params.oid!==undefined){
        this.orderid = params.oid
      }  

     
      
     });
}

ngOnInit() {
   try{
       this.isaddmode=true;
       this.orderaddressform.reset();
       this.fnInitializeorderaddressFormGroup(); 

       if(this.orderaddressid>0){
         this.fngetorderaddress();
       }
       this.fngetArea();


       this.mapsAPILoader.load().then(() => {        
        this.geoCoder = new google.maps.Geocoder;       
        });

      
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}
ngOnDestroy(): void {
  if (this._routerSub !== undefined) {
    this._routerSub.unsubscribe();
  }
  if (this._routerparamsSub !== undefined) {
    this._routerparamsSub.unsubscribe();
  }

  if (this._routerLoginSub !== undefined) {
    this._routerLoginSub.unsubscribe();
  }
}
fnInitializeorderaddressFormGroup(){
   this.orderaddressform.setValue({
    firstname:'',
    mobile:'',    
    houseno:'',    
    street:'',    
    areaid:null,    
    landmark:'',  
    latitude:null,
    longitude:null,  
    maplocationname:'',
    orderaddressid:0,           
   })
}




//Get 
fngetorderaddress() {        
     var data = {
       'orderaddressid':this.orderaddressid,            
     }
     this.appService.getOrderAddressbyOrderAddressid(data)
     .subscribe(
       (res)=>{               
         console.log(res.json())   
         this.orderaddressColl=res.json().result;
         this.orderaddressBO=this.orderaddressColl[0];

         var editdata = {
           'orderaddressid':this.orderaddressColl[0].orderaddressid,
           'firstname':this.orderaddressColl[0].firstname, 
           'mobile':this.orderaddressColl[0].mobile, 
           'houseno':this.orderaddressColl[0].houseno,          
           'street':this.orderaddressColl[0].street,          
           'areaid':this.orderaddressColl[0].areaid,          
           'landmark':this.orderaddressColl[0].landmark,   
           'latitude':this.orderaddressColl[0].latitude,    
           'longitude':this.orderaddressColl[0].longitude,   
           'maplocationname':this.orderaddressColl[0].maplocationname,          
         }
         this.fnEditMode(editdata);
     },
     (err)=>{          
       this.toastr.error('Error', err);
     })
}

fngetArea() {        
  var data = {
    'areaid':0,            
  }
  this.appService.getArea(data)
  .subscribe(
    (res)=>{                  
      this.areaColl=res.json().result;         
  },
  (err)=>{          
    this.toastr.error('Error', err);
  })
}



//Save Btn Click
fnSaveBtnClick(){
   try{
     this.submitted=true;
     this.successfully=false;
     //Validate
     if(this.orderaddressform.valid){
         //Check
         var data = {
               'orderaddressid': this.orderaddressid,       
               'firstname':this.orderaddressform.get('firstname').value,            
               'mobile':this.orderaddressform.get('mobile').value,  
               'houseno':this.orderaddressform.get('houseno').value,  
               'street':this.orderaddressform.get('street').value,  
               'areaid':this.orderaddressform.get('areaid').value,  
               'landmark':this.orderaddressform.get('landmark').value,  
               'latitude':this.orderaddressform.get('latitude').value,  
               'longitude':this.orderaddressform.get('longitude').value,  
               'maplocationname':this.orderaddressform.get('maplocationname').value,  
               'inactive': 0,  
               'createdby': this.loginService.fnGetModUserId(),    
               'modifiedby':this.loginService.fnGetModUserId(), 
         }
         this.fnSetorderaddress(data);
      }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}

//Set
fnSetorderaddress(data){    

this.appService.fnSetOrderAddress(data).subscribe(
 (res)=>{
 if(res.json().result!=""){
     this.toastr.success('', "The orderaddress has been updated successfully.");

     this.fnInsertActivityLog('EDIT',this.orderid,data.orderaddressid);

     this.fnCloseorderaddress();        
 }},
 (err)=>{
   this.toastr.error('Error', err);
 });

}

//Close
fnCloseorderaddress(){
try{
  let navigationExtras: NavigationExtras = {
    queryParams: {
      'id': this.orderid,
    }
    }

  this.router.navigate(['/AddOrders'], navigationExtras);
}catch(ex){
   this.fnShowErrorMsg(ex);
}
}

private fnEditMode(deptBO){

 this.orderaddressform.setValue({
      firstname:deptBO.firstname,
      mobile:deptBO.mobile,      
      houseno:deptBO.houseno,      
      street:deptBO.street,      
      areaid:deptBO.areaid,      
      landmark:deptBO.landmark, 
      latitude:deptBO.latitude, 
      longitude:deptBO.longitude,     
      maplocationname:deptBO.maplocationname, 
      orderaddressid:deptBO.orderaddressid,       
 });


this.isaddmode=false;
this.iseditmode=true;
this.isdeletemode=false;
this.isviewmode=false;
}


markerDragEnd(lat, lng, $event) {


  this.orderaddressform.patchValue({
    latitude:$event.coords.lat, 
    longitude: $event.coords.lng, 
  })


  this.getAddress($event.coords.lat,$event.coords.lng);
  console.log($event.coords.lat)
  console.log($event.coords.lng)
}



getAddress(latitude, longitude) {
  
  if(latitude<=0){
    return;
  }
  if(longitude<=0){
    return;
  }
  
  this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
   // console.log(results);
   // console.log(status);
    if (status === 'OK') {
      if (results[0]) {              
        var res=results[0];         

        this.orderaddressform.patchValue({
          maplocationname:res.formatted_address,           
        })

      } 
    } else {
      //window.alert('Geocoder failed due to: ' + status);
    }

  });
}


fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:"ORDERADDRESS",
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}


private  fnShowErrorMsg(ex){
this.toastr.warning('Internal Error', ex);       
}



}
