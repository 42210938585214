import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-addcart',
  templateUrl: './addcart.component.html',
  styleUrls: ['./addcart.component.css']
})
export class AddcartComponent implements OnInit {

//Mode Values
isaddmode:boolean=false;
iseditmode:boolean=false;
isdeletemode:boolean=false;
isviewmode:boolean=false;

//Save Validation
submitted:boolean;
successfully:boolean;


//Edit Get Values
cartColl:any=[];
cartdetailsColl:any=[];
cartBO:any={};
cartid: number = 0;
ptype: string = "";
customerid: number = 0;
  
isloading:boolean=false;
isprivilage:boolean=false;  
showProgress:boolean=false;
formname:String="Cart";

_routerSub = Subscription.EMPTY;
_routerparamsSub = Subscription.EMPTY;
_routerLoginSub = Subscription.EMPTY

constructor(private appService: DhukanDataService,private route: ActivatedRoute,
   public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
    this._routerSub = this.route.queryParams.subscribe(params => {
      if(params.id!==undefined){
        this.cartid = params.id
       }  
       if(params.ftype!==undefined){
        this.ptype = params.ftype
       } 
       if(params.customerid!==undefined){
        this.customerid = params.customerid
       } 
       
     });
     this.fnServiceChanges();
}


fnServiceChanges(){
  this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}

ngOnInit() {
    try{
      if(this.loginService.fnCheckUser()){ 
        if(this.fnCheckUserPririvilage()){       
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
              
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}
ngOnDestroy(): void {
  if (this._routerSub !== undefined) {
    this._routerSub.unsubscribe();
  }
  if (this._routerparamsSub !== undefined) {
    this._routerparamsSub.unsubscribe();
  }

  if (this._routerLoginSub !== undefined) {
    this._routerLoginSub.unsubscribe();
  }
}
fnOnInitForm(){
   try{
       this.isaddmode=true;
   

       if(this.cartid>0){
         this.fngetCartByCartid();
       }

   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}



fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.cartid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'VIEW')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'VIEW')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}





//Get 
fngetCartByCartid() {        
     var data = {
       'cartid':this.cartid,            
     }
     this.appService.getCartByCartid(data)
     .subscribe(
       (res)=>{                  
         this.cartColl=res.json().result[0];
         this.cartdetailsColl=res.json().result[1];

         if(this.cartColl.length>0){
           this.cartBO=this.cartColl[0]
         }

     },
     (err)=>{          
       this.toastr.error('Error', err);
     })
}


//Close
fnCloseCart(){
  try {
  
    if (this.ptype === 'CUSTOMER') {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': this.customerid,
        }
        }
  
      this.router.navigate(['/CustomerView'], navigationExtras);
    }
    else {
      this.router.navigate(['/Cart']);
    }
  
}catch(ex){
   this.fnShowErrorMsg(ex);
}
}

private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}

private  fnShowErrorMsg(ex){
this.toastr.warning('Internal Error', ex);       
}





}
