import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-addcertificate',
  templateUrl: './addcertificate.component.html',
  styleUrls: ['./addcertificate.component.css']
})
export class AddcertificateComponent implements OnInit {

 

  //Mode Values
  isaddmode:boolean=false;
  iseditmode:boolean=false;
  isdeletemode:boolean=false;
  isviewmode:boolean=false;

  //Save Validation
  submitted:boolean;
  successfully:boolean;

  //set image
  image;
  strImage: any;

  //Edit Get Values
  certificateColl:any=[];
  certificateid:number=0
  picurl:string="";
  picname:string="";

  certificateform=new FormGroup({
    certificatename:new FormControl('',Validators.required),         
    certificateid:new FormControl(0),
    certificatepic:new FormControl(''), 
    certificateimage:new FormControl(null),        
   inactive:new FormControl(false),     
  });

  isloading:boolean=false;
isprivilage:boolean=false;  
showProgress:boolean=false;
formname:String="Certificate";

_routerSub = Subscription.EMPTY;
_routerparamsSub = Subscription.EMPTY;
_routerLoginSub = Subscription.EMPTY

constructor(private appService: DhukanDataService,private route: ActivatedRoute,
     public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
      this._routerSub =  this.route.queryParams.subscribe(params => {
        
         if(params.id!==undefined){
          this.certificateid = params.id
        }    

       });

       this.fnServiceChanges();
}


fnServiceChanges(){
  this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}

 

ngOnInit() {
  try{
    if(this.loginService.fnCheckUser()){ 
      if(this.fnCheckUserPririvilage()){       
        this.isloading=true;
        this.fnOnInitForm();
      }
      else{
        this.fnUserFailedForm();
      }
    }
    else{
      this.loginService.fnGetUserRoleList();
    }
            
  }catch(ex){
    this.fnShowErrorMsg(ex);
  }
}
ngOnDestroy(): void {
  if (this._routerSub !== undefined) {
    this._routerSub.unsubscribe();
  }
  if (this._routerparamsSub !== undefined) {
    this._routerparamsSub.unsubscribe();
  }

  if (this._routerLoginSub !== undefined) {
    this._routerLoginSub.unsubscribe();
  }
}
fnOnInitForm(){

     try{
         this.isaddmode=true;
         this.certificateform.reset();
         this.fnInitializeCertificateFormGroup(); 

         if(this.certificateid>0){
           this.fngetCertificate();
         }
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
}


fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.certificateid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}

fnInitializeCertificateFormGroup(){
     this.certificateform.setValue({
      certificatename:'',      
      certificateid:0,
      certificatepic:'',
      certificateimage:null,
       inactive:false,      
     })
}

//Get 
fngetCertificate() {        
       var data = {
         'certificateid':this.certificateid,            
       }
       this.appService.getCertificate(data)
       .subscribe(
         (res)=>{                  
           this.certificateColl=res.json().result;
           var editdata = {
             'certificateid':this.certificateColl[0].certificateid,
             'certificatename':this.certificateColl[0].certificatename,              
             'certificatepic':this.certificateColl[0].certificatepic,              
             'inactive':this.certificateColl[0].inactive,
             'picurl':this.certificateColl[0].picurl,                                    
           }
           this.fnEditMode(editdata);
       },
       (err)=>{          
         this.toastr.error('Error', err);
       })
}

//Save Btn Click
fnSaveBtnClick(){
     try{
       this.submitted=true;
       this.successfully=false;
       //Validate
       if(this.certificateform.valid){
           //Check
           var data = {
                 'certificateid': this.certificateid,       
                 'certificatename':this.certificateform.get('certificatename').value,                                         
                 'inactive': 0,  
                 'createdby': this.loginService.fnGetModUserId(),    
                 'modifiedby':this.loginService.fnGetModUserId(),    
                 'certificateimage': this.strImage,
                 'certificatepic': this.picurl,                 
           }
           this.appService.fnCheckCertificate(data).subscribe(
             (res)=>{
                 var _checkresult=res.json().result[0].checkfield;
               
                 if(_checkresult==="done"){
                       if (this.certificateform.get('certificateid').value>0){
                         //Update Department
                         this.fnSetCertificate(data);
                       }
                       else{
                         //Insert Department
                         this.fnInsertCertificate(data);
                       }
                 }
                 else{        
                       if(_checkresult==="name"){
                         this.certificateform.controls['certificatename'].setErrors({'notfound': true});
                       }
                 }
           },
           (err)=>{
               this.toastr.error('Error', err);           
           });
        }
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
}

//Insert
fnInsertCertificate(data){    
   
     this.appService.fnInsertCertificate(data).subscribe(
       (res)=>{
       if(res.json().result!=""){
         this.toastr.success('', "The new certificate has been added successfully.Insert Successfully");

         this.fnInsertActivityLog('INSERT',0,data.certificatename);

         this.fnCloseCertificate();
       }},
       (err)=>{
         this.toastr.error('Error', err);
       });

}

//Set
fnSetCertificate(data){    

 this.appService.fnSetCertificate(data).subscribe(
   (res)=>{
   if(res.json().result!=""){
       this.toastr.success('', "The certificate has been updated successfully.");

       this.fnInsertActivityLog('EDIT',this.certificateid,data.certificatename);

       this.fnCloseCertificate();        
   }},
   (err)=>{
     this.toastr.error('Error', err);
   });

}

//Close
fnCloseCertificate(){
 try{
    this.router.navigate(['/Certificate']);
 }catch(ex){
     this.fnShowErrorMsg(ex);
 }
}

private fnEditMode(deptBO){
   this.certificateform.setValue({
    certificatename:deptBO.certificatename,
    certificateid:deptBO.certificateid,
    certificatepic:deptBO.certificatepic,
    inactive:deptBO.inactive, 
    certificateimage:null,     
  });
   this.picurl =deptBO.certificatepic;   

 this.isaddmode=false;
 this.iseditmode=true;
 this.isdeletemode=false;
 this.isviewmode=false;
}

//Image Import
changeListener($event): void {
try{
   this.readThis($event.target);
 }catch(ex){
   this.fnShowErrorMsg(ex);
 }
}

readThis(inputValue: any): void {

 var file: File = inputValue.files[0];
 var myReader: FileReader = new FileReader();

 myReader.onloadend = (e) => {
     this.image = myReader.result;
     this.strImage = this.image.split(',')[1];
 }
 myReader.readAsDataURL(file);
}

removeimage(){
 this.image =null;
 this.strImage =null;
 this.picurl="";
}


fnOpenFileBrowser(event:any){

  event.preventDefault();

  let element:HTMLElement=document.getElementById('image') as HTMLElement;
  element.click();

}

   
fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}


private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


private  fnShowErrorMsg(ex){
 this.toastr.warning('Internal Error', ex);       
}




}
