import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-adddeliveryslot',
  templateUrl: './adddeliveryslot.component.html',
  styleUrls: ['./adddeliveryslot.component.css']
})
export class AdddeliveryslotComponent implements OnInit {

//Mode Values
isaddmode:boolean=false;
iseditmode:boolean=false;
isdeletemode:boolean=false;
isviewmode:boolean=false;

//Save Validation
submitted:boolean;
successfully:boolean;


//Edit Get Values
deliverytypeColl:any=[];
deliveryslotColl:any=[];
slotid:number=0

deliveryslotform=new FormGroup({
  slotname:new FormControl('',Validators.required),         
  deliverytypeid:new FormControl(null,Validators.required),      
  timefrom:new FormControl(null),
  timeto:new FormControl(null),     
  maxorder:new FormControl(0),      
  slotid:new FormControl(0),      
 inactive:new FormControl(false),     
});

isloading:boolean=false;
isprivilage:boolean=false;  
showProgress:boolean=false;
formname:String="DeliverySlot";

_routerSub = Subscription.EMPTY;
_routerparamsSub = Subscription.EMPTY;
_routerLoginSub = Subscription.EMPTY

constructor(private appService: DhukanDataService,private route: ActivatedRoute,
   public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
    this._routerSub = this.route.queryParams.subscribe(params => {
        if(params.id!==undefined){
          this.slotid = params.id
        }  
     });

     this.fnServiceChanges();
}


fnServiceChanges(){
  this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}

ngOnInit() {
    try{
      if(this.loginService.fnCheckUser()){ 
        if(this.fnCheckUserPririvilage()){       
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
              
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}
ngOnDestroy(): void {
  if (this._routerSub !== undefined) {
    this._routerSub.unsubscribe();
  }
  if (this._routerparamsSub !== undefined) {
    this._routerparamsSub.unsubscribe();
  }

  if (this._routerLoginSub !== undefined) {
    this._routerLoginSub.unsubscribe();
  }
}
fnOnInitForm(){

   try{
       this.isaddmode=true;
       this.deliveryslotform.reset();
       this.fnInitializeDeliverySlotFormGroup(); 

       if(this.slotid>0){
         this.fngetDeliverySlot();
       }

       this.fngetDeliveryType();
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}


fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.slotid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}

fnInitializeDeliverySlotFormGroup(){
   this.deliveryslotform.setValue({
    slotname:'',
    slotid:0,      
    deliverytypeid:null,
    timefrom:null,
    timeto:null,
    maxorder:null,
     inactive:false,      
   })
}

//Get 
fngetDeliverySlot() {        
     var data = {
       'slotid':this.slotid,            
     }
     this.appService.getDeliverySlot(data)
     .subscribe(
       (res)=>{                  
         this.deliveryslotColl=res.json().result;
         var editdata = {
           'slotid':this.deliveryslotColl[0].slotid,
           'slotname':this.deliveryslotColl[0].slotname, 
           'deliverytypeid':this.deliveryslotColl[0].deliverytypeid, 
           'timefrom':this.deliveryslotColl[0].timefrom, 
           'timeto':this.deliveryslotColl[0].timeto, 
           'maxorder':this.deliveryslotColl[0].maxorders, 
           'inactive':this.deliveryslotColl[0].inactive,          
         }
         this.fnEditMode(editdata);
     },
     (err)=>{          
       this.toastr.error('Error', err);
     })
}

//Get 
fngetDeliveryType() {        
  var data = {
    'deliverytypeid':0,            
  }
  this.appService.getDeliveryType(data)
  .subscribe(
    (res)=>{                  
      this.deliverytypeColl=res.json().result;     
    },
  (err)=>{          
    this.toastr.error('Error', err);
  })
}

//Save Btn Click
fnSaveBtnClick(){
   try{
     this.submitted=true;
     this.successfully=false;
     //Validate
     if(this.deliveryslotform.valid){
         //Check
         var data = {
               'slotid': this.slotid,       
               'slotname':this.deliveryslotform.get('slotname').value,            
               'deliverytypeid':this.deliveryslotform.get('deliverytypeid').value,
               'timefrom':this.deliveryslotform.get('timefrom').value,
               'timeto':this.deliveryslotform.get('timeto').value,
               'maxorder':this.deliveryslotform.get('maxorder').value,
               'inactive': 0,  
               'createdby': this.loginService.fnGetModUserId(),    
               'modifiedby':this.loginService.fnGetModUserId(), 
         }
         this.appService.fnCheckDeliverySlot(data).subscribe(
           (res)=>{
               var _checkresult=res.json().result[0].checkfield;
             
               if(_checkresult==="done"){
                     if (this.deliveryslotform.get('slotid').value>0){
                       //Update Department
                       this.fnSetDeliverySlot(data);
                     }
                     else{
                       //Insert Department
                       this.fnInsertDeliverySlot(data);
                     }
               }
               else{        
                     if(_checkresult==="name"){
                       this.deliveryslotform.controls['slotname'].setErrors({'notfound': true});
                     }
               }
         },
         (err)=>{
             this.toastr.error('Error', err);           
         });
      }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}

//Insert
fnInsertDeliverySlot(data){    
 
   this.appService.fnInsertDeliverySlot(data).subscribe(
     (res)=>{
     if(res.json().result!=""){
       this.toastr.success('', "The new DeliverySlot has been added successfully.Insert Successfully");

       this.fnInsertActivityLog('INSERT',0,data.slotname);

       this.fnCloseDeliverySlot();
     }},
     (err)=>{
       this.toastr.error('Error', err);
     });

}

//Set
fnSetDeliverySlot(data){    

this.appService.fnSetDeliverySlot(data).subscribe(
 (res)=>{
 if(res.json().result!=""){
     this.toastr.success('', "The DeliverySlot has been updated successfully.");
     
     this.fnInsertActivityLog('EDIT',this.slotid,data.slotname);

     this.fnCloseDeliverySlot();        
 }},
 (err)=>{
   this.toastr.error('Error', err);
 });

}

//Close
fnCloseDeliverySlot(){
try{
  this.router.navigate(['/DeliverySlot']);
}catch(ex){
   this.fnShowErrorMsg(ex);
}
}

private fnEditMode(deptBO){
console.log(deptBO);

 this.deliveryslotform.setValue({
    slotname:deptBO.slotname,
    slotid:deptBO.slotid,
    deliverytypeid:deptBO.deliverytypeid,
    timefrom:deptBO.timefrom,
    timeto:deptBO.timeto,
    maxorder:deptBO.maxorder,
    inactive:deptBO.inactive,   
 });

this.isaddmode=false;
this.iseditmode=true;
this.isdeletemode=false;
this.isviewmode=false;
}

  
fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}


private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


private  fnShowErrorMsg(ex){
this.toastr.warning('Internal Error', ex);       
}





}
