import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-addstatus',
  templateUrl: './addstatus.component.html',
  styleUrls: ['./addstatus.component.css']
})
export class AddstatusComponent implements OnInit {
//Mode Values
isaddmode:boolean=false;
iseditmode:boolean=false;
isdeletemode:boolean=false;
isviewmode:boolean=false;

//Save Validation
submitted:boolean;
successfully:boolean;


//Edit Get Values
statusColl:any=[];
statusid:number=0

statusform=new FormGroup({
  status:new FormControl('',Validators.required),         
  statusid:new FormControl(0),      
 inactive:new FormControl(false),     
});

isloading:boolean=false;
isprivilage:boolean=false;  
showProgress:boolean=false;
  formname: String = "Status";
  

  _routerSub = Subscription.EMPTY;
  _routerparamsSub = Subscription.EMPTY;
  _routerLoginSub = Subscription.EMPTY

constructor(private appService: DhukanDataService,private route: ActivatedRoute,
   public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
    this._routerSub = this.route.queryParams.subscribe(params => {
      if(params.id!==undefined){
        this.statusid = params.id
      }  

     });

     this.fnServiceChanges();
}


fnServiceChanges(){
  this._routerLoginSub = this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}

ngOnInit() {
    try{
      if(this.loginService.fnCheckUser()){ 
        if(this.fnCheckUserPririvilage()){       
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
              
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}
ngOnDestroy(): void {
  if (this._routerSub !== undefined) {
    this._routerSub.unsubscribe();
  }
  if (this._routerparamsSub !== undefined) {
    this._routerparamsSub.unsubscribe();
  }

  if (this._routerLoginSub !== undefined) {
    this._routerLoginSub.unsubscribe();
  }
}
fnOnInitForm(){
   try{
       this.isaddmode=true;
       this.statusform.reset();
       this.fnInitializeStatusFormGroup(); 

       if(this.statusid>0){
         this.fngetStatus();
       }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}


fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.statusid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}

fnInitializeStatusFormGroup(){
   this.statusform.setValue({
      status:'',
      statusid:0,      
     inactive:false,      
   })
}

//Get 
fngetStatus() {        
     var data = {
       'statusid':this.statusid,            
     }
     this.appService.getStatus(data)
     .subscribe(
       (res)=>{                  
         this.statusColl=res.json().result;
         var editdata = {
           'statusid':this.statusColl[0].statusid,
           'status':this.statusColl[0].status, 
           'inactive':this.statusColl[0].inactive,          
         }
         this.fnEditMode(editdata);
     },
     (err)=>{          
       this.toastr.error('Error', err);
     })
}

//Save Btn Click
fnSaveBtnClick(){
   try{
     this.submitted=true;
     this.successfully=false;
     //Validate
     if(this.statusform.valid){
         //Check
         var data = {
               'statusid': this.statusid,       
               'status':this.statusform.get('status').value,            
               'customerdisplay':0,
               'inactive': 0,  
               'createdby': this.loginService.fnGetModUserId(),    
               'modifiedby': this.loginService.fnGetModUserId(),    
         }
         this.appService.fnCheckStatus(data).subscribe(
           (res)=>{
               var _checkresult=res.json().result[0].checkfield;
             
               if(_checkresult==="done"){
                     if (this.statusform.get('statusid').value>0){
                       //Update Department
                       this.fnSetStaus(data);
                     }
                     else{
                       //Insert Department
                       this.fnInsertStatus(data);
                     }
               }
               else{        
                     if(_checkresult==="name"){
                       this.statusform.controls['status'].setErrors({'notfound': true});
                     }
               }
         },
         (err)=>{
             this.toastr.error('Error', err);           
         });
      }
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
}

//Insert
fnInsertStatus(data){    
 
   this.appService.fnInsertStatus(data).subscribe(
     (res)=>{
     if(res.json().result!=""){
       this.toastr.success('', "The new status has been added successfully.Insert Successfully");

       this.fnInsertActivityLog('INSERT',0,data.status);

       this.fnCloseStatus();
     }},
     (err)=>{
       this.toastr.error('Error', err);
     });

}

//Set
fnSetStaus(data){    

this.appService.fnSetStatus(data).subscribe(
 (res)=>{
 if(res.json().result!=""){
     this.toastr.success('', "The status has been updated successfully.");

     this.fnInsertActivityLog('EDIT',this.statusid,data.status);

     this.fnCloseStatus();        
 }},
 (err)=>{
   this.toastr.error('Error', err);
 });

}

//Close
fnCloseStatus(){
try{
  this.router.navigate(['/Status']);
}catch(ex){
   this.fnShowErrorMsg(ex);
}
}

private fnEditMode(deptBO){
 this.statusform.setValue({
   status:deptBO.status,
    statusid:deptBO.statusid,
   inactive:deptBO.inactive,   
 });

this.isaddmode=false;
this.iseditmode=true;
this.isdeletemode=false;
this.isviewmode=false;
}


 
fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}


private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


private  fnShowErrorMsg(ex){
this.toastr.warning('Internal Error', ex);       
}



}
