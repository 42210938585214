import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-productoutofstock',
  templateUrl: './productoutofstock.component.html',
  styleUrls: ['./productoutofstock.component.css']
})
export class ProductoutofstockComponent implements OnInit {


  @Input() doctype: string;
  @Input() mode: string;
  @Input() req: {};
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() msgboxtype: string;
  @Output() emitData = new EventEmitter();
  @Input() displaytxt: string = "";

  @Input() productid: number = null;
  @Input() skuid: number = null;
  @Input() warehouseid: number = null;

  @Input() editorValue: string = '';

  @Input() productname: any = null;
  @Input() sku: any = null;

  productform = new FormGroup({
    productid: new FormControl(null),
    skuid: new FormControl(null),
    isoutofstock: new FormControl(false),
    inactive: new FormControl(false),
  });

  //Save Validation
  submitted: boolean;
  successfully: boolean;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;

  constructor(private activeModal: NgbActiveModal, private appService: DhukanDataService, private loginService: LoginDataService, private toastr: ToastrService) { }

  ngOnInit() {

    this.productform.reset()
    this.fnInitializeProductFormGroup()

    if (this.productid > 0) {
      this.fngetProduct()
    }
    

  }
  public fnInitializeProductFormGroup() {
    this.productform.patchValue({
      productid: null,
      skuid: null,
      isoutofstock: false,
      inactive: false
    })


  }


    //Get Product
    fngetProduct() {        
      this.fnShowProgress();
    
         var data = {
           'productid':this.productid,            
         }
         this.appService.getProduct(data)
         .subscribe(
           (res)=>{          
           
            this.fnHideProgress();
  
            //console.log(res);
            if(res.json().status==200){
             
              let _prodskuprice = res.json().result[2];
              
              for(let mbo of _prodskuprice){                  
                  if(mbo.skuid===this.skuid && mbo.warehouseid==this.warehouseid){
                    this.productform.patchValue({
                      productid: this.productid,
                      skuid: this.skuid,
                      isoutofstock: mbo.isoutofstock,
                      inactive:  mbo.inactive,
                    })
                  }
              }          
    
             
            }
         },
         (err)=>{          
          this.fnHideProgress();
           this.toastr.error('Error', err);
         })
    }
  
  

  public decline() {
    this.activeModal.close(false);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }


  //Save Btn Click
  fnSaveBtnClick() {

    try {
      this.submitted = true;
      this.successfully = false;


      //Validate
      if (this.productform.valid) {


        //Check
        var data = {
          'productid': this.productid,
          'skuid': this.skuid,
          'warehouseid': this.warehouseid,
          'isoutofstock': this.productform.get('isoutofstock').value,
          'inactive': this.productform.get('inactive').value,      
          'modifiedby': this.loginService.fnGetModUserId(),
        }
        //CheckMain
        this.appService.fnSetProductSkuOutOfStock(data).subscribe(
          (res) => {
            this.emitData.emit("DONE");
            this.activeModal.close(true);
          },
          (err) => {
            this.fnHideProgress();
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {

      this.fnShowErrorMsg(ex);
    }
  }




  fnSetFilter() {


    //this.emitData.emit(_data);  
    this.activeModal.close(true);


  }



  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: "Product",
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });

    } catch {

    }
  }


  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    console.log(ex)
    this.toastr.warning('Internal Error', ex);
  }



}
